import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const ForecastReservationDialog = (props: { forecast: Forecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();
  const { forecast } = props;

  const handleReservation = () => {
    setLoading(true);
    api
      .post(`reservation/generate/${forecast.id}`)
      .then(() => {
        addToast(
          "Réservations générées",
          `Les réservations (méli-mélos, promotions et lancements) pour la prévision #${forecast.id} ont été générées`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la génération des réservations", "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Génération en cours..." onClose={() => props.close(false)}>
      <ModalHeader>{forecast.generated === null ? "Générer" : "Regénérer"} les réservations</ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous la {forecast.generated === null ? "génération " : "regénération "}
          des réservations pour la prévision "<Span isBold>{props.forecast.label}</Span>" (#{props.forecast.id}) ?
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => handleReservation()}>
            Générer la réservation
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};
export default ForecastReservationDialog;
