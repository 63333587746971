import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span, UnorderedList } from "@zendeskgarden/react-typography";

import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const ForecastResetDialog = (props: { forecast: Forecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();

  const handleActivityUpdate = () => {
    const { forecast } = props;
    setLoading(true);
    api
      .put(`activity/${forecast.id}/reset`)
      .then(() => {
        addToast(
          "Scénario réinitialisé",
          `Le scénario de prévision #${forecast.id} a été réinitialisé`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la réinitialisation de la prévision", "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage={""} onClose={() => props.close(false)}>
      <ModalHeader>Réinitialiser la prévision</ModalHeader>
      <Body>
        <Paragraph className={loading ? "text-grey-600" : "text"}>
          Confirmez-vous la réinitialisation de la prévision : "<Span isBold>{props.forecast.label}</Span>" (#
          {props.forecast.id}) ?
        </Paragraph>
        <Paragraph className={loading ? "text-grey-600" : "text"}>
          <Span hue={loading ? "grey" : "blue"}>
            La dernière version de l'historique et des apports seront pris en compte et les ajustements éventuels
            sélectionnés seront appliqués.
          </Span>
        </Paragraph>
        <Paragraph className={loading ? "text-grey-600" : "text"}>
          <Span hue={loading ? "grey" : "red"}>Attention, la réinitialisation entraîne la suppression :</Span>
          <UnorderedList size="small">
            <UnorderedList.Item>
              <Span hue={loading ? "grey" : "red"}>des transferts d'apports</Span>
            </UnorderedList.Item>
            <UnorderedList.Item>
              <Span hue={loading ? "grey" : "red"}>des surcharges</Span>
            </UnorderedList.Item>
            <UnorderedList.Item>
              <Span hue={loading ? "grey" : "red"}>des ouvertures et fermetures de combinaisons</Span>
            </UnorderedList.Item>
          </UnorderedList>
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isDanger>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary isDanger disabled={loading} onClick={() => handleActivityUpdate()}>
            {loading ? "Réinitialisation en cours..." : "Réinitialiser"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};
export default ForecastResetDialog;
