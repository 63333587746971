import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { makeUseAxios, Options, RefetchOptions } from "axios-hooks";

export type ApiRefetch<TResponse = any> = (
  config?: AxiosRequestConfig,
  options?: RefetchOptions
) => AxiosPromise<TResponse>;

export const useApiInstance = (): AxiosInstance => {
  const { keycloak, initialized } = useKeycloak();
  const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  instance.interceptors.request.use((config) => {
    if (initialized) {
      const kcToken = keycloak?.token ?? "";
      config.headers = { ...config.headers, Authorization: `Bearer ${kcToken}` };
    }
    return config;
  });

  return instance;
};

export const useApiGet = (url: string, config?: AxiosRequestConfig, options?: Options) => {
  const instance = useApiInstance();
  return makeUseAxios({ axios: instance })({ url, method: "GET", ...config }, options);
};

export const useApiPost = (url: string, data?: any, config?: AxiosRequestConfig, options?: Options) => {
  const instance = useApiInstance();
  return makeUseAxios({ axios: instance })({ url, method: "POST", data, ...config }, options);
};
