import { GridApi, GridOptions, GridReadyEvent } from "ag-grid-community";
import { useState } from "react";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useServerSideGridDeleteRows } from "../../../hooks/aggrid/useServerSideGridDeleteRows";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter, dateValueFormatter } from "../../../utils/helpers";

const BLUE = "#1f73b7";

const ForecastInputTransferGrid = ({
  forecast,
  onGridReady,
  gridApi,
}: {
  forecast: Forecast;
  onGridReady?(event: GridReadyEvent): void;
  gridApi: React.MutableRefObject<GridApi | undefined>;
}) => {
  const baseUrl = `/forecast/${forecast.id}/transfer`;
  const [deleteDialog, setDeleteDialog] = useState<any[] | undefined>(undefined);
  const deleteRows = useServerSideGridDeleteRows(baseUrl, gridApi.current);

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "from_family_code", headerName: "Depuis", filter: true },
      { field: "from_family_label", headerName: "Filière origine", filter: true },
      { field: "to_family_code", headerName: "Vers", filter: true },
      { field: "to_family_label", headerName: "Filière destination", filter: true },
      { field: "id", headerName: "identifiant", filter: false, hide: true },
      {
        field: "quantity_kg",
        headerName: "Quantité transférée",
        filter: false,
        editable: true,
        valueFormatter: numberValueFormatter({ suffix: "kg" }),
        cellStyle: { textAlign: "right", color: BLUE },
      },
      {
        field: "from_date",
        headerName: "Date début",
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "dateCellEditor",
        valueFormatter: dateValueFormatter(),
      },
      { field: "from_week", headerName: "Semaine début", filter: true },
      {
        field: "to_date",
        headerName: "Date fin",
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "dateCellEditor",
        valueFormatter: dateValueFormatter(),
      },
      { field: "to_week", headerName: "Semaine fin", filter: true },
    ],
  };

  return (
    <>
      {" "}
      <ServerSideGrid
        baseUrl={baseUrl}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
        menuDeleteAction={setDeleteDialog}
      />
      {deleteDialog && (
        <ConfirmDialog
          onCancel={() => setDeleteDialog(undefined)}
          onValidate={() => deleteRows(deleteDialog, () => setDeleteDialog(undefined))}
        >
          Êtes-vous sûr de supprimer le transfert d'apport ?
        </ConfirmDialog>
      )}
    </>
  );
};

export default ForecastInputTransferGrid;
