import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

import useToast from "../../../hooks/useToast";
import { useApiInstance } from "../../../hooks/useApi";
import { BudgetForecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const BudgetForecastValidateDialog = (props: { forecast: BudgetForecast; close: Function }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const api = useApiInstance();

  const validateBudgetForecast = () => {
    const { forecast } = props;
    forecast.status = "VALIDATED";
    setLoading(true);
    api
      .put(`forecast/${forecast.id}/validate`)
      .then(() => {
        addToast("Prévision validée", `La prévision #${forecast.id} a été validée`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", `Erreur à la validation de la prévision #${forecast.id}`, "error", "top");
        forecast.status = "DRAFT";
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage={"Validation en cours..."} onClose={() => props.close()}>
      <ModalHeader>Valider la prévision budgétaire #{props.forecast.id}</ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous la validation de la prévision budgétaire : "<Span isBold>{props.forecast.label}</Span>" (#
          {props.forecast.id}) ?
        </Paragraph>
        <Paragraph>
          <Span hue="red">La validation du budget est définitive et empêche toute modification future.</Span>
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button isBasic onClick={() => props.close(false)}>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => validateBudgetForecast()}>
            Valider la prévision
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default BudgetForecastValidateDialog;
