import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import moment from "moment";

const DateCellEditor = forwardRef((props: any, ref) => {
  const [value, setValue] = useState(props.value);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // focus on the input
    setTimeout(() => refInput.current!.focus());
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        if (props.nullable) {
          if (!moment(value).isValid()) {
            return null;
          } else {
            return value;
          }
        } else {
          return value;
        }
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        if (props.nullable) {
          return !(moment(value).isValid() || value === "");
        } else {
          return !moment(value).isValid();
        }
      },
    };
  });

  return (
    <input
      type="date"
      className="ag-input"
      ref={refInput}
      value={value}
      onChange={(event) => setValue(event.target.value)}
      style={{ width: "100%" }}
    />
  );
});

export default DateCellEditor;
