import { Body as ChromeBody, HeaderItemWrapper, Content } from "@zendeskgarden/react-chrome";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Span } from "@zendeskgarden/react-typography";

import NotFound from "../NotFound";
import Header from "../../components/Header";
import { useApiGet } from "../../hooks/useApi";

import DatasourceGrid from "./grids/DatasourceGrid";
import DatasourceMasterDetailGrid from "./grids/DatasourceMasterDetailGrid";
import { Datasource } from "../../interfaces";

const DatasourceView = () => {
  const { code } = useParams();
  const [{ data: datasource, error }] = useApiGet(`/datasource/${code}`);

  if (error) {
    return <NotFound />;
  }

  const renderGrid = (datasource: Datasource) => {
    if (datasource.metadata && datasource.metadata.masterDetail) {
      return <DatasourceMasterDetailGrid datasource={datasource} />;
    }
    return <DatasourceGrid datasource={datasource} />;
  };

  return (
    <ChromeBody>
      <Header helmetTitle={datasource && datasource.name}>
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/datasource">Données</Link>
            <Span>{datasource && datasource.name}</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
      </Header>
      <Content>{datasource && renderGrid(datasource)}</Content>
    </ChromeBody>
  );
};

export default DatasourceView;
