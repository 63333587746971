import { useCallback } from "react";
import {
  Notification,
  Title,
  Close,
  useToast as useZengardenToast,
  ToastPlacement,
} from "@zendeskgarden/react-notifications";
import { VALIDATION_TYPE } from "@zendeskgarden/react-notifications/dist/typings/utils/types";

type AddToastFunc = (name: string, message: string, type?: VALIDATION_TYPE, placement?: ToastPlacement) => void;

type HookSignature = {
  addToast: AddToastFunc;
};

const useToast = (): HookSignature => {
  const { addToast } = useZengardenToast();

  const customAddToast = useCallback<AddToastFunc>(
    (title, message, type, placement) => {
      addToast(
        ({ close }) => (
          <Notification type={type ? type : "info"} style={{ maxWidth: 450 }}>
            <Title>{title}</Title>
            {message}
            <Close aria-label="Close" onClick={close} />
          </Notification>
        ),
        { placement: placement ? placement : "bottom-start" }
      );
    },
    [addToast]
  );

  // const toastSuccess = (message: string, placement?: ToastPlacement) => {
  //   customAddToast("Succès", message, "success", placement);
  // }

  // const toastError = (message: string, placement?: ToastPlacement) => {
  //   customAddToast("Erreur", message, "error", placement);
  // }

  return {
    addToast: customAddToast,
  };
};

export default useToast;
