import {
  ColumnApi,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { useStorageState } from "react-storage-hooks";

/**
 * This hook is used to add a persistent layer to a grid instance
 * see https://blog.ag-grid.com/persisting-ag-grid-state-with-react-redux/#column-filter-state
 *
 * @returns
 */
export const useGridStorageState = (
  key: string
): {
  onSortChanged(event: SortChangedEvent): void;
  onFilterChanged(event: FilterChangedEvent): void;
  onFirstDataRendered(event: FirstDataRenderedEvent): void;
  onGridReady(event: GridReadyEvent): void;
} => {
  const [sortModel, setSortModel] = useStorageState<{ colId: string | undefined; sort: string | null | undefined }[]>(
    sessionStorage,
    `${key}-sort-model`,
    []
  );
  const [filterModel, setFilterModel] = useStorageState<{ [key: string]: any }>(
    sessionStorage,
    `${key}-filter-model`,
    []
  );

  const onSortChanged = (event: SortChangedEvent) => {
    setSortModel(event.api.getSortModel());
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    if (Object.keys(event).length > 3 && !event.afterDataChange) {
      setFilterModel(event.api.getFilterModel());
    }
    if (event.afterDataChange && filterModel && Object.keys(filterModel).length > 0) {
      event.api.setFilterModel(filterModel);
    }
  };

  const onGridReady = (event: GridReadyEvent) => {
    renderFilterAndSort(event);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    renderFilterAndSort(event);
  };

  const renderFilterAndSort = (event: { [key: string]: any } & { api: GridApi; columnApi: ColumnApi }) => {
    // apply filterModel
    if (filterModel && Object.keys(filterModel).length > 0) {
      event.api.setFilterModel(filterModel);
    }

    // apply sortModel
    if (sortModel && sortModel.length > 0) {
      event.api.setSortModel(sortModel);
    }

    // important to have consistent results over pagination
    if (!event.api.getSortModel().length) {
      const columns = event.columnApi.getAllColumns();
      if (columns && columns.length > 0) {
        event.api.setSortModel([{ colId: columns[0].getId(), sort: "asc" }]);
      }
    }
  };

  return { onSortChanged, onFilterChanged, onFirstDataRendered, onGridReady };
};
