import { GridOptions, ValueFormatterParams } from "ag-grid-community";

import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { ApiRefetch } from "../../../hooks/useApi";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const ForecastPromotionReservation = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;

  const baseUrl = `/reservation/promotion/${forecast.id}`;

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "product_family_code", headerName: "Code famille", hide: true, filter: true },
      { field: "product_family_label", headerName: "Label famille", filter: "agTextColumnFilter" },
      { field: "product_code", headerName: "Produit", filter: true, cellStyle: { color: "green" } },
      { field: "product_label", headerName: "Label produit", filter: "agTextColumnFilter" },
      { field: "shipping_warehouse_code", headerName: "Dépôt" },
      { field: "customer_subtype_code", headerName: "Code enseigne", hide: true, filter: true },
      { field: "customer_subtype_label", headerName: "Enseigne", filter: "agTextColumnFilter" },
      { field: "delivered_customer_code", headerName: "Client livré", filter: true },
      { field: "delivered_customer_name", headerName: "Nom du client livré", filter: "agTextColumnFilter" },
      {
        field: "shipping_date",
        headerName: "Date d'expédition",
        filter: "agDateColumnFilter",
        valueFormatter: (params: ValueFormatterParams) => params.data.shipping_date.substring(0, 10),
      },
      {
        field: "sales_quantity_kg",
        headerName: "Quantité (kg)",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
    ],
  };

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions}></ServerSideGrid>;
};

export default ForecastPromotionReservation;
