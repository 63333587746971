import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Toggle, Message } from "@zendeskgarden/react-forms";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { useForm } from "react-hook-form";

import { Form } from "../../../components/Form";
import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const BudgetForecastUpdateDialog = (props: { close: Function; forecast: Forecast }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const api = useApiInstance();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const updateBudgetForecast = (data: any) => {
    setLoading(true);
    api
      .put(`forecast/${props.forecast.id}`, {
        ...data,
        id: props.forecast.id,
        created: props.forecast.created,
        status: props.forecast.status,
        type: props.forecast.type,
      })
      .then(() => {
        addToast("Prévision mise à jour", `La prévision #${props.forecast.id} a été mise à jour`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à jour de la prévision", "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Mise à jour en cours..." onClose={() => props.close(false)}>
      <ModalHeader>Mettre à jour la prévision budgétaire</ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(updateBudgetForecast)}>
          <Field>
            <Label>Libellé</Label>
            <Input
              {...register("label", { value: props.forecast.label, required: true })}
              validation={errors.label ? "error" : undefined}
            />
            {errors.label && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner un libellé
              </Message>
            )}
          </Field>
          <Field>
            <Label>Année cible</Label>
            <Input
              disabled
              type="number"
              {...register("year", { value: props.forecast.year, required: true })}
              validation={errors.year ? "error" : undefined}
            />
            {errors.year && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner une année
              </Message>
            )}
          </Field>
          <Field>
            <Label>Mois de référence</Label>
            <Input
              disabled
              type="number"
              {...register("period", { value: props.forecast.period, required: true })}
              validation={errors.period ? "error" : undefined}
            />
            {errors.period && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner un mois de référence
              </Message>
            )}
          </Field>
          <Field>
            <Toggle {...register("activityMatchingOn", { value: props.forecast.activityMatchingOn })}>
              <Label>Activer les alignements</Label>
            </Toggle>
          </Field>
          <Field>
            <Toggle {...register("activityExclusionOn", { value: props.forecast.activityExclusionOn })}>
              <Label>Activer les exclusions</Label>
            </Toggle>
          </Field>
          <Field>
            <Toggle {...register("activityOverwriteOn", { value: props.forecast.activityOverwriteOn })}>
              <Label>Activer les surcharges</Label>
            </Toggle>
          </Field>
          <Field>
            <Label>Taux d'évolution des méli-mélos</Label>
            <Input
              type="number"
              step="any"
              {...register("melimeloTrend", { value: props.forecast.melimeloTrend, required: true })}
            />
          </Field>
        </Form>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary type="submit" form="dialog_form">
            Mettre à jour la prévision
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default BudgetForecastUpdateDialog;
