import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Moment from "react-moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Paragraph, Title, Well } from "@zendeskgarden/react-notifications";
import { Tag } from "@zendeskgarden/react-tags";
import { SM, Span } from "@zendeskgarden/react-typography";

import { ReactComponent as CheckOffIcon } from "@zendeskgarden/svg-icons/src/16/circle-full-stroke.svg";
import { ReactComponent as CheckOnIcon } from "@zendeskgarden/svg-icons/src/16/check-circle-fill.svg";
import { ReactComponent as ForecastIcon } from "@zendeskgarden/svg-icons/src/26/book.svg";
import { ReactComponent as ReferenceIcon } from "@zendeskgarden/svg-icons/src/26/settings-fill.svg";
import { ReactComponent as HistoryIcon } from "@zendeskgarden/svg-icons/src/26/workflow.svg";
import { ReactComponent as ParameterIcon } from "@zendeskgarden/svg-icons/src/26/line-chart.svg";
import { ReactComponent as SalesIcon } from "@zendeskgarden/svg-icons/src/26/play.svg";
import { ReactComponent as SwitchIcon } from "@zendeskgarden/svg-icons/src/26/arrow-right-left.svg";
import { ReactComponent as ReforecastIcon } from "@zendeskgarden/svg-icons/src/26/grid-add.svg";
import { ReactComponent as ValidationIcon } from "@zendeskgarden/svg-icons/src/26/checkbox.svg";
import { ReactComponent as WeekIcon } from "@zendeskgarden/svg-icons/src/26/dashboard.svg";

import BudgetForecastActivityDialog from "../dialogs/BudgetForecastActivityDialog";
import BudgetForecastForecastDialog from "../dialogs/BudgetForecastForecastDialog";
import BudgetForecastProductSwitchDialog from "../dialogs/BudgetForecastProductSwitchDialog";
import BudgetForecastProposalDialog from "../dialogs/BudgetForecastProposalDialog";
import BudgetForecastRecipeDialog from "../dialogs/BudgetForecastRecipeDialog";
import BudgetForecastSaleSwitchDialog from "../dialogs/BudgetForecastSaleSwitchDialog";
import BudgetForecastUpdateDialog from "../dialogs/BudgetForecastUpdateDialog";
import BudgetReforecastForecastDialog from "../dialogs/BudgetReforecastForecastDialog";
import BudgetForecastValidateDialog from "../dialogs/BudgetForecastValidateDialog";

import ForecastStatusTag from "../../../components/ForecastStatusTag";
import ForecastSubTypeTag from "../../../components/ForecastSubTypeTag";
import { PaddedMain, StyledCol } from "../../../components/Styled";
import { ApiRefetch, useApiGet } from "../../../hooks/useApi";
import { BudgetForecast } from "../../../interfaces";

const isLocked = (forecast: BudgetForecast, action: string): boolean => {
  const isValidated = forecast.status !== "DRAFT";
  const isSubstitutionBudget = forecast.subType === "SUBSTITUTION";
  const isReviewBudget = forecast.subType === "REVIEW";
  const isGenerated = isReviewBudget || forecast.generated;

  switch (action) {
    case "update":
    case "recipe":
    case "proposal":
      return isValidated || isSubstitutionBudget || isReviewBudget;
    case "activity":
      return isValidated || isSubstitutionBudget;
    case "productSwitch":
    case "saleSwitch":
      return isValidated;
    case "forecast":
      return isValidated || isSubstitutionBudget || isReviewBudget || !forecast.generated;
    case "reforecast":
      return isValidated || isSubstitutionBudget || !isGenerated;
    case "validate":
      return isValidated || !isGenerated || !forecast.forecasted;
    default:
      return isValidated || isSubstitutionBudget || isReviewBudget;
  }
};

const color = (forecast: BudgetForecast) => {
  switch (forecast.subType) {
    case "REVIEW":
      return "#f5d5d8";
    case "SUBSTITUTION":
      return "#fed6a8";
    default:
      return "#f8f9f9";
  }
};

const StatusDisplay = (props: { forecast: BudgetForecast; isReview: boolean }) => {
  return (
    <>
      <Well isRecessed>
        <Title>
          <Span>
            <Span.StartIcon>
              <ForecastIcon />
            </Span.StartIcon>
            Prévision
          </Span>
        </Title>
        <Paragraph>
          Libellé : <Span isBold>{props.forecast.label}</Span>
        </Paragraph>
        <Paragraph style={{ marginBottom: "20px" }}>
          Année cible : <Span isBold>{props.forecast.year}</Span>
        </Paragraph>
        <ForecastStatusTag forecast={props.forecast} />
      </Well>
      <Well isRecessed style={{ backgroundColor: color(props.forecast), marginTop: 10, marginBottom: 5 }}>
        <Title style={{ marginBottom: "20px" }}>
          <Title>
            <Span>
              <Span.StartIcon>
                <ReferenceIcon />
              </Span.StartIcon>
              Références du budget
            </Span>
          </Title>
        </Title>
        <ForecastSubTypeTag forecast={props.forecast} />
        {props.isReview && (
          <Paragraph>
            Semaine d'actualisation : <Span isBold>{props.forecast.reviewWeek}</Span>
          </Paragraph>
        )}
        {props.forecast.sourceBudget && (
          <>
            <Paragraph style={{ marginTop: "20px" }}>Révision générée depuis le budget :</Paragraph>
            <Link to={`/budget/${props.forecast.sourceBudget?.id}`}>
              <Tag isPill style={{ marginRight: 5 }}>
                <span>#{props.forecast.sourceBudget.id}</span>
              </Tag>
              <span>{props.forecast.sourceBudget.label}</span>
            </Link>
          </>
        )}
        {props.forecast.referentBudget && props.forecast.referentBudget.id != props.forecast.sourceBudget?.id && (
          <>
            <Paragraph>Budget d'origine :</Paragraph>
            <Link to={`/budget/${props.forecast.referentBudget?.id}`}>
              <Tag isPill style={{ marginRight: 5 }}>
                <span>#{props.forecast.referentBudget.id}</span>
              </Tag>
              <span>{props.forecast.referentBudget.label}</span>
            </Link>
          </>
        )}
      </Well>
    </>
  );
};

const ParametersDisplay = (props: { forecast: BudgetForecast; onClick: () => void }) => {
  return (
    <>
      <Well isRecessed>
        <Title>
          <Span>
            <Span.StartIcon>
              <HistoryIcon />
            </Span.StartIcon>
            Paramètres de l'historique
          </Span>
        </Title>
        <Paragraph>
          Mois de référence {props.forecast.subType === "ORIGINAL" ? "" : "initial "}:{" "}
          <Span isBold>{props.forecast.period}</Span>
        </Paragraph>
        <Paragraph>{props.forecast.activityMatchingOn ? <CheckOnIcon /> : <CheckOffIcon />} Alignements</Paragraph>
        <Paragraph>{props.forecast.activityExclusionOn ? <CheckOnIcon /> : <CheckOffIcon />} Exclusions</Paragraph>
        <Paragraph>{props.forecast.activityOverwriteOn ? <CheckOnIcon /> : <CheckOffIcon />} Surcharges</Paragraph>
      </Well>
      <Well isRecessed style={{ marginTop: 10 }}>
        <Title>
          <Span>
            <Span.StartIcon>
              <ParameterIcon />
            </Span.StartIcon>
            Paramètres de projection
          </Span>
        </Title>
        <Paragraph>
          Taux d'évolution des méli-mélos : <Span isBold>{props.forecast.melimeloTrend}</Span>
        </Paragraph>
      </Well>
      <Well isRecessed style={{ marginTop: 10 }}>
        <Button
          isStretched
          isPrimary
          style={{ marginTop: 10 }}
          onClick={props.onClick}
          disabled={isLocked(props.forecast, "update")}
        >
          Modifier les paramètres
        </Button>
      </Well>
    </>
  );
};

const UpdateRecipeDisplay = (props: { forecast: BudgetForecast; onClick: () => void }) => {
  return (
    <Well isRecessed style={{ marginTop: 10 }}>
      <Button
        isStretched
        isPrimary
        style={{ marginTop: 10 }}
        onClick={props.onClick}
        disabled={isLocked(props.forecast, "recipe")}
      >
        Mettre à jour la nomenclature
      </Button>
    </Well>
  );
};

const SwitchDisplay = (props: { forecast: BudgetForecast; onClickProduct: () => void; onClickSale: () => void }) => {
  return (
    <Well isRecessed style={{ marginTop: 10 }}>
      <Title>
        <Span>
          <Span.StartIcon>
            <SwitchIcon />
          </Span.StartIcon>
          Substitutions
        </Span>
      </Title>
      <Button
        isStretched
        isPrimary
        style={{ marginTop: 10 }}
        onClick={props.onClickProduct}
        disabled={isLocked(props.forecast, "productSwitch")}
      >
        Substituer un produit
      </Button>
      <Button
        isStretched
        isPrimary
        style={{ marginTop: 10 }}
        onClick={props.onClickSale}
        disabled={isLocked(props.forecast, "saleSwitch")}
      >
        Substituer un commercial
      </Button>
    </Well>
  );
};

const ActivityDisplay = (props: { forecast: BudgetForecast; referent: BudgetForecast; onClick: () => void }) => {
  let updated = props.forecast.created;
  if (props.forecast.subType === "SUBSTITUTION") updated = props.referent?.created;
  if (props.forecast.subType === "REVIEW" && props.forecast.reset) updated = props.forecast.reset;

  return (
    <Well isRecessed>
      <Title>
        <Span>
          <Span.StartIcon>
            <HistoryIcon />
          </Span.StartIcon>
          Historique de référence
        </Span>
      </Title>
      <Paragraph>
        Dernière mise à jour :&nbsp;
        <Span isBold>
          <Moment format="D MMM YYYY à HH:mm">{updated}</Moment>
        </Span>
      </Paragraph>
      <Button
        isStretched
        isPrimary
        style={{ marginTop: 10 }}
        onClick={props.onClick}
        disabled={isLocked(props.forecast, "activity")}
      >
        Mettre à jour l'historique
      </Button>
    </Well>
  );
};

const ProposalDisplay = (props: { forecast: BudgetForecast; isReview: boolean; onClick: () => void }) => {
  const updated = props.isReview ? props.forecast.referentBudget?.generated : props.forecast.generated;

  return (
    <Well isRecessed style={{ marginTop: 10 }}>
      <Title>
        <Span>
          <Span.StartIcon>
            <SalesIcon />
          </Span.StartIcon>
          Proposition de ventes annuelles
        </Span>
      </Title>
      {updated && (
        <Paragraph>
          Dernière génération :&nbsp;
          <Span isBold>
            <Moment format="D MMM YYYY à HH:mm">{updated}</Moment>
          </Span>
        </Paragraph>
      )}
      <Button
        isStretched
        isPrimary
        style={{ marginTop: 10 }}
        onClick={props.onClick}
        disabled={isLocked(props.forecast, "proposal")}
      >
        {props.forecast.generated === null ? "Générer" : "Regénérer"} la proposition #{props.forecast.id}
      </Button>
    </Well>
  );
};

const ForecastDisplay = (props: { forecast: BudgetForecast; isReview: boolean; onClick: () => void }) => {
  const updated = props.isReview ? props.forecast.referentBudget?.forecasted : props.forecast.forecasted;

  return (
    <Well isRecessed style={{ marginTop: 10 }}>
      <Title>
        <Span>
          <Span.StartIcon>
            <WeekIcon />
          </Span.StartIcon>
          Prévision éclatée à la semaine
        </Span>
      </Title>
      <SM>La prévision doit être lancée après avoir complété les propositions de ventes annuelles.</SM>
      {updated && (
        <Paragraph>
          Dernière génération :&nbsp;
          <Span isBold>
            <Moment format="D MMM YYYY à HH:mm">{updated}</Moment>
          </Span>
        </Paragraph>
      )}
      <Button
        isStretched
        isPrimary
        style={{ marginTop: 10 }}
        onClick={props.onClick}
        disabled={isLocked(props.forecast, "forecast")}
      >
        {props.forecast.forecasted ? "Relancer" : "Lancer"} la prévision #{props.forecast.id}
      </Button>
    </Well>
  );
};

const ReforecastDisplay = (props: { forecast: BudgetForecast; onClick: () => void }) => {
  return (
    <Well isRecessed>
      <Title>
        <Span>
          <Span.StartIcon>
            <ReforecastIcon />
          </Span.StartIcon>
          Actualisation de la prévision
        </Span>
      </Title>
      <SM>L'actualisation doit être lancée après avoir revu les propositions de ventes annuelles.</SM>
      {props.forecast.sourceBudget?.subType === "REVIEW" && props.forecast.referentBudget?.forecasted && (
        <Paragraph>
          Précédente actualisation (Budget #{props.forecast.sourceBudget?.id}) :<br />
          <Span isBold>
            <Moment format="D MMM YYYY à HH:mm">{props.forecast.referentBudget?.forecasted}</Moment>
          </Span>
        </Paragraph>
      )}
      {props.forecast.forecasted && (
        <Paragraph>
          Dernière actualisation :&nbsp;
          <Span isBold>
            <Moment format="D MMM YYYY à HH:mm">{props.forecast.forecasted}</Moment>
          </Span>
        </Paragraph>
      )}
      <Button
        isStretched
        isPrimary
        style={{ marginTop: 10 }}
        onClick={props.onClick}
        disabled={isLocked(props.forecast, "reforecast")}
      >
        {props.forecast.forecasted ? "Ré-actualiser" : "Actualiser"} la prévision #{props.forecast.id}
      </Button>
    </Well>
  );
};

const ValidateDisplay = (props: { forecast: BudgetForecast; onClick: () => void }) => {
  const marginTop = props.forecast.subType === "REVIEW" ? 10 : 0;

  return (
    <Well isRecessed style={{ marginTop }}>
      <Title>
        <Span>
          <Span.StartIcon>
            <ValidationIcon />
          </Span.StartIcon>
          Validation du budget {props.forecast.subType === "REVIEW" && " actualisé"}
        </Span>
      </Title>
      <Button
        isStretched
        isPrimary
        style={{ marginTop: 10 }}
        onClick={props.onClick}
        disabled={isLocked(props.forecast, "validate")}
      >
        Valider le budget
      </Button>
    </Well>
  );
};

const BudgetForecastParams = (props: { forecast: BudgetForecast; refetch: ApiRefetch<any> }) => {
  const { forecast, refetch } = props;
  const { id } = useParams();
  const [{ data: refBudget }] = useApiGet(`/budget/forecast/${id}/reference`, {});

  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const [productSwitchDialogVisible, setProductSwitchDialogVisible] = useState(false);
  const [saleSwitchDialogVisible, setSaleSwitchDialogVisible] = useState(false);
  const [recipeDialogVisible, setRecipeDialogVisible] = useState(false);
  const [activityDialogVisible, setActivityDialogVisible] = useState(false);
  const [proposalDialogVisible, setProposalDialogVisible] = useState(false);
  const [forecastDialogVisible, setForecastDialogVisible] = useState(false);
  const [reforecastDialogVisible, setReforecastDialogVisible] = useState(false);
  const [validateDialogVisible, setValidateDialogVisible] = useState(false);

  const onDialogClose = (success: boolean, setStateFunction: (visible: boolean) => void) => {
    setStateFunction(false);
    if (success) {
      refetch();
    }
  };

  const isReviewBudget = forecast.subType === "REVIEW";
  const isSubstitutionBudget = forecast.subType === "SUBSTITUTION";

  return (
    <PaddedMain>
      <Row>
        <Col sm={3}>
          <StatusDisplay forecast={forecast} isReview={isReviewBudget} />
        </Col>
        <StyledCol sm={3}>
          <ParametersDisplay forecast={forecast} onClick={() => setUpdateDialogVisible(true)} />
          {!isReviewBudget && <UpdateRecipeDisplay forecast={forecast} onClick={() => setRecipeDialogVisible(true)} />}
          <SwitchDisplay
            forecast={forecast}
            onClickProduct={() => setProductSwitchDialogVisible(true)}
            onClickSale={() => setSaleSwitchDialogVisible(true)}
          />
        </StyledCol>
        <StyledCol sm={3}>
          <ActivityDisplay forecast={forecast} referent={refBudget} onClick={() => setActivityDialogVisible(true)} />
          <ProposalDisplay
            forecast={forecast}
            isReview={isReviewBudget}
            onClick={() => setProposalDialogVisible(true)}
          />
          <ForecastDisplay
            forecast={forecast}
            isReview={isReviewBudget}
            onClick={() => setForecastDialogVisible(true)}
          />
        </StyledCol>
        <StyledCol sm={3}>
          {isReviewBudget && <ReforecastDisplay forecast={forecast} onClick={() => setReforecastDialogVisible(true)} />}
          {forecast.forecasted && (
            <ValidateDisplay forecast={forecast} onClick={() => setValidateDialogVisible(true)} />
          )}
        </StyledCol>
      </Row>
      {forecast && updateDialogVisible && (
        <BudgetForecastUpdateDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setUpdateDialogVisible)}
        />
      )}
      {forecast && recipeDialogVisible && (
        <BudgetForecastRecipeDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setRecipeDialogVisible)}
        />
      )}
      {forecast && productSwitchDialogVisible && (
        <BudgetForecastProductSwitchDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setProductSwitchDialogVisible)}
        />
      )}
      {forecast && saleSwitchDialogVisible && (
        <BudgetForecastSaleSwitchDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setSaleSwitchDialogVisible)}
        />
      )}
      {forecast && activityDialogVisible && (
        <BudgetForecastActivityDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setActivityDialogVisible)}
        />
      )}
      {forecast && proposalDialogVisible && (
        <BudgetForecastProposalDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setProposalDialogVisible)}
        />
      )}
      {forecast && forecastDialogVisible && (
        <BudgetForecastForecastDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setForecastDialogVisible)}
        />
      )}
      {forecast && reforecastDialogVisible && (
        <BudgetReforecastForecastDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setReforecastDialogVisible)}
        />
      )}
      {forecast && forecast.forecasted && validateDialogVisible && (
        <BudgetForecastValidateDialog
          forecast={forecast}
          close={(success: boolean) => onDialogClose(success, setValidateDialogVisible)}
        />
      )}
    </PaddedMain>
  );
};

export default BudgetForecastParams;
