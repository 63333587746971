import { useRef, useState } from "react";
import FlexView from "react-flexview";

import { GridApi } from "ag-grid-community";

import { Button } from "@zendeskgarden/react-buttons";
import { FooterItem } from "@zendeskgarden/react-chrome";

import InputTransferCreateDialog from "../dialogs/InputTransferCreateDialog";
import ForecastInputTransferGrid from "../../forecast/grids/ForecastInputTransferGrid";

import { CompactFooter } from "../../../components/Styled";
import { ApiRefetch } from "../../../hooks/useApi";
import { Forecast } from "../../../interfaces";

const ForecastTransfer = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;
  const gridApi = useRef<GridApi>();

  const [newDialog, setNewDialog] = useState<boolean>(false);

  const onCreateDialogClose = (success: boolean) => {
    setNewDialog(false);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  return (
    <>
      <FlexView column height="100%">
        <FlexView grow={1} shrink={1}>
          <ForecastInputTransferGrid
            forecast={forecast}
            onGridReady={() => gridApi.current?.refreshServerSideStore({ purge: true })}
            gridApi={gridApi}
          />
        </FlexView>
        <CompactFooter>
          <FooterItem>
            <Button size="small" isPrimary onClick={() => setNewDialog(true)}>
              Ajouter un transfert d'apport
            </Button>
          </FooterItem>
        </CompactFooter>
      </FlexView>
      {newDialog && <InputTransferCreateDialog close={onCreateDialogClose} forecast={forecast} />}
    </>
  );
};

export default ForecastTransfer;
