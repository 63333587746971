import { useState } from "react";
import moment from "moment";

import { GridApi, GridOptions, ValueFormatterParams } from "ag-grid-community";

import ClosedDayDialog from "../dialogs/ClosedDayDialog";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { useServerSideGridDeleteRows } from "../../../hooks/aggrid/useServerSideGridDeleteRows";
import { dateValueFormatter } from "../../../utils/helpers";

const ClosedDayGrid = (props: { gridApi: React.MutableRefObject<GridApi | undefined> }) => {
  const { gridApi } = props;
  const [deleteDialog, setDeleteDialog] = useState<any[] | undefined>(undefined);
  const [updateDialog, setUpdateDialog] = useState<any[] | undefined>(undefined);

  const baseUrl = "/closed/day";
  const deleteRows = useServerSideGridDeleteRows(baseUrl, gridApi.current);

  const onUpdateDialogClose = (success: boolean) => {
    setUpdateDialog(undefined);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "year", headerName: "Année", filter: "agNumberColumnFilter" },
      { field: "day", headerName: "Jour fermé", filter: "agDateColumnFilter", valueFormatter: dateValueFormatter() },
      { field: "shipping_warehouse_codes", headerName: "Entrepôt", filter: true },
      {
        field: "transfer_rule",
        headerName: "Régle de report",
        filter: false,
        valueFormatter: (params: ValueFormatterParams) => {
          let output = "";
          JSON.parse(params.value.value)
            .sort((a: { day: number[]; percent: number }, b: { day: number[]; percent: number }) =>
              a.day.join("-").localeCompare(b.day.join("-"))
            )
            .forEach((rule: { day: number[]; percent: number }) => {
              if (rule["percent"] !== 0) {
                output += `${moment(Object.assign([], rule["day"], { 1: rule["day"][1] - 1 })).format(
                  "DD/MM/YYYY"
                )} : ${rule["percent"]}%, `;
              }
            });
          return output.slice(0, -2);
        },
      },
      {
        field: "updated",
        headerName: "Mise à jour",
        filter: "agDateColumnFilter",
        valueFormatter: dateValueFormatter("DD/MM/YYYY HH:mm"),
      },
    ],
  };

  return (
    <>
      <ServerSideGrid
        baseUrl={baseUrl}
        menuDeleteAction={setDeleteDialog}
        menuEditAction={setUpdateDialog}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
      />
      {deleteDialog && (
        <ConfirmDialog
          onCancel={() => setDeleteDialog(undefined)}
          onValidate={() => deleteRows(deleteDialog, () => setDeleteDialog(undefined))}
        >
          Etes-vous sûr de supprimer le jour de fermeture {moment(deleteDialog[0].day).format("DD/MM/YYYY")} ?
        </ConfirmDialog>
      )}
      {updateDialog && <ClosedDayDialog closedDay={updateDialog[0]} close={onUpdateDialogClose} />}
    </>
  );
};

export default ClosedDayGrid;
