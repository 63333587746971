import React, { LegacyRef } from "react";

export { default as Autocomplete } from "./Autocomplete";
export { default as Datepicker } from "./Datepicker";
export { default as Multiselect } from "./Multiselect";

export const Form = React.forwardRef(
  (props: React.HTMLAttributes<HTMLFormElement>, ref?: LegacyRef<HTMLFormElement>) => {
    const { children, ...formProps } = props;

    return (
      <form ref={ref} {...formProps}>
        {React.Children.map(children, (child, i) => {
          return <div className={i > 0 ? "mt-5" : ""}>{child}</div>;
        })}
      </form>
    );
  }
);
