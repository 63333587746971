import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const ForecastRefreshDialog = (props: { forecast: Forecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();

  const handleActivityUpdate = () => {
    const { forecast } = props;
    setLoading(true);
    api
      .put(`activity/${forecast.id}/refresh`)
      .then(() => {
        addToast("Scénario actualisé", `Le scénario de prévision #${forecast.id} a été actualisé`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à l'actualisation de la prévision", "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage={""} onClose={() => props.close(false)}>
      <ModalHeader>Actualiser la prévision</ModalHeader>
      <Body>
        <Paragraph className={loading ? "text-grey-600" : "text"}>
          Confirmez-vous l'actualisation de la prévision : "<Span isBold>{props.forecast.label}</Span>" (#
          {props.forecast.id}) ?
        </Paragraph>
        <Paragraph className={loading ? "text-grey-600" : "text"}>
          <Span hue={loading ? "grey" : "blue"}>
            La dernière version de l'historique et des apports seront pris en compte et les ajustements éventuels
            sélectionnés seront appliqués. Les réservations et les prévisions seront recalculées.
          </Span>
        </Paragraph>
        <Paragraph className={loading ? "text-grey-600" : "text"}>
          <Span hue={loading ? "grey" : "blue"}>
            Les transferts d'apports, les surcharges des prévisions ainsi que les ouvertures et fermetures de
            combinaisons seront conservées.
          </Span>
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} onClick={() => handleActivityUpdate()}>
            {loading ? "Actualisation en cours..." : "Actualiser"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};
export default ForecastRefreshDialog;
