import { Footer, Main } from "@zendeskgarden/react-chrome";
import styled from "styled-components";
import { zdSpacing } from "@zendeskgarden/css-variables";
import { TabList, TabPanel, Tabs } from "@zendeskgarden/react-tabs";
import { mediaQuery } from "@zendeskgarden/react-theming";
import { Col } from "@zendeskgarden/react-grid";
import { Cell, GroupRow, HeaderRow, Row } from "@zendeskgarden/react-tables";

/*
 * Padded main
 */
export const PaddedMain = styled(Main)`
  padding: ${zdSpacing};
`;

/*
 * TabList with no margin
 */
export const CompactTabList = styled(TabList)`
  margin: 0;
`;

/**
 * Flex tabs
 */
export const FlexTabs = styled(Tabs)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FlexTabPanel = styled(TabPanel)`
  flex: 1;
`;
/*
 * CompactFooter with (flex) align property
 */
export const CompactFooter = styled(Footer)<{ align?: "start" | "end" }>`
  height: 60px;
  padding: 0 19px;
  justify-content: flex-${(props) => props.align};
`;
CompactFooter.defaultProps = { align: "end" };

/*
 * col with additionnal top margin
 */
export const StyledCol = styled(Col)`
  ${(p) => mediaQuery("down", "xs", p.theme)} {
    margin-top: ${(p) => p.theme.space.sm};
  }
`;

/*
 * Table similar to AG Grid table
 */
export const AGHeaderRow = styled(HeaderRow)`
  height: 32px;
`;
export const AGGroupRow = styled(GroupRow)`
  height: 28px;
`;
export const AGRow = styled(Row)`
  height: 28px;
`;
export const AGCell = styled(Cell)`
  padding: 2px 12px !important;
`;
