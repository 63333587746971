import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Message } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Alert, Title } from "@zendeskgarden/react-notifications";

import { Autocomplete, Form } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { BudgetCombination, BudgetForecast } from "../../../interfaces";

const BudgetCombinationExceptionDialog = (props: {
  close: Function;
  forecast: BudgetForecast;
  gridCombinations: BudgetCombination[] | [];
  productCode?: string;
  customerSubtypeCode?: string;
  commercialName?: string;
}) => {
  const { forecast, gridCombinations } = props;

  const [loading, setLoading] = useState(false);
  const [combinationError, setCombinationError] = useState("");

  const { addToast } = useToast();
  const api = useApiInstance();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [{ data: productOptions }] = useApiGet(`/sales/${forecast.id}/product`);
  const [{ data: customerSubtypeOptions }] = useApiGet(
    "/datasource/customer/values/customer_subtype_code/customer_subtype_label"
  );
  const [{ data: commercialOptions }] = useApiGet("/datasource/customer/values/commercial_name");

  const getOptions = (options: { [key: string]: string }[], code: string, label: string) => {
    return (
      [""].concat(options?.map((option: { [key: string]: string }) => `${option[code]} - ${option[label]}`).sort()) ||
      []
    );
  };

  const getCodeFromCodeLabel = (
    codeLabel: string,
    options: { [key: string]: string }[],
    code: string,
    label: string
  ) => {
    return codeLabel
      ? options.find((option: { [key: string]: string }) => `${option[code]} - ${option[label]}` === codeLabel)![code]
      : null;
  };

  const getPayload = (data: { [key: string]: string }) => {
    return {
      productCode: getCodeFromCodeLabel(data.productCodeLabel, productOptions, "product_code", "product_label"),
      customerSubtypeCode: getCodeFromCodeLabel(
        data.customerSubtypeCodeLabel,
        customerSubtypeOptions,
        "customer_subtype_code",
        "customer_subtype_label"
      ),
      commercialName: data.commercialName,
    };
  };

  const addCombination = (data: any) => {
    setCombinationError("");
    setLoading(true);
    const payload = getPayload(data);
    // check opening combination not exists
    const targetCombination: BudgetCombination = {
      product_code: payload.productCode ?? "",
      customer_subtype_code: payload.customerSubtypeCode ?? "",
      commercial_name: payload.commercialName ?? "",
    };
    const found = gridCombinations.find(
      (combination) =>
        combination.product_code === targetCombination.product_code &&
        combination.customer_subtype_code === targetCombination.customer_subtype_code &&
        combination.commercial_name === targetCombination.commercial_name
    );
    if (found) {
      setCombinationError("La combinaison est déjà ouverte.");
      setLoading(false);
      return;
    }
    api
      .post(`/sales/${forecast.id}/combination`, payload)
      .then((response) => {
        const name = `Création d'ouverture`;
        const message = `L'ouverture du produit #${response.data.product_code} a été créée.`;
        addToast(name, message, "success", "top");
        props.close(true);
      })
      .catch((error) => {
        setCombinationError(error.response.status === 405 ? "Configuration déjà existante." : "Données incohérentes.");
        setLoading(false);
      });
  };

  const handleFormSubmit = (data: any) => {
    addCombination(data);
  };

  return (
    <Modal isLarge onClose={() => props.close()}>
      <ModalHeader>Ouvrir une combinaison</ModalHeader>
      <Body>
        {productOptions && customerSubtypeOptions && commercialOptions && (
          <Form id="dialog_form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Field>
              <Label>Produit concerné</Label>
              <Autocomplete
                className="mt-2"
                control={control}
                name="productCodeLabel"
                options={getOptions(productOptions, "product_code", "product_label")}
                rules={{ required: true }}
                validation={errors.productCodeLabel ? "error" : undefined}
                value={props.productCode ? `${props.productCode}` : ""}
              />
              {errors.productCodeLabel && (
                <Message className="mt-2" validation="error">
                  Vous devez choisir un produit à ouvrir
                </Message>
              )}
            </Field>
            <Row>
              <Col size={6}>
                <Field>
                  <Label>Enseigne</Label>
                  <Autocomplete
                    className="mt-2"
                    control={control}
                    name="customerSubtypeCodeLabel"
                    options={getOptions(customerSubtypeOptions, "customer_subtype_code", "customer_subtype_label")}
                    rules={{ required: true }}
                    validation={errors.customerSubtypeCode ? "error" : undefined}
                    value={props.customerSubtypeCode}
                  />
                  {errors.customerSubtypeCode && (
                    <Message className="mt-2" validation="error">
                      Vous devez choisir une enseigne
                    </Message>
                  )}
                </Field>
              </Col>
              <Col size={6}>
                <Field>
                  <Label>Commercial</Label>
                  <Autocomplete
                    className="mt-2"
                    control={control}
                    name="commercialName"
                    options={commercialOptions.filter((option: string) => option !== null && option !== "")}
                    rules={{ required: true }}
                    value={props.commercialName}
                  />
                  {errors.commercialName && (
                    <Message className="mt-2" validation="error">
                      Vous devez choisir un commercial
                    </Message>
                  )}
                </Field>
              </Col>
            </Row>
          </Form>
        )}
        {combinationError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{combinationError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {loading ? "Création en cours..." : "Créer l'ouverture"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default BudgetCombinationExceptionDialog;
