import { Link } from "react-router-dom";
import styled from "styled-components";
import { Main } from "@zendeskgarden/react-chrome";
import { XXL } from "@zendeskgarden/react-typography";
import { zdSpacingLg, zdSpacing } from "@zendeskgarden/css-variables";

const Header = styled(XXL)`
  margin-bottom: ${zdSpacing};
`;

const PaddedMain = styled(Main)`
  padding: ${zdSpacingLg};
`;

const NotFound = () => {
  return (
    <PaddedMain className="bg-blue-100 not-found">
      <Header tag="h1">Oups...</Header>
      <h2>Désolé...</h2>
      <p>La page demandée n'a pas été trouvée</p>
      <Link to="/">Retour à l'accueil</Link>
    </PaddedMain>
  );
};

export default NotFound;
