import { useRef, useState } from "react";
import FlexView from "react-flexview";

import { GridApi } from "ag-grid-community";

import { Button } from "@zendeskgarden/react-buttons";
import { FooterItem } from "@zendeskgarden/react-chrome";
import { LG } from "@zendeskgarden/react-typography";

import BudgetMelimeloOverwriteDialog from "../dialogs/BudgetMelimeloOverwriteDialog";
import ForecastMelimeloGrid from "../../common/grids/ForecastMelimeloGrid";

import { CompactFooter } from "../../../components/Styled";
import { BudgetForecast } from "../../../interfaces";
import { ApiRefetch } from "../../../hooks/useApi";

const BudgetForecastOverwrite = (props: { forecast: BudgetForecast; refetch: ApiRefetch<any> }) => {
  const { forecast, refetch } = props;
  const melimeloGridApi = useRef<GridApi>();
  const [addMelimeloDialog, setAddMelimeloDialog] = useState<boolean>(false);

  const onMelimeloOverwriteDialogClose = (success: boolean) => {
    setAddMelimeloDialog(false);
    if (success) {
      melimeloGridApi.current?.refreshServerSideStore({});
    }
  };

  return (
    <>
      <FlexView column height="100%">
        <LG style={{ margin: 5 }}>Surcharge recette Mélimélo </LG>
        <FlexView grow={5} shrink={1}>
          <ForecastMelimeloGrid forecast={forecast} gridApi={melimeloGridApi} />
        </FlexView>
        <CompactFooter>
          <FooterItem>
            <Button
              size="small"
              isPrimary
              disabled={forecast.status !== "DRAFT"}
              onClick={() => setAddMelimeloDialog(true)}
            >
              Surcharger une recette méli-mélo
            </Button>
          </FooterItem>
        </CompactFooter>
      </FlexView>
      {addMelimeloDialog && (
        <BudgetMelimeloOverwriteDialog close={onMelimeloOverwriteDialogClose} forecast={forecast} />
      )}
    </>
  );
};

export default BudgetForecastOverwrite;
