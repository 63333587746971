import { GridApi, GridOptions } from "ag-grid-community";
import { useState } from "react";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useServerSideGridDeleteRows } from "../../../hooks/aggrid/useServerSideGridDeleteRows";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";
import FamilyOverwriteDialog from "../dialogs/FamilyOverwriteDialog";

const BLUE = "#1f73b7";

const ForecastFamilyGrid = ({
  forecast,
  gridApi,
}: {
  forecast: Forecast;
  gridApi: React.MutableRefObject<GridApi | undefined>;
}) => {
  const baseUrl = `/forecast/${forecast.id}/family`;
  const [updateDialog, setUpdateDialog] = useState<any[] | undefined>(undefined);
  const [deleteDialog, setDeleteDialog] = useState<any[] | undefined>(undefined);
  const deleteRows = useServerSideGridDeleteRows(baseUrl, gridApi.current);

  const onUpdateDialogClose = (success: boolean) => {
    setUpdateDialog(undefined);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "family_label", headerName: "Nom", filter: true },
      { field: "id", headerName: "Code filière", filter: true },
      { field: "weeks", headerName: "Surcharge semaines de références", filter: false },
      {
        field: "quantity_percent",
        headerName: "Variation globale Méli-mélo (%)",
        cellStyle: { textAlign: "right", color: BLUE },
        filter: false,
        editable: true,
        valueFormatter: numberValueFormatter({ suffix: "%" }),
      },
    ],
  };

  return (
    <>
      {" "}
      <ServerSideGrid
        baseUrl={baseUrl}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
        menuEditAction={setUpdateDialog}
        menuDeleteAction={setDeleteDialog}
      />
      {updateDialog && (
        <FamilyOverwriteDialog
          forecast={forecast}
          close={onUpdateDialogClose}
          weeks={updateDialog[0].weeks}
          id={updateDialog[0].id}
          familyLabel={updateDialog[0].family_label}
          variation={updateDialog[0].quantity_percent}
        />
      )}
      {deleteDialog && (
        <ConfirmDialog
          onCancel={() => setDeleteDialog(undefined)}
          onValidate={() => deleteRows(deleteDialog, () => setDeleteDialog(undefined))}
        >
          Êtes-vous sûr de supprimer la surcharge filière ?
        </ConfirmDialog>
      )}
    </>
  );
};

export default ForecastFamilyGrid;
