import { forwardRef, useImperativeHandle, useRef, useState } from "react";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

/**
 * from: https://www.ag-grid.com/react-data-grid/component-cell-editor/#example-component-editor
 */
const NumberCellEditor = forwardRef((props: any, ref) => {
  const createInitialState = () => {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  };

  const initialState = createInitialState();
  const [value, setValue] = useState(initialState.value);
  const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(initialState.highlightAllOnFocus);
  const refInput = useRef<HTMLInputElement>(null);
  const { step } = props;
  const cancelBeforeStart = props.charPress && "-1234567890".indexOf(props.charPress) < 0;

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      afterGuiAttached() {
        // get ref from React component
        const eInput = refInput.current!;
        eInput.focus();
        if (highlightAllOnFocus) {
          eInput.select();
          setHighlightAllOnFocus(false);
        }
      },
      // the final value to send to the grid, on completion of editing
      getValue() {
        return props.parseValue(value ? parseFloat(value) : null);
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return cancelBeforeStart;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        return false;
      },
    };
  });

  return (
    <input
      type="number"
      className="ag-input"
      ref={refInput}
      value={value}
      step={step ? step : 1}
      onChange={(event) => setValue(event.target.value)}
      style={{ width: "100%" }}
    />
  );
});

export default NumberCellEditor;
