import { useEffect, useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Alert, Title, Well } from "@zendeskgarden/react-notifications";

import { Form, Autocomplete, Datepicker } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import { useForm } from "react-hook-form";
import useToast from "../../../hooks/useToast";
import moment from "moment";
import { FamilyOption } from "../../../interfaces";

type ProductOption = {
  product_code: string;
  product_label: string;
};
type CustomerSubtypeOption = {
  customer_subtype_code: string;
  customer_subtype_label: string;
};
type CustomerOption = {
  customer_code: string;
  customer_name: string;
};
type ProductLineOption = {
  product_line_code: string;
  product_line_label: string;
};

const ActivityExclusionCreateDialog = (props: { close: Function }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: initProductOptions }] = useApiGet("/datasource/product/values/product_code/product_label");
  const [{ data: customerOptions }] = useApiGet("/datasource/customer/values/customer_code/customer_name");
  const [{ data: customerSubtypeOptions }] = useApiGet(
    "/datasource/customer/values/customer_subtype_code/customer_subtype_label"
  );
  const [{ data: familyOptions }] = useApiGet("/datasource/family/values/family_code/family_label");
  const [{ data: initProductLineOptions }] = useApiGet(
    "/datasource/product/values/product_line_code/product_line_label"
  );
  const [productLineOptions, setProductLineOptions] = useState(initProductLineOptions);
  const [productOptions, setProductOptions] = useState(initProductOptions);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const watchFamilyLabel = watch("familyLabel");
  const watchProductLineLabel = watch("productLineLabel");
  const watchExclusionStart = watch("exclusionStart");
  const watchExclusionEnd = watch("exclusionEnd");
  const watchCustomerSubtypeCodeLabel = watch("customerSubtypeCodeLabel");
  const watchCustomerCodeName = watch("customerCodeName");

  useEffect(() => {
    let body = {
      family_label: watchFamilyLabel ? watchFamilyLabel : "%",
      product_line_label: watchProductLineLabel ? watchProductLineLabel : "%",
    };
    api
      .post(`/activity/matching/product/values/product_line_code/product_line_label`, body)
      .then((req) => setProductLineOptions(req.data))
      .catch(() => {
        addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
      });
    api
      .post(`/activity/matching/product/values/product_code/product_label`, body)
      .then((req) => setProductOptions(req.data))
      .catch(() => {
        addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
      });
  }, [watchFamilyLabel, watchProductLineLabel]);

  const addActivityExclusion = (data: any) => {
    setApiError("");
    setLoading(true);
    api
      .post(`activity/exclusion`, {
        productCode: data.productCodeLabel
          ? productOptions.find(
              (option: ProductOption) => `${option.product_code} - ${option.product_label}` === data.productCodeLabel
            ).product_code
          : null,
        customerSubtypeCode: data.customerSubtypeCodeLabel
          ? customerSubtypeOptions.find(
              (option: CustomerSubtypeOption) =>
                `${option.customer_subtype_code} - ${option.customer_subtype_label}` === data.customerSubtypeCodeLabel
            ).customer_subtype_code
          : null,
        customerCode: data.customerCodeName
          ? customerOptions.find(
              (option: CustomerOption) => `${option.customer_code} - ${option.customer_name}` === data.customerCodeName
            ).customer_code
          : null,
        exclusionStart: data.exclusionStart ? moment(data.exclusionStart).format("YYYY-MM-DD") : null,
        exclusionEnd: data.exclusionEnd ? moment(data.exclusionEnd).format("YYYY-MM-DD") : null,
        comment: data.comment,
      })
      .then((response) => {
        addToast("Création d'exclusion", `L'exclusion #${response.data.id} a été créé`, "success", "top");
        props.close(true);
      })
      .catch((error) => {
        setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
        setLoading(false);
      });
  };

  return (
    <Modal onClose={() => props.close()}>
      <ModalHeader>Créer une nouvelle exclusion</ModalHeader>
      <Body>
        {familyOptions && productLineOptions && productOptions && (
          <Form id="dialog_form" onSubmit={handleSubmit(addActivityExclusion)}>
            <Well isRecessed style={{ padding: 8 }}>
              <Title isRegular>Filtres de sélection des produits</Title>
              <Row>
                <Col>
                  <Field>
                    <Label>Filière</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="familyLabel"
                      options={
                        [""].concat(familyOptions?.map((option: FamilyOption) => `${option.family_label}`).sort()) || []
                      }
                      rules={{ required: false }}
                      value={watchFamilyLabel}
                    />
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label>Conditionnement</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="productLineLabel"
                      options={
                        [""].concat(
                          productLineOptions?.map((option: ProductLineOption) => `${option.product_line_label}`).sort()
                        ) || []
                      }
                      rules={{ required: false }}
                      value={watchProductLineLabel}
                    />
                  </Field>
                </Col>
              </Row>
            </Well>
            <Field>
              <Label>Produit </Label>
              <Autocomplete
                className="mt-2"
                control={control}
                name="productCodeLabel"
                options={
                  [""].concat(
                    productOptions
                      ?.map((option: ProductOption) => `${option.product_code} - ${option.product_label}`)
                      .sort()
                  ) || []
                }
                rules={{
                  validate: (value) =>
                    value !== "" ||
                    (watchCustomerCodeName !== undefined && watchCustomerCodeName !== "") ||
                    (watchCustomerSubtypeCodeLabel !== undefined && watchCustomerSubtypeCodeLabel !== ""),
                }}
                validation={errors.productCodeLabel ? "error" : undefined}
              />
              {errors.productCodeLabel && (
                <Message className="mt-2" validation="error">
                  Vous devez choisir un code produit, enseigne ou client
                </Message>
              )}
            </Field>
            <Field>
              <Label>Enseigne</Label>
              <Autocomplete
                className="mt-2"
                control={control}
                name="customerSubtypeCodeLabel"
                options={
                  [""].concat(
                    customerSubtypeOptions
                      ?.map(
                        (option: CustomerSubtypeOption) =>
                          `${option.customer_subtype_code} - ${option.customer_subtype_label}`
                      )
                      .sort()
                  ) || []
                }
                validation={errors.customerSubtypeCodeLabel ? "error" : undefined}
                disabled={!!watchCustomerCodeName}
              />
            </Field>
            <Field>
              <Label>Client </Label>
              <Autocomplete
                className="mt-2"
                control={control}
                name="customerCodeName"
                options={
                  [""].concat(
                    customerOptions
                      ?.map((option: CustomerOption) => `${option.customer_code} - ${option.customer_name}`)
                      .sort()
                  ) || []
                }
                validation={errors.customerCodeName ? "error" : undefined}
                disabled={!!watchCustomerSubtypeCodeLabel}
              />
            </Field>
            <Field>
              <Label>Date début</Label>
              <Datepicker
                value={watchExclusionStart}
                onChange={(d) => setValue("exclusionStart", d)}
                maxValue={watchExclusionEnd}
              >
                <Input
                  {...register("exclusionStart", { required: true })}
                  validation={errors.exclusionStart ? "error" : undefined}
                />
              </Datepicker>
              {errors.exclusionStart && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner une date de début
                </Message>
              )}
            </Field>
            <Field>
              <Label>Date fin</Label>
              <Datepicker
                value={watchExclusionEnd}
                onChange={(d) => setValue("exclusionEnd", d)}
                minValue={watchExclusionStart}
              >
                <Input
                  {...register("exclusionEnd", { required: true })}
                  validation={errors.exclusionEnd ? "error" : undefined}
                />
              </Datepicker>
              {errors.exclusionEnd?.type === "required" && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner une date de fin
                </Message>
              )}
              {errors.exclusionEnd?.type === "validate" && (
                <Message className="mt-2" validation="error">
                  La date de fin doit être ultérieure à la date de début
                </Message>
              )}
            </Field>
            <Field>
              <Label>Commentaire</Label>
              <Input {...register("comment")} />
            </Field>
          </Form>
        )}
        {apiError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{apiError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {loading ? "Création en cours..." : "Créer l'exclusion"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default ActivityExclusionCreateDialog;
