import React from "react";
import { Route, Routes } from "react-router-dom";

import BudgetForecastInputGrid from "../budget/grids/BudgetForecastInputGrid";
import ForecastCarryOver from "../forecast/components/ForecastCarryOver";
import ForecastTransfer from "./components/ForecastTransfer";
import ForecastInputConsumption from "../forecast/components/ForecastInputConsumption";

import { RouteTabs, RouteTab, RouteTabPanel } from "../../components/RouteTabs";
import { CompactTabList } from "../../components/Styled";
import { ApiRefetch } from "../../hooks/useApi";
import { WeeklyForecast } from "../../interfaces";

const ForecastReservationView = (props: { forecast: WeeklyForecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;

  return (
    <RouteTabs>
      {forecast.type === "BUDGET" && (
        <CompactTabList>
          <RouteTab to="transfer">Transferts d'apport</RouteTab>
          <RouteTab to="">Apports</RouteTab>
        </CompactTabList>
      )}
      {forecast.type === "WEEKLY" && (
        <CompactTabList>
          <RouteTab to="carryover" disabled={!forecast.generated || !forecast.inputStockOn}>
            Reports S{forecast.period}
          </RouteTab>
          <RouteTab to="transfer" disabled={!forecast.generated}>
            Transferts d'apport
          </RouteTab>
          <RouteTab to="" disabled={!forecast.generated}>
            Apports restants
          </RouteTab>
        </CompactTabList>
      )}

      <RouteTabPanel>
        <Routes>
          {forecast.type === "WEEKLY" && <Route path="carryover" element={<ForecastCarryOver {...props} />} />}
          <Route path="transfer" element={<ForecastTransfer {...props} />} />
          {forecast.type === "BUDGET" && <Route index element={<BudgetForecastInputGrid {...props} />} />}
          {forecast.type === "WEEKLY" && <Route index element={<ForecastInputConsumption {...props} />} />}
        </Routes>
      </RouteTabPanel>
    </RouteTabs>
  );
};

export default ForecastReservationView;
