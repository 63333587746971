import { GridOptions, GridReadyEvent, ValueGetterParams } from "ag-grid-community";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const BLUE = "#1f73b7";

const BudgetForecastInputConsumptionGrid = ({
  forecast,
  step,
  onGridReady,
}: {
  forecast: Forecast;
  step: string;
  onGridReady?(event: GridReadyEvent): void;
}) => {
  const baseUrl = `/sales/input/${forecast.id}`;

  const gridOptions: GridOptions = {
    sideBar: { toolPanels: [] },
    columnDefs: [
      { field: "component_family_label", headerName: "Filière d'apport", filter: "agTextColumnFilter" },
      { field: "component_family_code", headerName: "Code filière", filter: true },
      {
        field: "budget_quantity_kg",
        headerName: "Apport annuel",
        cellStyle: { textAlign: "right" },
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ suffix: "kg", minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "transfer_quantity_kg",
        headerName: "Transferts",
        cellStyle: { textAlign: "right" },
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ suffix: "kg", minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "input_quantity_kg",
        headerName: "Apport net",
        cellStyle: { textAlign: "right", color: BLUE },
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ suffix: "kg", minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "consumption_kg",
        headerName: "Consommation annuelle",
        cellStyle: { textAlign: "right", color: BLUE },
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ suffix: "kg", minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "total_gap",
        headerName: "Ecart",
        cellStyle: { textAlign: "right", color: "#ff3300" },
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ suffix: "kg", minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
    ],
  };

  if (step === "review") {
    gridOptions.columnDefs?.push({
      colId: "future_gap",
      headerName: "Ecart futur",
      cellStyle: { textAlign: "right", color: "#ff3300" },
      filter: "agNumberColumnFilter",
      valueGetter: (params: ValueGetterParams) =>
        params.data.future_input_quantity_kg - params.data.future_consumption_kg,
      valueFormatter: numberValueFormatter({ suffix: "kg", minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    });
  }

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions} onGridReady={onGridReady} />;
};

export default BudgetForecastInputConsumptionGrid;
