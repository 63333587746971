import { Link } from "react-router-dom";

import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Content, HeaderItem, HeaderItemWrapper, Body as ChromeBody } from "@zendeskgarden/react-chrome";
import { Span } from "@zendeskgarden/react-typography";
import ActivityOverwriteGrid from "./grids/ActivityOverwriteGrid";
import { Button } from "@zendeskgarden/react-buttons";
import { Dropdown, Item, Menu, Trigger } from "@zendeskgarden/react-dropdowns";
import moment from "moment";
import { ReactComponent as ChevronIcon } from "@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg";
import Header from "../../components/Header";
import { useState } from "react";

const ActivityOverwrite = () => {
  const [year, setYear] = useState<Number>(moment().year());
  const [display, setDisplay] = useState<"sales" | "price">("sales");

  const years = [moment().year() - 2, moment().year() - 1, moment().year()];

  return (
    <ChromeBody>
      <Header helmetTitle="Surcharges">
        <HeaderItemWrapper>
          <Breadcrumb>
            <Link to="/settings">Paramètres</Link>
            <Span>Surcharges</Span>
          </Breadcrumb>
        </HeaderItemWrapper>

        <Dropdown selectedItem={year} onSelect={(item) => setYear(item)}>
          <Trigger>
            <HeaderItem>
              <Button isNeutral size="small">
                Année : {year}
                <Button.EndIcon>
                  <ChevronIcon />
                </Button.EndIcon>
              </Button>
            </HeaderItem>
          </Trigger>
          <Menu>
            {years.map((option) => (
              <Item key={option} value={option}>
                {option}
              </Item>
            ))}
          </Menu>
        </Dropdown>

        <Dropdown selectedItem={display} onSelect={(item) => setDisplay(item)}>
          <Trigger>
            <HeaderItem className="ml-0">
              <Button isNeutral size="small">
                Affichage : {display === "sales" ? "Volumes de vente" : "Prix unitaires"}
                <Button.EndIcon>
                  <ChevronIcon />
                </Button.EndIcon>
              </Button>
            </HeaderItem>
          </Trigger>
          <Menu>
            <Item value={"sales"}>Volumes de vente</Item>
            <Item value={"price"}>Prix unitaires</Item>
          </Menu>
        </Dropdown>

        <HeaderItemWrapper maxX />
      </Header>
      <Content>
        <ActivityOverwriteGrid display={display} year={year} />
      </Content>
    </ChromeBody>
  );
};

export default ActivityOverwrite;
