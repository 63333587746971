import { DEFAULT_THEME, IGardenTheme } from "@zendeskgarden/react-theming";

// @see https://garden.zendesk.com/components/theme-object

const theme: IGardenTheme = {
  ...DEFAULT_THEME,
  colors: {
    ...DEFAULT_THEME.colors,
    primaryHue: "#60ab16",
    chromeHue: "#63b01e",
  },
};

export default theme;
