import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import { useApiInstance } from "../../../hooks/useApi";

const ForecastDeleteDialog = (props: { forecast: Forecast; close: Function }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const api = useApiInstance();

  const deleteForecast = () => {
    const { forecast } = props;
    setLoading(true);
    api
      .delete(`forecast/${forecast.id}`)
      .then(() => {
        console.log(`delete forecast #${forecast.id}`);
        addToast("Prévision supprimée", `La prévision #${forecast.id} a été supprimée`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la suppression de la prévision", "error", "top");
        setLoading(false);
      });
  };

  return (
    <Modal onClose={() => props.close(false)}>
      <ModalHeader>Supprimer la prévision budgétaire #{props.forecast.id}</ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous la suppression de la prévision : "<Span isBold>{props.forecast.label}</Span>" (#
          {props.forecast.id}) ?
        </Paragraph>
        <Paragraph>
          <Span hue="red">Attention, la suppression est définitive !</Span>
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button
            isPrimary
            disabled={loading}
            onClick={() => {
              deleteForecast();
            }}
          >
            {loading ? "Suppression en cours..." : "Supprimer la prévision"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default ForecastDeleteDialog;
