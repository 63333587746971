import { CellValueChangedEvent, GridOptions } from "ag-grid-community";

import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { ApiRefetch, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const BLUE = "#1f73b7";

const ForecastCarryOver = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;
  const preveolApi = useApiInstance();
  const { addToast } = useToast();
  const editable = forecast && forecast.generated && forecast.status === "DRAFT";

  const baseUrl = `/input/carryover/${forecast.id}/${forecast.period}`;

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    let oldValue = params.oldValue ? parseInt(params.oldValue) : 0;
    let newValue = params.newValue ? parseInt(params.newValue) : 0;
    if (newValue !== oldValue) {
      preveolApi
        .put(`input/entry/${forecast.id}/${forecast.period}`, {
          componentFamilyCode: params.data.component_family_code,
          oldValue: oldValue,
          newValue: newValue,
        })
        .then((req) => {
          addToast(
            "Valeur mise à jour",
            `Mise à jour effectuée pour la filière "${params.data.component_family_label}".`,
            "success"
          );
          props.refetch();
        })
        .catch((err: TypeError) => {
          let message = `La valeur ${params.newValue} est erronée. Veuillez corriger.`;
          // reset old value in grid
          if (forecast.status !== "DRAFT") {
            message = "Modification impossible sur les budgets validés.";
          }
          addToast("Erreur", message, "error", "top");
        });
    }
  };

  const gridOptions: GridOptions = {
    defaultColDef: {
      maxWidth: 200,
    },
    columnDefs: [
      {
        field: "component_family_code",
        headerName: "Code filière",
        filter: true,
      },
      { field: "component_family_label", headerName: "Filière", filter: "agTextColumnFilter" },
      {
        field: "input_quantity_kg",
        headerName: "Apports",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      },
      {
        field: "carry_over_quantity_kg",
        headerName: "Reports",
        cellStyle: { textAlign: "right", color: BLUE },
        cellRenderer: "agAnimateShowChangeCellRenderer",
        editable: editable,
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        cellEditor: "numberCellEditor",
        cellEditorParams: { step: 1 },
      },
    ],
    onCellValueChanged: onCellValueChanged,
    enableCellChangeFlash: true,
    undoRedoCellEditing: true,
  };

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions}></ServerSideGrid>;
};

export default ForecastCarryOver;
