import { useRef, useState } from "react";
import FlexView from "react-flexview";

import { GridApi } from "ag-grid-community";

import { Button } from "@zendeskgarden/react-buttons";
import { FooterItem } from "@zendeskgarden/react-chrome";
import { LG } from "@zendeskgarden/react-typography";

import FamilyOverwriteDialog from "../dialogs/FamilyOverwriteDialog";
import ForecastFamilyGrid from "../grids/ForecastFamilyGrid";
import ForecastMelimeloGrid from "../../common/grids/ForecastMelimeloGrid";
import MelimeloOverwriteDialog from "../dialogs/MelimeloOverwriteDialog";

import { CompactFooter } from "../../../components/Styled";
import { WeeklyForecast } from "../../../interfaces";
import { ApiRefetch } from "../../../hooks/useApi";

const ForecastOverwrite = (props: { forecast: WeeklyForecast; refetch: ApiRefetch<any> }) => {
  const { forecast, refetch } = props;
  const familyGridApi = useRef<GridApi>();
  const melimeloGridApi = useRef<GridApi>();
  const [addFamilyDialog, setAddFamilyDialog] = useState<boolean>(false);
  const [addMelimeloDialog, setAddMelimeloDialog] = useState<boolean>(false);

  const onFamilyOverwriteDialogClose = (success: boolean) => {
    setAddFamilyDialog(false);
    if (success) {
      familyGridApi.current?.refreshServerSideStore({});
      refetch();
    }
  };

  const onMelimeloOverwriteDialogClose = (success: boolean) => {
    setAddMelimeloDialog(false);
    if (success) {
      melimeloGridApi.current?.refreshServerSideStore({});
    }
  };

  return (
    <>
      <FlexView column height="100%">
        <LG style={{ margin: 5 }}>Surcharges filières</LG>
        <FlexView grow={1} shrink={1}>
          <ForecastFamilyGrid forecast={forecast} gridApi={familyGridApi} />
        </FlexView>
        <LG style={{ margin: 5 }}>Surcharge recette Mélimélo </LG>
        <FlexView grow={5} shrink={1}>
          <ForecastMelimeloGrid forecast={forecast} gridApi={melimeloGridApi} />
        </FlexView>
        <CompactFooter>
          <FooterItem>
            <Button
              size="small"
              isPrimary
              disabled={forecast.status !== "DRAFT"}
              onClick={() => setAddFamilyDialog(true)}
            >
              Surcharger une filière
            </Button>
          </FooterItem>
          <FooterItem>
            <Button
              size="small"
              isPrimary
              disabled={forecast.status !== "DRAFT"}
              onClick={() => setAddMelimeloDialog(true)}
            >
              Surcharger une recette méli-mélo
            </Button>
          </FooterItem>
        </CompactFooter>
      </FlexView>
      {addFamilyDialog && <FamilyOverwriteDialog close={onFamilyOverwriteDialogClose} forecast={forecast} />}
      {addMelimeloDialog && <MelimeloOverwriteDialog close={onMelimeloOverwriteDialogClose} forecast={forecast} />}
    </>
  );
};

export default ForecastOverwrite;
