import { useEffect, useState } from "react";
import moment from "moment";

import { IToolPanelParams, ModelUpdatedEvent } from "ag-grid-community";

import { Body, Head, HeaderCell, Table } from "@zendeskgarden/react-tables";

import { AGCell, AGGroupRow, AGHeaderRow, AGRow } from "../../../../components/Styled";
import { WeeklyForecast } from "../../../../interfaces";

interface ProductAggregation {
  diff: number;
  promo_kg: number;
  promo_pc: number;
  total_kg: number;
  total_pc: number;
}

const PackagingPanel = (props: IToolPanelParams & { forecast: WeeklyForecast; familyCode: string }) => {
  const { forecast, api } = props;
  const [productLines, setProductLines] = useState<{ [key: string]: ProductAggregation[] }>();

  const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "decimal", maximumFractionDigits: 0 });
  const percentFormatter = new Intl.NumberFormat("fr-FR", { style: "percent", maximumFractionDigits: 2 });

  const dateRef = moment(`${forecast.year}`).add(forecast.period, "weeks");

  const update = (evt: ModelUpdatedEvent) => {
    // update product line stats from grid data
    let productLineAnalysis: { [key: string]: ProductAggregation[] } = {};
    evt.api.forEachNode(function (node) {
      const data = node.data;
      if (data && data.total) {
        const date = moment(`${forecast.year}`).add(data.shipping_week, "weeks");
        const w = date.diff(dateRef, "week");
        if (w >= 0 && w < 3) {
          if (productLineAnalysis.hasOwnProperty(data.product_line_label)) {
            const index = productLineAnalysis[data.product_line_label].findIndex((agg) => agg.diff === w);
            if (index > -1) {
              productLineAnalysis[data.product_line_label][index].promo_kg +=
                data.order_type === "PROMOTION" ? data.total : 0;
              productLineAnalysis[data.product_line_label][index].total_kg += data.total;
            } else {
              productLineAnalysis[data.product_line_label].push({
                diff: w,
                promo_kg: data.order_type === "PROMOTION" ? data.total : 0,
                promo_pc: 0,
                total_kg: data.total,
                total_pc: 0,
              });
            }
          } else {
            productLineAnalysis[data.product_line_label] = [
              { diff: w, promo_kg: 0, promo_pc: 0, total_kg: data.total, total_pc: 0 },
            ];
          }
        }
      }
    });
    // compute relative values
    let promo = [0, 0, 0];
    let total = [0, 0, 0];
    Object.values(productLineAnalysis).forEach((aggregation) => {
      aggregation.forEach((agg) => {
        promo[agg.diff] += agg.promo_kg;
        total[agg.diff] += agg.total_kg;
      });
    });
    Object.values(productLineAnalysis).forEach((aggregation) => {
      aggregation.forEach((agg) => {
        agg.promo_pc = promo[agg.diff] > 0.01 ? agg.promo_kg / total[agg.diff] : 0;
        agg.total_pc = total[agg.diff] > 0.01 ? agg.total_kg / total[agg.diff] : 0;
      });
    });
    // compute sums
    productLineAnalysis["TOTAL"] = [
      {
        diff: 0,
        promo_kg: promo[0],
        promo_pc: total[0] > 1 ? promo[0] / total[0] : 0,
        total_kg: total[0],
        total_pc: 1,
      },
    ];
    [1, 2].forEach((w: number) => {
      productLineAnalysis["TOTAL"].push({
        diff: w,
        promo_kg: promo[w],
        promo_pc: total[w] > 1 ? promo[w] / total[w] : 0,
        total_kg: total[w],
        total_pc: 1,
      });
    });
    // update productLines
    setProductLines(productLineAnalysis);
  };

  useEffect(() => {
    api.addEventListener("modelUpdated", update);
    return () => api.removeEventListener("modelUpdated", update);
  }, []);

  return (
    <Table size="small" style={{ fontSize: "small", minWidth: 450 }}>
      {productLines && (
        <Head>
          <AGHeaderRow>
            <HeaderCell width={140}>Conditionnement</HeaderCell>
            <HeaderCell style={{ textAlign: "right" }}>S</HeaderCell>
            <HeaderCell style={{ textAlign: "right" }}>S+1</HeaderCell>
            <HeaderCell style={{ textAlign: "right" }}>S+2</HeaderCell>
          </AGHeaderRow>
        </Head>
      )}
      {productLines &&
        Object.keys(productLines).map((line) => (
          <Body key={line}>
            <AGGroupRow>
              <AGCell colSpan={4}>
                <b>{line}</b>
              </AGCell>
            </AGGroupRow>
            <AGRow>
              <AGCell>
                <i>Promo %</i>
              </AGCell>
              {[0, 1, 2].map((w: number) => (
                <AGCell key={w} style={{ textAlign: "right" }}>
                  {percentFormatter.format(
                    productLines[line].find((agg) => agg.diff === w)
                      ? productLines[line].find((agg) => agg.diff === w)!.promo_pc
                      : 0
                  )}
                </AGCell>
              ))}
            </AGRow>
            <AGRow>
              <AGCell>
                <i>Promo kg</i>
              </AGCell>
              {[0, 1, 2].map((w: number) => (
                <AGCell key={w} style={{ textAlign: "right" }}>
                  {numberFormatter.format(
                    productLines[line].find((agg) => agg.diff === w)
                      ? productLines[line].find((agg) => agg.diff === w)!.promo_kg
                      : 0
                  )}
                </AGCell>
              ))}
            </AGRow>
            <AGRow>
              <AGCell>
                <i>FDR/Lancement %</i>
              </AGCell>
              {[0, 1, 2].map((w: number) => (
                <AGCell key={w} style={{ textAlign: "right" }}>
                  {percentFormatter.format(
                    productLines[line].find((agg) => agg.diff === w)
                      ? productLines[line].find((agg) => agg.diff === w)!.total_pc -
                          productLines[line].find((agg) => agg.diff === w)!.promo_pc
                      : 0
                  )}
                </AGCell>
              ))}
            </AGRow>
            <AGRow>
              <AGCell>
                <i>FDR/Lancement kg</i>
              </AGCell>
              {[0, 1, 2].map((w: number) => (
                <AGCell key={w} style={{ textAlign: "right" }}>
                  {numberFormatter.format(
                    productLines[line].find((agg) => agg.diff === w)
                      ? productLines[line].find((agg) => agg.diff === w)!.total_kg -
                          productLines[line].find((agg) => agg.diff === w)!.promo_kg
                      : 0
                  )}
                </AGCell>
              ))}
            </AGRow>
            <AGRow>
              <AGCell>
                <i>Total %</i>
              </AGCell>
              {[0, 1, 2].map((w: number) => (
                <AGCell key={w} style={{ textAlign: "right" }}>
                  {percentFormatter.format(
                    productLines[line].find((agg) => agg.diff === w)
                      ? productLines[line].find((agg) => agg.diff === w)!.total_pc
                      : 0
                  )}
                </AGCell>
              ))}
            </AGRow>
            <AGRow>
              <AGCell>
                <i>Total kg</i>
              </AGCell>
              {[0, 1, 2].map((w: number) => (
                <AGCell key={w} style={{ textAlign: "right" }}>
                  {numberFormatter.format(
                    productLines[line].find((agg) => agg.diff === w)
                      ? productLines[line].find((agg) => agg.diff === w)!.total_kg
                      : 0
                  )}
                </AGCell>
              ))}
            </AGRow>
          </Body>
        ))}
    </Table>
  );
};

export default PackagingPanel;
