import { useState, useEffect } from "react";
import { Autocomplete as ZenAutocomplete, Field, Dropdown, Item, Menu } from "@zendeskgarden/react-dropdowns";
import { ReactComponent as SearchIcon } from "@zendeskgarden/svg-icons/src/16/search-stroke.svg";

import { Controller, Control, RegisterOptions, FieldValues, FieldPath } from "react-hook-form";
import { VALIDATION } from "@zendeskgarden/react-dropdowns/dist/typings/utils/validation";

export default function Autocomplete({
  control,
  options,
  name,
  rules,
  validation,
  className,
  disabled,
  value,
}: {
  control: Control;
  options: string[];
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  validation?: VALIDATION;
  className?: string;
  disabled?: boolean;
  value?: string;
}) {
  value = value !== undefined ? value : "";
  const [selectedItem, setSelectedItem] = useState(value);
  const [inputValue, setInputValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState([] as string[]);

  useEffect(() => {
    if (inputValue) {
      setMatchingOptions(
        options.filter((option) => option.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1)
      );
    } else {
      setMatchingOptions(options);
    }
  }, [options, inputValue]);

  const onSelect = (item: any, field: any) => {
    setSelectedItem(item);
    field.onChange(item);
  };

  return (
    <Controller
      render={({ field }) => (
        <Dropdown
          {...field}
          inputValue={inputValue}
          selectedItem={selectedItem}
          onSelect={(item) => onSelect(item, field)}
          onInputValueChange={(value) => setInputValue(value)}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
          <Field>
            <ZenAutocomplete className={className} start={<SearchIcon />} validation={validation} disabled={disabled}>
              {selectedItem}
            </ZenAutocomplete>
          </Field>
          <Menu>
            {matchingOptions.length ? (
              matchingOptions.map((option) => (
                <Item key={option} value={option}>
                  {" "}
                  {/* pour une better UI fournir une liste d'option avec label et value à utilisé ici */}
                  <span>{option}</span>
                </Item>
              ))
            ) : (
              <Item disabled>No matches found</Item>
            )}
          </Menu>
        </Dropdown>
      )}
      name={name}
      control={control}
      rules={rules}
      defaultValue={value}
    />
  );
}
