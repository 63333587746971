import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

import LoadingModal from "../../../components/LoadingModal";
import useToast from "../../../hooks/useToast";
import { useApiInstance } from "../../../hooks/useApi";
import { Forecast } from "../../../interfaces";

const ForecastFamilyValidateDialog = (props: { forecast: Forecast; familyCodes: string[]; close: Function }) => {
  const { forecast, familyCodes, close } = props;
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const api = useApiInstance();

  const validateForecast = () => {
    forecast.status = "VALIDATED";
    setLoading(true);
    api
      .put(`forecast/${forecast.id}/validate/families`, familyCodes)
      .then(() => {
        let message =
          familyCodes.length === 1
            ? `La prévision #${forecast.id} a été validée pour la filière ${familyCodes[0]}`
            : `La prévision #${forecast.id} a été validée pour les filières ${familyCodes.join(", ")}`;
        addToast("Prévision validée", message, "success", "top");
        props.close(true);
      })
      .catch(() => {
        let message =
          familyCodes.length === 1
            ? `Erreur à la validation de la prévision #${forecast.id} pour la filière ${familyCodes[0]}`
            : `Erreur à la validation de la prévision #${forecast.id} pour les filières ${familyCodes.join(", ")}`;
        addToast("Erreur", message, "error", "top");
      });
    forecast.status = "DRAFT";
    setLoading(false);
    props.close(true);
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Validation en cours..." onClose={() => close()}>
      <ModalHeader>
        Valider {familyCodes.length > 1 ? "les filières sélectionnées" : "une filière"} de la prévision #{forecast.id}
      </ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous la validation
          {familyCodes.length > 1 && <Span isBold> des filières sélectionnées ({familyCodes.join(", ")}) </Span>}
          {familyCodes.length === 1 && <Span isBold> de la filière {familyCodes[0]} </Span>}
          pour la prévision hebdomadaire: "<Span isBold>{forecast.label}</Span>" (#{forecast.id}) ?
        </Paragraph>
        <Paragraph>
          <Span hue="blue">
            La validation {familyCodes.length > 1 ? "des filières sélectionnées" : "de la filière"} entraîne le
            transfert des prévisions.
          </Span>
        </Paragraph>
        <Paragraph>
          <Span hue="red">
            La validation empêche toute modification future, à l'exception de l'actualisation de la prévision.
          </Span>
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button isBasic onClick={() => close(false)}>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => validateForecast()}>
            Valider la prévision
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default ForecastFamilyValidateDialog;
