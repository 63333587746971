import { GridOptions } from "ag-grid-community";

import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { ApiRefetch } from "../../../hooks/useApi";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const ActivityDailyPattern = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;

  const baseUrl = `/activity/pattern/daily/${forecast.id}`;

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "product_family_code", headerName: "Filière", filter: true, hide: true },
      { field: "product_family_label", headerName: "Label filière", filter: "agTextColumnFilter" },
      { field: "product_code", headerName: "Produit", filter: true },
      { field: "product_label", headerName: "Label produit", filter: "agTextColumnFilter" },
      // { field: "customer_type_code", headerName: "Code type", filter: true, hide: true },
      // { field: "customer_type_label", headerName: "Type", filter: "agTextColumnFilter", hide: true },
      // { field: "customer_subtype_code", headerName: "Code enseigne", filter: true, hide: true },
      { field: "customer_subtype_label", headerName: "Enseigne", filter: "agTextColumnFilter", hide: true },
      { field: "shipping_warehouse_code", headerName: "Dépôt" },
      { field: "customer_code", headerName: "Code client", filter: true, hide: true },
      { field: "customer_name", headerName: "Nom du client", filter: "agTextColumnFilter" },
      { field: "delivered_customer_code", headerName: "Client livré", filter: true },
      // { field: "delivered_customer_name", headerName: "Nom du client livré", filter: "agTextColumnFilter" },
      {
        field: "day_of_week",
        headerName: "Jour",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "center" },
      },
      {
        field: "sales",
        headerName: "Poids",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right", color: "green" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 4, maximumFractionDigits: 4 }),
      },
    ],
  };

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions}></ServerSideGrid>;
};

export default ActivityDailyPattern;
