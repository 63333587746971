import { Tag } from "@zendeskgarden/react-tags";
import { PropsWithChildren } from "react";
import { Forecast } from "../interfaces";

const ForecastStatusTag = (props: PropsWithChildren<{ forecast: Forecast }>) => {
  switch (props.forecast.status) {
    case "DRAFT":
      return <Tag hue="blue">Brouillon</Tag>;
    case "PARTIAL":
      return <Tag hue="orange">En cours</Tag>;
    case "VALIDATED":
      return <Tag hue="green">Validé</Tag>;
    case "ARCHIVED":
      return <Tag hue="grey">Archivé</Tag>;
  }
};

export default ForecastStatusTag;
