import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { GridApi } from "ag-grid-community";

import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Button } from "@zendeskgarden/react-buttons";
import { Content, HeaderItemWrapper, Body as ChromeBody } from "@zendeskgarden/react-chrome";
import { Span } from "@zendeskgarden/react-typography";

import ProductLaunchDialog from "./dialogs/ProductLaunchDialog";
import ProductLaunchGrid from "./grids/ProductLaunchGrid";
import Header from "../../components/Header";

const ProductLaunch = () => {
  const [newDialog, setNewDialog] = useState<boolean>(false);

  const gridApi = useRef<GridApi>();

  const onCreateDialogClose = (success: boolean) => {
    setNewDialog(false);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  return (
    <ChromeBody>
      <Header helmetTitle="Lancements de produits">
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/settings">Paramètres</Link>
            <Span>Lancements de produits</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          <Button isPrimary size="small" onClick={() => setNewDialog(true)}>
            Nouveau lancement de produits
          </Button>
        </HeaderItemWrapper>
      </Header>
      <Content>
        <ProductLaunchGrid gridApi={gridApi} />
      </Content>

      {newDialog && <ProductLaunchDialog close={onCreateDialogClose} />}
    </ChromeBody>
  );
};

export default ProductLaunch;
