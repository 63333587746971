import styled, { keyframes } from "styled-components";

const ProgressBarWrapper = styled.div`
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
`;

const indeterminateAnimation = keyframes`
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
`;

const ProgressBarValue = styled.div<{ color?: string; animated?: boolean; progress?: number }>`
  width: ${(props) => props.progress}%;
  height: 100%;
  background-color: ${(props) => props.color || "rgb(5, 114, 206)"};
  animation: ${indeterminateAnimation} ${(props) => (props.animated ? "1s" : "0s")} infinite linear;
  transform-origin: 0% 50%;
`;

const ProgressBar = (props: { color?: string; animated?: boolean; progress?: number }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarValue
        color={props.color}
        animated={props.animated !== undefined ? props.animated : true}
        progress={props.progress! | 0}
      ></ProgressBarValue>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
