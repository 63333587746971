import { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { Button, IconButton } from "@zendeskgarden/react-buttons";
import { Dots } from "@zendeskgarden/react-loaders";
import { Pagination } from "@zendeskgarden/react-pagination";
import { Body as ChromeBody, HeaderItemWrapper, Content } from "@zendeskgarden/react-chrome";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, SortableCell, Table } from "@zendeskgarden/react-tables";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { Span } from "@zendeskgarden/react-typography";
import { ReactComponent as ArchiveIcon } from "@zendeskgarden/svg-icons/src/12/folder-open-stroke.svg";
import { ReactComponent as DeleteIcon } from "@zendeskgarden/svg-icons/src/12/trash-stroke.svg";
import { ReactComponent as ReforecastIcon } from "@zendeskgarden/svg-icons/src/12/arrow-retweet-fill.svg";
import { ReactComponent as ViewIcon } from "@zendeskgarden/svg-icons/src/12/eye-fill.svg";

import BudgetForecastArchiveDialog from "./dialogs/BudgetForecastArchiveDialog";
import BudgetForecastCreateDialog from "./dialogs/BudgetForecastCreateDialog";
import BudgetForecastDeleteDialog from "./dialogs/BudgetForecastDeleteDialog ";

import BudgetForecastReforecastDialog from "./dialogs/BudgetForecastReforecastDialog";
import ForecastStatusTag from "../../components/ForecastStatusTag";
import Header from "../../components/Header";
import { PaddedMain } from "../../components/Styled";
import { useApiGet } from "../../hooks/useApi";
import { BudgetForecast, SortDirection } from "../../interfaces";
import { sortCallback } from "../../utils/helpers";
import ForecastSubTypeTag from "../../components/ForecastSubTypeTag";

const BudgetForecastList = () => {
  const [yearSort, setYearSort] = useState<SortDirection | undefined>();
  const [idSort, setIdSort] = useState<SortDirection | undefined>();
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [{ data: forecasts, loading, error }, refetch] = useApiGet("/forecast");

  const [newDialog, setNewDialog] = useState<boolean>(false);
  const [reforecastDialog, setReforecastDialog] = useState<BudgetForecast | null>(null);
  const [archiveDialog, setArchiveDialog] = useState<BudgetForecast | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<BudgetForecast | null>(null);

  const onCreateDialogClose = (success: boolean) => {
    setNewDialog(false);
    if (success) {
      refetch();
    }
  };

  const onReforecastDialogClose = (success: boolean) => {
    setReforecastDialog(null);
    if (success) {
      refetch();
    }
  };

  const onDeleteDialogClose = (success: boolean) => {
    setDeleteDialog(null);
    if (success) {
      refetch();
    }
  };

  const onArchiveDialogClose = (success: boolean) => {
    setArchiveDialog(null);
    if (success) {
      refetch();
    }
  };

  const sortData = (tableData: BudgetForecast[]) => {
    if (!yearSort && !idSort) {
      return tableData.sort(sortCallback("updated", "desc"));
    }
    if (yearSort) {
      return tableData.sort(sortCallback("year", yearSort));
    } else {
      return tableData.sort(sortCallback("id", idSort));
    }
  };

  const handleYearSort = () => {
    setYearSort(yearSort === "asc" ? "desc" : yearSort === "desc" ? undefined : "asc");
    setIdSort(undefined);
    sortData(forecasts);
  };

  const handleIdSort = () => {
    setIdSort(idSort === "asc" ? "desc" : idSort === "desc" ? undefined : "asc");
    setYearSort(undefined);
    sortData(forecasts);
  };

  return (
    <ChromeBody>
      <Header helmetTitle="Prévisions budgétaires">
        <HeaderItemWrapper maxX>
          <Span>Prévisions budgétaires</Span>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          <Button isPrimary size="small" onClick={() => setNewDialog(true)}>
            Créer une nouvelle prévision
          </Button>
        </HeaderItemWrapper>
      </Header>
      <Content>
        <PaddedMain>
          {loading && <Dots />}
          {error && (
            <Alert type="error" style={{ marginTop: 10 }}>
              <Title>Erreur à la récupération des prévisions</Title>Veuillez recharger la page.
            </Alert>
          )}
          {forecasts && (
            <>
              <Table>
                <Head>
                  <HeaderRow>
                    <SortableCell width={50} onClick={() => handleIdSort()} sort={idSort}>
                      Id
                    </SortableCell>
                    <HeaderCell>Libellé</HeaderCell>
                    <HeaderCell width={120}>Type</HeaderCell>
                    <SortableCell width={100} onClick={() => handleYearSort()} sort={yearSort}>
                      Année cible
                    </SortableCell>
                    <HeaderCell width={100}>Mois de référence</HeaderCell>
                    <HeaderCell width={120}>Statut</HeaderCell>
                    <HeaderCell width={180}>Création</HeaderCell>
                    <HeaderCell width={180}>Dernière modification</HeaderCell>
                    <HeaderCell>Actions</HeaderCell>
                  </HeaderRow>
                </Head>
                <Body>
                  {sortData(forecasts)
                    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((forecast: BudgetForecast) => (
                      <Row key={forecast.id}>
                        <Cell width={50}>
                          <Link to={`/budget/${forecast.id}`}>#{forecast.id}</Link>
                        </Cell>
                        <Cell>
                          <Link to={`/budget/${forecast.id}`}>{forecast.label}</Link>
                        </Cell>
                        <Cell width={150}>
                          <ForecastSubTypeTag forecast={forecast}></ForecastSubTypeTag>
                        </Cell>
                        <Cell width={150}>{forecast.year}</Cell>
                        <Cell width={200}>{forecast.period}</Cell>
                        <Cell width={150}>
                          <ForecastStatusTag forecast={forecast}></ForecastStatusTag>
                        </Cell>
                        <Cell>
                          <Moment format="D MMM YYYY à HH:mm">{forecast.created}</Moment>
                          <br />
                          {forecast.createdBy ? ` par ${forecast.createdBy}` : ""}
                        </Cell>
                        <Cell>
                          <Moment format="D MMM YYYY à HH:mm">{forecast.updated}</Moment>
                        </Cell>
                        <Cell>
                          <Link to={`/budget/${forecast.id}`}>
                            <Tooltip placement="bottom" type="dark" size="small" content="Accéder à la prévision">
                              <IconButton isBasic={false} size="small" style={{ marginRight: 2 }}>
                                <ViewIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          {forecast.status === "VALIDATED" && (
                            <Tooltip placement="bottom" type="dark" size="small" content="Réviser la prévision">
                              <IconButton
                                isBasic={false}
                                size="small"
                                onClick={() => setReforecastDialog(forecast)}
                                style={{ borderColor: "DarkOrange", color: "DarkOrange", marginRight: 2 }}
                              >
                                <ReforecastIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {forecast.status === "VALIDATED" && (
                            <Tooltip placement="bottom" type="dark" size="small" content="Archiver la prévision">
                              <IconButton
                                isDanger
                                isBasic={false}
                                size="small"
                                onClick={() => setArchiveDialog(forecast)}
                                style={{ marginRight: 2 }}
                              >
                                <ArchiveIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {forecast.status === "DRAFT" && (
                            <Tooltip placement="bottom" type="dark" size="small" content="Supprimer la prévision">
                              <IconButton
                                isDanger
                                isBasic={false}
                                size="small"
                                onClick={() => setDeleteDialog(forecast)}
                                style={{ marginRight: 2 }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Cell>
                      </Row>
                    ))}
                </Body>
              </Table>
              {forecasts.length / pageSize > 1 && (
                <Pagination
                  totalPages={Math.ceil(forecasts.length / pageSize)}
                  currentPage={currentPage}
                  onChange={setCurrentPage}
                />
              )}
            </>
          )}
        </PaddedMain>
      </Content>

      {newDialog && <BudgetForecastCreateDialog close={onCreateDialogClose} />}
      {reforecastDialog && (
        <BudgetForecastReforecastDialog forecast={reforecastDialog} close={onReforecastDialogClose} />
      )}
      {archiveDialog && <BudgetForecastArchiveDialog forecast={archiveDialog} close={onArchiveDialogClose} />}
      {deleteDialog && <BudgetForecastDeleteDialog forecast={deleteDialog} close={onDeleteDialogClose} />}
    </ChromeBody>
  );
};

export default BudgetForecastList;
