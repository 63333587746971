import { useKeycloak } from "@react-keycloak/web";
import { useStore, withStore } from "react-context-hook";
import { PropsWithChildren, useEffect } from "react";
import { UserProfile } from "../interfaces";

type StoreState = {
  userProfile: UserProfile | null;
};

const initialState: StoreState = {
  userProfile: null,
};

const StoreProvider = (props: PropsWithChildren<{}>) => {
  const { keycloak } = useKeycloak();
  const [, setUserProfile] = useStore<UserProfile | null>("userProfile", null);

  useEffect(() => {
    // load user profile from keycloak
    keycloak
      .loadUserProfile()
      .then((u) => {
        setUserProfile(u as UserProfile);
      })
      .catch(() => {
        keycloak.loadUserInfo().then((u: any) => {
          setUserProfile({
            username: u.preferred_username,
          } as UserProfile);
        });
      });
  }, [keycloak]);

  return <>{props.children}</>;
};

export default withStore(StoreProvider, initialState);
