import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const BudgetForecastForecastDialog = (props: { forecast: Forecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();
  const { forecast } = props;

  const handleForecast = () => {
    setLoading(true);
    api
      .post(`budget/forecast/${forecast.id}/generate`)
      .then(() => {
        addToast("Prévision générée", `La prévision #${forecast.id} a été générée.`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", `Échec à la génération de la prévision #${forecast.id}`, "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Génération en cours..." onClose={() => props.close(false)}>
      <ModalHeader>Générer la prévision</ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous la génération de la prévision budgétaire : "<Span isBold>{props.forecast.label}</Span>" (#
          {props.forecast.id}) ?
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => handleForecast()}>
            Générer la prévision
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};
export default BudgetForecastForecastDialog;
