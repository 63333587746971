import { useStorageState } from "react-storage-hooks";
import { AgGridReact } from "ag-grid-react";
import moment from "moment/moment";

import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridOptions,
  GridReadyEvent,
  RowGroupingDisplayType,
  SortChangedEvent,
  ValueGetterParams,
} from "ag-grid-community";

import frameworkComponents from "../../../components/AgGrid";
import AG_GRID_LOCALE_FR from "../../../config/aggrid-locale.fr";
import { useGridStorageState } from "../../../hooks/aggrid/useGridStorageState";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { BudgetForecast, Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const BLUE = "#1f5cb7";
const BROWN = "#833030";
const GREY = "#1c1c1c";

interface Overwrite {
  component_family_code: string;
  product_code: string;
  week: number;
}

const BudgetForecastInputResultGrid = ({
  forecast,
  onQtyUpdate,
}: {
  forecast: BudgetForecast;
  onQtyUpdate: () => void;
}) => {
  const { addToast } = useToast();
  const preveolApi = useApiInstance();
  const baseUrl = `/budget/forecast/${forecast.id}/input`;
  const [{ data }, refetch] = useApiGet(baseUrl);
  const [{ data: overwrites }, updateOverwrites] = useApiGet(`${baseUrl}/overwrite`);

  const storageStateOptions = useGridStorageState(`${baseUrl}`);
  const [filterModel] = useStorageState<{ [key: string]: any }>(sessionStorage, `${baseUrl}-filter-model`, []);

  const editable = forecast && forecast.status === "DRAFT";

  const onCellValueUpdate = (params: CellValueChangedEvent) => {
    const week = parseInt(params.column.getColId().slice(1), 10);
    // entered values are to be taken as correction, not new values
    // null value account for removing entry
    const newValue = params.newValue ? Math.max(params.oldValue + params.newValue, 0) : null;
    preveolApi
      .post(`${baseUrl}/entry`, {
        week: week,
        oldValue: params.oldValue,
        newValue: newValue,
        product: params.data.product_code,
        component: params.data.component_family_code,
      })
      .then((response) => {
        const message = `${params.data.product_code} modifié en semaine ${params.column.getColId()}.`;
        addToast("Valeur mise à jour", message, "success", "top");
        updateOverwrites();
        params.data[params.column.getColId()] = response.data;
        params.api.refreshCells();
      })
      .catch((error) => {
        addToast("Saisie erronée", error.response.data.message, "error", "top");
        // reset old value in grid
        params.data[params.column.getColId()] = params.oldValue ?? "";
        params.api.refreshCells();
      });
  };

  const aggCellStyle = (params: CellClassParams) => {
    let style: { [key: string]: string } = { "text-align": "right" };
    if (params.node.footer) {
      return { ...style, "font-weight": "500", color: BROWN };
    }
    if (params.colDef.cellClass && params.colDef.cellClass === "quantity") {
      return { ...style, "font-weight": "400", color: params.data?.product_code === "APPORTS" ? BLUE : GREY };
    }
    if (params.colDef.cellClass && params.colDef.cellClass === "week") {
      style = { ...style, "font-weight": "400", color: params.data?.product_code === "APPORTS" ? BLUE : GREY };
      if (params.data) {
        const week = params.colDef.field ? parseInt(params.colDef.field.slice(1), 10) : null;
        const overwrite = overwrites.filter(
          (overwrite: Overwrite) =>
            overwrite.component_family_code === params.data?.component_family_code &&
            overwrite.product_code === params.data?.product_code &&
            overwrite.week === week
        );
        style = { ...style, textDecoration: overwrite.length ? "underline" : "none" };
      }
    }
    return style;
  };

  const isEditableWeek = (params: EditableCallbackParams | CellClassParams) => {
    // empty cells are not
    const isNotEmpty = params.colDef.field ? params.data[params.colDef.field] !== null : false;
    return editable && params.data?.product_code !== "APPORTS" && isNotEmpty;
  };

  const quantityColDef: ColDef = {
    filter: false,
    valueFormatter: numberValueFormatter({
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      suffix: "",
    }),
    cellStyle: aggCellStyle,
    aggFunc: "sum",
  };

  const rowGroupingOptions: GridOptions = {
    groupDisplayType: RowGroupingDisplayType.SINGLE_COLUMN,
    autoGroupColumnDef: {
      headerName: "Filière",
      sort: "asc",
      width: 200,
      cellRendererParams: {
        suppressCount: true,
        footerValueGetter: (params: any) => `Balance ${params.value}`,
      },
      pinned: "left",
    },
    groupIncludeFooter: true,
    suppressAggFuncInHeader: true,
    // onFirstDataRendered: (event: FirstDataRenderedEvent) => {
    //   event.columnApi.autoSizeColumns(["ag-Grid-AutoColumn"]);
    // },
  };

  // for column move from inside column sidebar, upgrade to ag-grid 26.1.0 -- see
  // https://stackoverflow.com/questions/65413802/is-there-way-moving-columns-in-the-column-section-of-the-tool-panel-of-ag-grid
  const gridOptions: GridOptions = {
    ...rowGroupingOptions,
    frameworkComponents: frameworkComponents,
    // rememberGroupStateWhenNewData: true,
    enableBrowserTooltips: true,
    columnHoverHighlight: true,
    sideBar: {
      toolPanels: ["filters"],
      defaultToolPanel: "filters",
    },
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    },
    onCellValueChanged: onCellValueUpdate,
    localeText: AG_GRID_LOCALE_FR,
  };

  let weeks: ColDef[] = [];
  const weeksNumber = moment({ year: forecast.year }).isoWeeksInYear();
  Array(weeksNumber)
    .fill(0)
    .forEach((_, i) =>
      weeks.push({
        field: `s${String(i + 1).padStart(2, "0")}`,
        type: "numericColumn",
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        filter: false,
        cellClass: "week",
        ...quantityColDef,
        minWidth: 80,
        editable: isEditableWeek,
        cellEditor: "numberCellEditor",
      })
    );

  const baseColumnDefs: ColDef[] = [
    {
      field: "component_family_code",
      headerName: "Filière",
      rowGroup: true,
      rowGroupIndex: 0,
      hide: true,
      filter: false,
      valueFormatter: (params: any) => {
        const node = params.node.allLeafChildren[0].data;
        return `${node.component_family_code} - ${node.component_family_label}`;
      },
      pinned: "left",
    },
    { field: "component_family_code", headerName: "Code filière", filter: true, hide: true },
    { field: "component_family_label", headerName: "Filière", filter: "agTextColumnFilter", hide: true },
    {
      colId: "product",
      headerName: "Produit",
      filter: false,
      valueGetter: (params: ValueGetterParams) => {
        if (params.node?.group) return "";
        return `${params.data?.product_code} - ${params.data?.product_label}`;
      },
      pinned: "left",
      cellStyle: (params: CellClassParams) => {
        return { "font-weight": "400", color: params.data?.product_code === "APPORTS" ? BLUE : GREY };
      },
    },
    { field: "product_code", headerName: "Code produit", hide: true, filter: false },
    { field: "product_label", headerName: "Label produit", hide: true, filter: false },
    {
      field: "total_balanced_consumption_quantity",
      headerName: "Annuel",
      cellClass: "quantity",
      ...quantityColDef,
      pinned: "left",
      width: 90,
    },
    {
      colId: "total_weekly_quantity",
      headerName: "Hebdo",
      cellClass: "quantity",
      ...quantityColDef,
      pinned: "left",
      width: 90,
      valueGetter: (params: ValueGetterParams) => {
        if (!params.node?.group && params.data?.product_code !== "APPORTS") {
          const weeks = Object.keys(params.data).filter((key) => /^s\d+/.test(key));
          return weeks.reduce((sum, key) => sum + params.data[key], 0);
        }
        return "";
      },
    },
    {
      colId: "gap_quantity",
      headerName: "Ec. total",
      cellClass: "quantity",
      ...quantityColDef,
      pinned: "left",
      width: 90,
      valueGetter: (params: ValueGetterParams) => {
        if (!params.node?.group && params.data?.product_code !== "APPORTS") {
          const weeks = Object.keys(params.data).filter((key) => /^s\d+/.test(key));
          return (
            params.data.total_balanced_consumption_quantity - weeks.reduce((sum, key) => sum + params.data[key], 0)
          );
        }
        return "";
      },
    },
  ];

  if (forecast.subType === "REVIEW") {
    baseColumnDefs.push({
      colId: "gap_quantity_future",
      headerName: "Ec. futur",
      cellClass: "quantity",
      ...quantityColDef,
      pinned: "left",
      width: 90,
      valueGetter: (params: ValueGetterParams) => {
        if (!params.node?.group && params.data?.product_code !== "APPORTS") {
          const weeks = Object.keys(params.data).filter((key) => {
            const match = /^s(\d{1,2})/.exec(key);
            return match && parseInt(match[1], 10) >= forecast.reviewWeek;
          });
          return params.data.future_sales_quantity - weeks.reduce((sum, key) => sum + params.data[key], 0);
        }
        return "";
      },
    });
  }

  let columnDefs: ColDef[] = [...baseColumnDefs, ...weeks];

  return (
    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        gridOptions={gridOptions}
        columnDefs={columnDefs}
        rowData={data}
        onSortChanged={(event: SortChangedEvent) => storageStateOptions.onSortChanged(event)}
        onFilterChanged={(event: FilterChangedEvent) => storageStateOptions.onFilterChanged(event)}
        onFirstDataRendered={(event: FirstDataRenderedEvent) => storageStateOptions.onFirstDataRendered(event)}
        onGridReady={(event: GridReadyEvent) => storageStateOptions.onGridReady(event)}
        groupDefaultExpanded={Object.keys(filterModel).length ? -1 : 0}
      />
    </div>
  );
};

export default BudgetForecastInputResultGrid;
