import React, { useMemo, useState } from "react";
import moment from "moment";

import {
  CellClassParams,
  CellDoubleClickedEvent,
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  RowGroupingDisplayType,
  RowNode,
  SortChangedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { Span, Code } from "@zendeskgarden/react-typography";
import { ReactComponent as EarlySeasonIcon } from "@zendeskgarden/svg-icons/src/12/calendar-fill.svg";

import DashboardPanel from "./panels/DashboardPanel";
import PackagingPanel from "./panels/PackagingPanel";
import CombinationExceptionPanel from "./panels/CombinationExceptionPanel";
import ForecastResultOverwriteDialog from "../dialogs/ForecastResultOverwriteDialog";

import frameworkComponents from "../../../components/AgGrid";
import { useApiGet } from "../../../hooks/useApi";
import { useGridStorageState } from "../../../hooks/aggrid/useGridStorageState";
import { CombinationInformation, ProductInformation, WeeklyForecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const GREY = "#6c6c6c";
const GREEN = "#09b800";
const BROWN = "#833030";
const DAYS = ["lun", "mar", "mer", "jeu", "ven", "sam"];

const ProductComponent = (props: ICellRendererParams & { productInfo: ProductInformation[] }) => {
  if (props.node.footer) {
    return <Span>Total {props.value}</Span>;
  }
  const data = props.node.allLeafChildren[0].data;
  const productInfo = props.productInfo.find((info: ProductInformation) => info.product_code === data.product_code);
  const productFluctuationMin =
    productInfo && productInfo.fluctuation_rate_min !== null ? `${productInfo.fluctuation_rate_min} %` : "[]";
  const productFluctuationMax =
    productInfo && productInfo.fluctuation_rate_max !== null ? `${productInfo.fluctuation_rate_max} %` : "[]";
  const productFluctuationRange = `Fluctuations autorisées - min : ${productFluctuationMin} - max : ${productFluctuationMax}`;
  return (
    <div title={productInfo?.prevision_option === "HISTORIQUE" ? productFluctuationRange : ""}>
      <Span isBold>{props.valueFormatted} - </Span>
      <Code hue="red" size="medium">
        {productInfo && productInfo.prevision_option ? productInfo.prevision_option[0] : "?"}
      </Code>
      <Span hue="grey"> - {data.product_label}</Span>
    </div>
  );
};

const CombinationComponent = (props: ICellRendererParams & { combinationInfo: CombinationInformation[] }) => {
  if (props.node.group || !props.data) return "";
  const data = props.data;
  const combinationInfo = props.combinationInfo.find(
    (info: CombinationInformation) =>
      info.product_code === data.product_code &&
      info.shipping_warehouse_code === data.shipping_warehouse_code &&
      info.delivered_customer_code === data.delivered_customer_code
  );
  let tooltip = `Combinaison en début de saison - ${combinationInfo?.shipping_weeks} semaine`;
  if (combinationInfo?.shipping_weeks && combinationInfo?.shipping_weeks > 1) {
    tooltip = `${tooltip}s`;
  }

  return (
    <div title={combinationInfo?.is_early_season ? tooltip : ""}>
      {combinationInfo?.is_early_season && (
        <Span hue="green">
          {" "}
          <EarlySeasonIcon />{" "}
        </Span>
      )}
      <Span>{props.valueFormatted} </Span>
    </div>
  );
};

const ForecastResultGrid = (props: {
  forecast: WeeklyForecast;
  familyCode: string;
  productInformation: ProductInformation[];
  combinationInformation: CombinationInformation[];
  status: string;
  unit: string;
  filter: boolean;
}) => {
  const { forecast, familyCode, productInformation, combinationInformation, status, unit, filter } = props;
  const [overwriteDialog, setOverwriteDialog] = useState<
    { cell: { [key: string]: string }; node: { [key: string]: string }; product: { [key: string]: string } } | undefined
  >();
  const [{ data }, refetch] = useApiGet(
    `/weekly/forecast/${forecast.id}/${familyCode}/${unit}${filter ? "/small" : ""}`
  );
  const [{ data: familyComponents }] = useApiGet(`/input/${forecast.id}/${familyCode}/component`, {});
  const storageStateOptions = useGridStorageState(`/weekly/forecast/${forecast.id}/${familyCode}`);

  const dataFormatter = new Intl.NumberFormat("fr-FR", { style: "decimal", maximumFractionDigits: 0 });

  const rowGroupingOptions: GridOptions = {
    groupDisplayType: RowGroupingDisplayType.SINGLE_COLUMN,
    autoGroupColumnDef: {
      headerName: "Produit",
      sort: "asc",
      minWidth: 300,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        footerValueGetter: (params: any) => {
          const rowIndex = params.node.rowGroupIndex;
          if (rowIndex === 1) {
            return `Total S${params.value.slice(-2)}`;
          }
          return `Total ${params.value}`;
        },
      },
    },
    groupIncludeFooter: true,
    suppressAggFuncInHeader: true,
    onFirstDataRendered: (event: FirstDataRenderedEvent) => {
      event.columnApi.autoSizeColumns(["ag-Grid-AutoColumn"]);
    },
  };

  /**
   * Return relative week for a given week number
   */
  const getRelativeWeek = (year: number, week: number) => {
    const date = moment(`${year}`).add(week, "weeks");
    const dateRef = moment(`${forecast.year}`).add(forecast.period, "weeks");
    return date.diff(dateRef, "week");
  };

  /**
   * check if node is in the past
   */
  const isNotInThePast = (colDef: ColDef, node: RowNode) => {
    const day = colDef.colId;
    const nodeData = getNodeData(node);
    if (day && nodeData.shipping_week) {
      const date = moment()
        .year(nodeData.shipping_year)
        .week(nodeData.shipping_week)
        .day(DAYS.indexOf(day) + 1);
      return date.isSameOrAfter(moment(), "day");
    }
    return false;
  };

  /**
   * access to forecast/original/initial/historic values for given day & node
   */
  const getValue = (day: string, type: string, node: RowNode) => {
    const obj = node.data || node.aggData;
    let field: string = day;
    switch (type) {
      case "FORECAST":
        field = `p_${field}`;
        break;
      case "ORIGINAL":
        field = `_${field}`;
        break;
      case "INITIAL":
        field = `__${field}`;
        break;
      case "HISTORIC":
        field = `h_${field}`;
        break;
    }

    if (obj && obj[field] !== undefined) {
      return obj[field];
    }

    return null;
  };

  /**
   * Check if value is overwritten for given column & node
   */
  const isOverwrittenValue = (colDef: ColDef, node: RowNode) => {
    const data = node.data || node.aggData;
    const field = colDef.colId!;
    const forecastField = node.data ? field : `p_${field}`;
    const originalField = `_${field}`;
    if (data?.[originalField] !== undefined) {
      return data[originalField] !== data[forecastField];
    }

    return false;
  };

  /**
   * Get node data
   */
  const getNodeData = (node: RowNode) => {
    let data: { [key: string]: any } = {};
    if (node.group) {
      while (node?.parent) {
        if (node.field) {
          data[node.field] = node.key;
        } else {
          // shipping_week column is a valueGetter and field is undefined
          data["shipping_week"] = node.key ? node.key.slice(-2) : "";
          data["shipping_year"] = node.key ? node.key.slice(0, 4) : "";
        }
        node = node.parent;
      }
    } else {
      data = node.data;
    }
    return data;
  };

  /**
   * Check if node has activity data
   */
  const isActivityValue = (day: string, data: any) => {
    if (day !== "total") {
      const dayMoment = moment()
        .year(data.shipping_year)
        .week(data.shipping_week)
        .day(DAYS.indexOf(day) + 1);
      return dayMoment.isBefore(moment(), "day");
    }
    return DAYS.every((weekDay) => {
      const weekDayMoment = moment()
        .year(data.shipping_year)
        .week(data.shipping_week)
        .day(DAYS.indexOf(weekDay) + 1);
      return weekDayMoment.isBefore(moment(), "day");
    });
  };

  const isActivityOnlyValue = (colDef: ColDef, node: RowNode) => {
    if (colDef.colId) {
      const day = colDef.colId;
      if (!node.group) {
        const data = node.data;
        return isActivityValue(day, data);
      }
      // check no children is forecast data
      const forecastChildren = node.allLeafChildren.filter((node) => !isActivityValue(day, node.data));
      return forecastChildren.length === 0;
    }
  };

  const isForecastOnlyValue = (colDef: ColDef, node: RowNode) => {
    if (colDef.colId) {
      const day = colDef.colId;
      if (!node.group) {
        const data = node.data;
        return !isActivityValue(day, data);
      }
      // check no children is activity data
      const activityChildren = node.allLeafChildren.filter((node) => isActivityValue(day, node.data));
      return activityChildren.length === 0;
    }
  };

  /**
   * Check if node is reservation data only
   */
  const isReservationOnlyValue = (node: RowNode) => {
    let data = getNodeData(node);
    if (data.order_type) return data.order_type !== "FDR";
    const otsChildren = node.allLeafChildren.filter((node) => node.data.order_type === "FDR");
    return otsChildren.length === 0;
  };

  /**
   * Check if filters are set
   */
  const isWithoutFilter = (node: RowNode) => {
    const model = node["gridApi"].getFilterModel();
    if (!model || Object.keys(model).length === 0) return true;
    return ![
      "order_type",
      "shipping_warehouse_code",
      "customer_subtype_label",
      "customer_subtype_code",
      "delivered_customer_name",
      "delivered_customer_code_1",
    ].some((entry: string) => model.hasOwnProperty(entry));
  };

  /**
   * Check if value can be overwritten for given node
   */
  const canBeOverwritten = (colDef: ColDef, node: RowNode) => {
    // exclude footers
    if (node.footer) return false;
    // exclude historical data
    return isForecastOnlyValue(colDef, node) && isNotInThePast(colDef, node) && isWithoutFilter(node);
  };

  /**
   * On cell double click : open edit dialog
   *
   * @param event
   */
  const onCellDoubleClicked = (event: CellDoubleClickedEvent) => {
    if (status === "NO_VALIDATION" && event.colDef.cellClass === "day" && canBeOverwritten(event.colDef, event.node)) {
      const day = event.column.getId();

      // use forecast value (as event.value merge historic and forecast data)
      const obj = event.node.data || event.node.aggData;
      const forecast = obj[`p_${day}`];
      const original = obj[`_${day}`];
      const initial = obj[`__${day}`];
      let overwrite = original !== null && original !== forecast ? forecast : null;
      if (overwrite === undefined) overwrite = obj[day]; // opening case

      // get product list with same family code
      let productCodes: { [key: string]: string } = {};
      event.api.forEachNode((node) => {
        if (node.data?.product_code) {
          let code = `${node.data?.product_code}`;
          if (!(code in productCodes)) productCodes[code] = `${node.data?.product_label}`;
        }
      });
      const nodeData = getNodeData(event.node);
      delete productCodes[nodeData.product_code];

      // get product weight
      let weight_kg = "";
      event.api.forEachNode((node) => {
        if (node.data?.product_code && node.data.product_code === nodeData.product_code) {
          weight_kg = node.data?.weight_kg;
        }
      });

      const data = { ...nodeData, day, original, initial, overwrite, weight_kg };
      setOverwriteDialog({ cell: data, node: obj, product: productCodes });
    }
  };

  /**
   * Day base column definition
   */
  const dayColDef: ColDef = {
    filter: false,
    cellClass: "day",
    valueGetter: (params: ValueGetterParams) => {
      if (!params.data || !params.colDef.colId) return "";
      const data = params.data;
      const day = params.colDef.colId;
      if (data.shipping_week && data.shipping_year) {
        const relativeWeek = getRelativeWeek(parseInt(data.shipping_year, 10), parseInt(data.shipping_week, 10));
        // future weeks S+x
        if (relativeWeek > 0) {
          return data[day];
        }
        // past weeks S-x
        if (relativeWeek < 0) {
          return data[`h_${day}`];
        }
        // current week S+0
        if (relativeWeek === 0) {
          if (day !== "total") {
            const dayMoment = moment()
              .year(data.shipping_year)
              .week(data.shipping_week)
              .day(DAYS.indexOf(day) + 1);
            if (dayMoment.isSameOrAfter(moment(), "day")) return data[day];
            return data[`h_${day}`] ?? 0;
          }
          return DAYS.reduce((total, weekDay) => {
            const weekDayMoment = moment()
              .year(data.shipping_year)
              .week(data.shipping_week)
              .day(DAYS.indexOf(weekDay) + 1);
            let quantity = data[weekDay];
            if (weekDayMoment.isBefore(moment(), "day")) {
              quantity = data[`h_${weekDay}`] ?? 0;
            }
            return total + quantity;
          }, 0);
        }
      }
    },
    valueFormatter: numberValueFormatter({
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      suffix: unit === "kg" ? " kg" : " colis",
    }),
    cellStyle: (params: CellClassParams) => {
      let style: { [key: string]: string } = { "font-weight": "bold", "text-align": "right" };
      if (isOverwrittenValue(params.colDef, params.node)) {
        style = { ...style, textDecoration: "underline" };
      }
      if (isReservationOnlyValue(params.node)) {
        style = { ...style, color: GREEN };
      }
      if (isActivityOnlyValue(params.colDef, params.node)) {
        style = { ...style, color: GREY };
      }
      if (params.node.footer) {
        style = { ...style, color: BROWN, textDecoration: "none" };
      }
      return style;
    },
    aggFunc: "sum",
    tooltipValueGetter: (params) => {
      if (!params.colDef || !("colId" in params.colDef) || params.colDef.colId === undefined) return "";
      const original = getValue(params.colDef.colId, "ORIGINAL", params.node!);
      if (isOverwrittenValue(params.colDef, params.node!)) {
        return `Valeur initiale : ${dataFormatter.format(original)} ${unit}`;
      }
      return "";
    },
  };

  /**
   * Hidden day base column definition
   */
  const hiddenDayColDef: ColDef = {
    filter: false,
    hide: true,
    aggFunc: "sum",
  };

  /**
   * Week value formatter
   */
  const weekValueFormatter = (params: any) => {
    const data = params.node.allLeafChildren[0].data;
    const diff = getRelativeWeek(data.shipping_year, data.shipping_week);
    const leadingZero = data.shipping_week < 10 ? "0" : "";
    const plus = diff < 0 ? " " : "+";
    return `S${plus}${diff} (S${leadingZero}${data.shipping_week})`;
  };

  const dashboardPanel = {
    id: "custom",
    width: 550,
    labelDefault: "Tableau de bord",
    labelKey: "dashboard",
    iconKey: "dashboard",
    toolPanel: "dashboardPanel",
    toolPanelParams: {
      forecast,
      familyCode,
      familyComponents,
    },
  };

  const packagingPanel = {
    id: "packaging",
    width: 450,
    labelDefault: "Conditionnement",
    labelKey: "packaging",
    iconKey: "packaging",
    toolPanel: "packagingPanel",
    toolPanelParams: {
      forecast,
      familyCode,
    },
  };

  const openingPanel = {
    id: "opening",
    width: 480,
    labelDefault: "Ouvertures",
    labelKey: "opening",
    iconKey: "maximize",
    toolPanel: "combinationExceptionPanel",
    toolPanelParams: {
      forecast,
      familyCode,
      exceptionType: "opening",
      refreshGrid: () => refetch(),
    },
  };

  const closingPanel = {
    id: "closing",
    width: 350,
    labelDefault: "Fermetures",
    labelKey: "closing",
    iconKey: "minimize",
    toolPanel: "combinationExceptionPanel",
    toolPanelParams: {
      forecast,
      familyCode,
      exceptionType: "closing",
      refreshGrid: () => refetch(),
    },
  };

  /**
   * Grid options
   */
  const gridOptions: GridOptions = {
    ...rowGroupingOptions,
    frameworkComponents: {
      ...frameworkComponents,
      dashboardPanel: DashboardPanel,
      packagingPanel: PackagingPanel,
      combinationExceptionPanel: CombinationExceptionPanel,
      ProductComponent,
      CombinationComponent,
    },
    rememberGroupStateWhenNewData: true,
    enableBrowserTooltips: true,
    columnHoverHighlight: true,
    icons: {
      dashboard: '<span class="ag-icon ag-icon-chart"></span>',
      packaging: '<span class="ag-icon ag-icon-grip"></span>',
    },
    sideBar: {
      toolPanels: [dashboardPanel, packagingPanel, openingPanel, closingPanel, "filters"],
      defaultToolPanel: "filter",
    },
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["clear"],
      },
    },
    onCellDoubleClicked,
  };

  const columnDefs = useMemo(
    () => [
      // Produit
      {
        field: "product_code",
        headerName: "Produit",
        rowGroup: true,
        rowGroupIndex: 0,
        hide: true,
        filter: false,
        cellRenderer: "ProductComponent",
        cellRendererParams: { productInfo: productInformation },
        valueFormatter: (params: any) => {
          const data = params.node.allLeafChildren[0].data;
          return `${data.product_code}`;
        },
      },
      { field: "product_code", headerName: "Code produit", hide: true, filter: true },
      { field: "product_label", headerName: "Label produit", filter: "agTextColumnFilter", hide: true },
      { field: "product_line_code", headerName: "Code conditionnement", hide: true, filter: false },
      { field: "product_line_label", headerName: "Conditionnement", hide: true, filter: true },
      { field: "weight_kg", headerName: "Poids", hide: true, filter: false },

      // Semaine
      {
        colId: "week",
        headerName: "Semaine",
        rowGroup: true,
        rowGroupIndex: 1,
        hide: true,
        valueGetter: (params: ValueGetterParams) => {
          const week = `${params.data.shipping_week}`.padStart(2, "0");
          return `${params.data.shipping_year}-${week}`;
        },
        valueFormatter: weekValueFormatter,
      },

      // Type d'ordre
      {
        field: "order_type",
        headerName: "Type",
        rowGroup: true,
        rowGroupIndex: 2,
        hide: true,
      },

      // Dépôt
      {
        field: "shipping_warehouse_code",
        headerName: "Dépôt",
        rowGroup: true,
        rowGroupIndex: 3,
        hide: true,
        valueFormatter: (params: any) => {
          return `Dépôt ${params.value}`;
        },
      },

      // Type client
      { field: "customer_type_code", headerName: "Code type client", hide: true, filter: false },
      { field: "customer_type_label", headerName: "Type client", hide: true, filter: false },

      // Enseigne
      {
        field: "customer_subtype_code",
        headerName: "Enseigne",
        rowGroup: true,
        rowGroupIndex: 4,
        hide: true,
        filter: false,
        valueFormatter: (params: any) => {
          const data = params.node.allLeafChildren[0].data;
          return `${data.customer_subtype_code} - ${data.customer_subtype_label}`;
        },
      },
      { field: "customer_subtype_code", headerName: "Code enseigne", hide: true, filter: false },
      { field: "customer_subtype_label", headerName: "Enseigne", hide: true, filter: "agTextColumnFilter" },

      // Client livré
      {
        field: "delivered_customer_code",
        headerName: "Client livré",
        filter: false,
        minWidth: 100,
        cellRenderer: "CombinationComponent",
        cellRendererParams: { combinationInfo: combinationInformation },
        valueFormatter: (params: any) => {
          if (params.data) {
            return `${params.data.delivered_customer_code} - ${params.data.delivered_customer_name}`;
          }
          return params.value;
        },
      },
      { field: "delivered_customer_code", headerName: "Code client livré", hide: true, filter: true },
      { field: "delivered_customer_name", headerName: "Nom du client livré", hide: true, filter: "agTextColumnFilter" },

      // Jours
      { colId: "lun", headerName: "Lundi", ...dayColDef },
      { field: "lun", colId: "p_lun", ...hiddenDayColDef },
      { field: "h_lun", ...hiddenDayColDef },
      { field: "_lun", ...hiddenDayColDef },
      { field: "__lun", ...hiddenDayColDef },
      { colId: "mar", headerName: "Mardi", ...dayColDef },
      { field: "mar", colId: "p_mar", ...hiddenDayColDef },
      { field: "h_mar", ...hiddenDayColDef },
      { field: "_mar", ...hiddenDayColDef },
      { field: "__mar", ...hiddenDayColDef },
      { colId: "mer", headerName: "Mercredi", ...dayColDef },
      { field: "mer", colId: "p_mer", ...hiddenDayColDef },
      { field: "h_mer", ...hiddenDayColDef },
      { field: "_mer", ...hiddenDayColDef },
      { field: "__mer", ...hiddenDayColDef },
      { colId: "jeu", headerName: "Jeudi", ...dayColDef },
      { field: "jeu", colId: "p_jeu", ...hiddenDayColDef },
      { field: "h_jeu", ...hiddenDayColDef },
      { field: "_jeu", ...hiddenDayColDef },
      { field: "__jeu", ...hiddenDayColDef },
      { colId: "ven", headerName: "Vendredi", ...dayColDef },
      { field: "ven", colId: "p_ven", ...hiddenDayColDef },
      { field: "h_ven", ...hiddenDayColDef },
      { field: "_ven", ...hiddenDayColDef },
      { field: "__ven", ...hiddenDayColDef },
      { colId: "sam", headerName: "Samedi", ...dayColDef },
      { field: "sam", colId: "p_sam", ...hiddenDayColDef },
      { field: "h_sam", ...hiddenDayColDef },
      { field: "_sam", ...hiddenDayColDef },
      { field: "__sam", ...hiddenDayColDef },
      { colId: "total", headerName: "Total", ...dayColDef, cellClass: "day" },
      { field: "total", colId: "p_total", ...hiddenDayColDef },
      { field: "h_total", ...hiddenDayColDef },
      { field: "_total", ...hiddenDayColDef },
      { field: "__total", ...hiddenDayColDef },
    ],
    [unit]
  );

  const onOverwriteDialogClose = (success: boolean) => {
    setOverwriteDialog(undefined);
    success && refetch();
  };

  if (!familyComponents) {
    return null;
  }

  return (
    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        gridOptions={gridOptions}
        columnDefs={columnDefs}
        rowData={data}
        onSortChanged={(event: SortChangedEvent) => storageStateOptions.onSortChanged(event)}
        onFilterChanged={(event: FilterChangedEvent) => storageStateOptions.onFilterChanged(event)}
        onFirstDataRendered={(event: FirstDataRenderedEvent) => storageStateOptions.onFirstDataRendered(event)}
        onGridReady={(event: GridReadyEvent) => storageStateOptions.onGridReady(event)}
      />
      {overwriteDialog && (
        <ForecastResultOverwriteDialog
          forecast={forecast}
          familyCode={familyCode}
          data={overwriteDialog.cell}
          node={overwriteDialog.node}
          products={overwriteDialog.product}
          unit={unit}
          close={onOverwriteDialogClose}
        />
      )}
    </div>
  );
};

export default ForecastResultGrid;
