import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";

import { Autocomplete, Form, Multiselect } from "../../../components/Form";
import LoadingModal from "../../../components/LoadingModal";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { WeeklyForecast } from "../../../interfaces";

type FamilyOption = {
  component_family_code: string;
  component_family_label: string;
};

const FamilyOverwriteDialog = (props: {
  close: Function;
  forecast: WeeklyForecast;
  weeks?: string[];
  id?: string;
  familyLabel?: string;
  variation?: string;
}) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const api = useApiInstance();
  const [{ data: familyOptions }] = useApiGet(
    "/datasource/nomenclature/values/component_family_code/component_family_label"
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const updateFamilyOverwrite = (data: any) => {
    setLoading(true);
    api
      .post(`forecast/${props.forecast?.id}/family`, { ...data, familyCode: props.id })
      .then(() => {
        addToast("Prévision mise à jour", `La prévision #${props.forecast.id} a été mise à jour`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à jour de la prévision", "error", "top");
        setLoading(false);
      });
  };

  const createFamilyOverwrite = (data: any) => {
    setLoading(true);
    api
      .post(`forecast/${props.forecast?.id}/family`, {
        ...data,
        familyCode: familyOptions.find(
          (option: FamilyOption) =>
            `${option.component_family_code} - ${option.component_family_label}` === data.familyCode
        ).component_family_code,
      })
      .then(() => {
        addToast("Prévision mise à jour", `La prévision #${props.forecast.id} a été mise à jour`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à jour de la prévision", "error", "top");
        setLoading(false);
      });
  };

  const handleFormSubmit = (data: any) => {
    if (props.id) {
      updateFamilyOverwrite(data);
    } else {
      createFamilyOverwrite(data);
    }
  };

  const getOptions = () => {
    const options = [];
    for (let i = 1; i <= 10; i++) {
      const number = ((props.forecast.period + 53 - i) % 53).toString();
      if (number === "0") {
        options.push("53");
      } else {
        options.push(((props.forecast.period + 53 - i) % 53).toString());
      }
    }
    return options;
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Modification en cours..." onClose={() => props.close(false)}>
      <ModalHeader>Surcharger une filière</ModalHeader>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Body>
          <Form>
            <Field>
              <Label>Filière</Label>
              <Autocomplete
                control={control}
                className="mt-2"
                name="familyCode"
                options={
                  familyOptions
                    ?.map(
                      (option: FamilyOption) => `${option.component_family_code} - ${option.component_family_label}`
                    )
                    .sort() || []
                }
                rules={{ required: true }}
                value={props.id ? `${props.id} - ${props.familyLabel}` : ""}
                disabled={props.id ? true : false}
                validation={errors.familyCode ? "error" : undefined}
              />
              {errors.familyCode && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner un code filière
                </Message>
              )}
            </Field>
            <Field>
              <Label>Semaines de références</Label>
              <Multiselect
                className="mt-2"
                control={control}
                name="weeks"
                options={getOptions()}
                validation={errors.weeks ? "error" : undefined}
                value={props.weeks || []}
              />
            </Field>
            <Field>
              <Label>Variation globale Méli-mélo (%)</Label>
              <Input
                type="number"
                {...register("variation", { value: props.variation })}
                validation={errors.period ? "error" : undefined}
              />
            </Field>
          </Form>
        </Body>
        <Footer>
          <FooterItem>
            <Button onClick={() => props.close(false)} isBasic>
              Annuler
            </Button>
          </FooterItem>
          <FooterItem>
            <Button isPrimary type="submit">
              Surcharger la filière
            </Button>
          </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
      </form>
    </LoadingModal>
  );
};

export default FamilyOverwriteDialog;
