import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GridApi } from "ag-grid-community";
import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Button } from "@zendeskgarden/react-buttons";
import { Body as ChromeBody, Content, HeaderItem, HeaderItemWrapper } from "@zendeskgarden/react-chrome";
import { Span } from "@zendeskgarden/react-typography";

import ClosedDayDialog from "./dialogs/ClosedDayDialog";
import ClosedDayGrid from "./grids/ClosedDayGrid";
import Header from "../../components/Header";

const ClosedDay = () => {
  const [newDialog, setNewDialog] = useState<boolean>(false);
  const gridApi = useRef<GridApi>();

  const onCreateDialogClose = (success: boolean) => {
    setNewDialog(false);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  return (
    <ChromeBody>
      <Header>
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/settings">Paramètres</Link>
            <Span>Jours de fermeture</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
        <HeaderItem>
          <Button isPrimary size="small" onClick={() => setNewDialog(true)}>
            Nouvelle fermeture
          </Button>
        </HeaderItem>
      </Header>
      <Content>
        <ClosedDayGrid gridApi={gridApi} />
      </Content>

      {newDialog && <ClosedDayDialog close={onCreateDialogClose} />}
    </ChromeBody>
  );
};

export default ClosedDay;
