import { Header as BaseHeader, HeaderItem, HeaderItemIcon, HeaderItemText } from "@zendeskgarden/react-chrome";
import { Dropdown, Menu, Item, Trigger } from "@zendeskgarden/react-dropdowns";
import { ReactComponent as PersonIcon } from "@zendeskgarden/svg-icons/src/16/user-solo-stroke.svg";
import { PropsWithChildren } from "react";
import { SM } from "@zendeskgarden/react-typography";
import { useKeycloak } from "@react-keycloak/web";
import { useStore } from "react-context-hook";
import { UserProfile } from "../interfaces";
import { Helmet } from "react-helmet";

const Header = (props: PropsWithChildren<{ helmetTitle?: string }>) => {
  const { helmetTitle, children } = props;
  const { keycloak } = useKeycloak();
  const [userProfile] = useStore<UserProfile | null>("userProfile", null);

  return (
    <BaseHeader>
      {helmetTitle && (
        <Helmet>
          <title>{`${helmetTitle} | Prévéol`}</title>
        </Helmet>
      )}
      {children}
      <Dropdown>
        <Trigger>
          <HeaderItem>
            <HeaderItemIcon>
              <PersonIcon />
            </HeaderItemIcon>
            <HeaderItemText>
              <SM>{userProfile && userProfile.username}</SM>
            </HeaderItemText>
          </HeaderItem>
        </Trigger>
        <Menu>
          <Item value="logout" onClick={() => keycloak.logout()}>
            Déconnexion
          </Item>
        </Menu>
      </Dropdown>
    </BaseHeader>
  );
};

export default Header;
