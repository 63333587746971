import { GridOptions } from "ag-grid-community";
import { Datasource } from "../../../interfaces";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";

const DatasourceGrid = ({ datasource, options }: { datasource: Datasource; options?: GridOptions }) => {
  const baseUrl = `/datasource/${datasource.code}`;
  const gridOptions = {
    defaultColDef: {
      filter: false,
    },
    ...datasource.metadata,
    ...options,
  };

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions}></ServerSideGrid>;
};

export default DatasourceGrid;
