import { Route, Routes } from "react-router-dom";

import ActivityDailyPattern from "./components/ActivityDailyPattern";
import ActivityEffect from "./components/ActivityEffect";
import ActivityReference from "./components/ActivityReference";
import ActivityWeeklyPattern from "./components/ActivityWeeklyPattern";

import { RouteTabs, RouteTab, RouteTabPanel } from "../../components/RouteTabs";
import { CompactTabList } from "../../components/Styled";
import { ApiRefetch } from "../../hooks/useApi";
import { Forecast } from "../../interfaces";

const ActivityView = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;

  return (
    <RouteTabs>
      <CompactTabList>
        <RouteTab to="">Historique ajusté</RouteTab>
        <RouteTab to="effect">Complétude de l'historique</RouteTab>
        {forecast.type === "BUDGET" && <RouteTab to="pattern/weekly">Répartion à la semaine</RouteTab>}
        {forecast.type === "WEEKLY" && <RouteTab to="pattern/daily">Répartion journalière</RouteTab>}
      </CompactTabList>
      <RouteTabPanel>
        <Routes>
          <Route index element={<ActivityReference {...props} />} />
          <Route path="effect" element={<ActivityEffect {...props} />} />
          {forecast.type === "BUDGET" && <Route path="pattern/weekly" element={<ActivityWeeklyPattern {...props} />} />}
          {forecast.type === "WEEKLY" && <Route path="pattern/daily" element={<ActivityDailyPattern {...props} />} />}
        </Routes>
      </RouteTabPanel>
    </RouteTabs>
  );
};

export default ActivityView;
