import { IDatepickerProps } from "@zendeskgarden/react-datepickers";
import { Datepicker as BaseDatepicker } from "@zendeskgarden/react-datepickers";
import { FunctionComponent } from "react";
import moment from "moment";

const dateFormatter = new Intl.DateTimeFormat("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric" });

const Datepicker: FunctionComponent<IDatepickerProps> = (props) => {
  const { children, ...datepickerProps } = props;

  const formatDate = (date: Date) => {
    return dateFormatter.format(date);
  };

  const customParseDate = (inputValue: string) => {
    const parts = inputValue.split("/");
    if (parts.length >= 3 && parts[2].length >= 4) {
      return moment(inputValue, "DD/MM/YYYY").toDate();
    }
    return new Date(NaN);
  };

  return (
    <BaseDatepicker locale="fr-FR" formatDate={formatDate} customParseDate={customParseDate} {...datepickerProps}>
      {children}
    </BaseDatepicker>
  );
};

export default Datepicker;
