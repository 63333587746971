import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GridApi } from "ag-grid-community";

import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Button } from "@zendeskgarden/react-buttons";
import { Content, HeaderItemWrapper, Body as ChromeBody } from "@zendeskgarden/react-chrome";
import { Span } from "@zendeskgarden/react-typography";

import ActivityMatchingGrid from "./grids/ActivityMatchingGrid";
import ActivityMatchingSearchGrid from "./grids/ActivityMatchingSearchGrid";
import ActivityMatchingCreateDialog from "./dialogs/ActivityMatchingCreateDialog";
import Header from "../../components/Header";

const ActivityMatching = () => {
  const [newDialog, setNewDialog] = useState<boolean>(false);
  const [search, setSearch] = useState<boolean>(false);
  const gridApiMatching = useRef<GridApi>();

  const onCreateDialogClose = (success: boolean) => {
    setNewDialog(false);
    if (success) {
      gridApiMatching.current?.refreshServerSideStore({});
    }
  };

  return (
    <ChromeBody>
      <Header helmetTitle="Alignements">
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/settings">Paramètres</Link>
            <Span>Alignements</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
        <HeaderItemWrapper className="mr-0">
          <Button isPrimary size="small" onClick={() => setSearch(!search)}>
            {search ? "Liste des alignements" : "Recherche d'historique à compléter"}
          </Button>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          <Button isPrimary size="small" onClick={() => setNewDialog(true)}>
            Nouvel alignement
          </Button>
        </HeaderItemWrapper>
      </Header>
      <Content>{search ? <ActivityMatchingSearchGrid /> : <ActivityMatchingGrid gridApi={gridApiMatching} />}</Content>

      {newDialog && <ActivityMatchingCreateDialog close={onCreateDialogClose} />}
    </ChromeBody>
  );
};

export default ActivityMatching;
