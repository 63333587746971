import { GridApi } from "ag-grid-community";
import { useApiInstance } from "../useApi";
import useToast from "../useToast";

/**
 * @returns
 */
export const useServerSideGridDeleteRows = (baseUrl: string, gridApi: GridApi | undefined) => {
  const api = useApiInstance();
  const { addToast } = useToast();

  return (rows: any[], callback: (success: boolean) => void) => {
    if (rows.length > 0) {
      // @todo : handle multiple lines
      const selectedRow = rows[0];
      api
        .delete(`${baseUrl}/${selectedRow.id}`)
        .then((req) => {
          addToast("Suppression", "La ligne a été correctement supprimée", "success");
          gridApi?.refreshServerSideStore({});
          callback(true);
        })
        .catch(() => {
          addToast("Erreur", "Suppression impossible.", "error");
          callback(false);
        });
    } else {
      callback(false);
    }
  };
};
