import { Fragment, PropsWithChildren, useState } from "react";
import { To, useMatch, useNavigate, useResolvedPath } from "react-router-dom";

import { Nav, NavItem, NavItemIcon, NavItemText } from "@zendeskgarden/react-chrome";
import { ReactComponent as BurgerIcon } from "@zendeskgarden/svg-icons/src/16/menu-fill.svg";
import { ReactComponent as HomeIcon } from "@zendeskgarden/svg-icons/src/26/home-fill.svg";
import { ReactComponent as InputIcon } from "@zendeskgarden/svg-icons/src/26/cloud-upload.svg";
import { ReactComponent as ForecastIcon } from "@zendeskgarden/svg-icons/src/26/play.svg";
import { ReactComponent as BudgetIcon } from "@zendeskgarden/svg-icons/src/26/grid-add.svg";
import { ReactComponent as SettingsIcon } from "@zendeskgarden/svg-icons/src/26/settings-fill.svg";

import { ReactComponent as ConjectoIcon } from "../assets/conjecto.svg";
import { ReactComponent as MainLogo } from "../assets/logo.svg";

const NavRouteItem = (props: PropsWithChildren<{ title: string; to: To; end?: boolean }>) => {
  const { children, to, end, ...itemProps } = props;
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: end || false });
  const navigate = useNavigate();

  return (
    <NavItem isCurrent={match !== null} onClick={() => navigate(to)} {...itemProps}>
      {children}
    </NavItem>
  );
};

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Fragment>
      <Nav isExpanded={expanded}>
        {expanded && (
          <NavItem hasLogo>
            <MainLogo width="75%" height="100%" onClick={() => setExpanded(!expanded)} />
            <NavItemText>Prévéol</NavItemText>
          </NavItem>
        )}
        {!expanded && (
          <NavItem hasLogo>
            <NavItemIcon>
              <BurgerIcon onClick={() => setExpanded(!expanded)} />
            </NavItemIcon>
          </NavItem>
        )}
        <NavRouteItem title="Accueil" to="/" end>
          <NavItemIcon>
            <HomeIcon />
          </NavItemIcon>
          {expanded && <NavItemText>Accueil</NavItemText>}
        </NavRouteItem>
        <NavRouteItem title="Données" to="datasource">
          <NavItemIcon>
            <InputIcon />
          </NavItemIcon>
          {expanded && <NavItemText>Données</NavItemText>}
        </NavRouteItem>
        <NavRouteItem title="Settings" to="settings">
          <NavItemIcon>
            <SettingsIcon />
          </NavItemIcon>
          {expanded && <NavItemText>Paramètres</NavItemText>}
        </NavRouteItem>
        <NavRouteItem title="Budget" to="budget">
          <NavItemIcon>
            <BudgetIcon />
          </NavItemIcon>
          {expanded && <NavItemText>Budget</NavItemText>}
        </NavRouteItem>
        <NavRouteItem title="Prévisions" to="forecast">
          <NavItemIcon>
            <ForecastIcon />
          </NavItemIcon>
          {expanded && <NavItemText>Prévisions</NavItemText>}
        </NavRouteItem>
        <NavItem hasBrandmark title="Made with ❤ by Conjecto">
          <NavItemIcon>
            <ConjectoIcon />
          </NavItemIcon>
          <NavItemText>Conjecto</NavItemText>
        </NavItem>
      </Nav>
    </Fragment>
  );
};

export default Navbar;
