import { useState } from "react";

import { GridApi, GridOptions, ValueFormatterParams } from "ag-grid-community";

import ActivityMatchingCreateDialog from "../dialogs/ActivityMatchingCreateDialog";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useServerSideGridDeleteRows } from "../../../hooks/aggrid/useServerSideGridDeleteRows";
import { dateValueFormatter, numberValueFormatter } from "../../../utils/helpers";

const BLUE = "#1f73b7";

const ActivityMatchingGrid = (props: { gridApi: React.MutableRefObject<GridApi | undefined> }) => {
  const { gridApi } = props;
  const [deleteDialog, setDeleteDialog] = useState<any[] | undefined>(undefined);
  const [updateDialog, setUpdateDialog] = useState<any[] | undefined>(undefined);

  const baseUrl = "/activity/matching";
  const deleteRows = useServerSideGridDeleteRows(baseUrl, gridApi.current);

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "id", hide: true, filter: false },
      { field: "product_code", headerName: "Produit" },
      { field: "product_label", headerName: "Libellé", filter: "agTextColumnFilter" },
      { field: "product_family_label", headerName: "Filière" },
      { field: "customer_subtype_code", headerName: "Enseigne(s)", filter: false },
      { field: "customer_code", headerName: "Client(s)", filter: false },
      { field: "product_code_match", headerName: "Référence(s)", filter: false },
      {
        field: "sales_quantity",
        headerName: "Quantité (kg)",
        cellStyle: { textAlign: "right", color: BLUE },
        editable: true,
        valueFormatter: numberValueFormatter({ suffix: "kg" }),
      },
      { field: "comment", headerName: "Commentaire", editable: true },
      {
        field: "matching_start",
        headerName: "Date début",
        filter: "agDateColumnFilter",
        cellStyle: { color: BLUE },
        editable: true,
        cellEditor: "dateCellEditor",
        valueFormatter: dateValueFormatter(),
      },
      {
        field: "matching_end",
        headerName: "Date fin",
        filter: "agDateColumnFilter",
        cellStyle: { color: BLUE },
        editable: true,
        cellEditor: "dateCellEditor",
        valueFormatter: dateValueFormatter(),
      },
      {
        field: "updated",
        headerName: "Mise à jour",
        filter: "agDateColumnFilter",
        valueFormatter: dateValueFormatter("DD/MM/YYYY HH:mm"),
      },
    ],
  };

  const onUpdateDialogClose = (success: boolean) => {
    setUpdateDialog(undefined);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  return (
    <>
      <ServerSideGrid
        baseUrl={baseUrl}
        menuDeleteAction={setDeleteDialog}
        menuEditAction={setUpdateDialog}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
      />

      {deleteDialog && (
        <ConfirmDialog
          onCancel={() => setDeleteDialog(undefined)}
          onValidate={() => deleteRows(deleteDialog, () => setDeleteDialog(undefined))}
        >
          Êtes-vous sûr de supprimer l'alignement ?
        </ConfirmDialog>
      )}

      {updateDialog && (
        <ActivityMatchingCreateDialog
          id={updateDialog[0].id}
          productCode={updateDialog[0].product_code}
          productLabel={updateDialog[0].product_label}
          productCodeMatch={updateDialog[0].product_code_match}
          customerSubtypeCode={updateDialog[0].customer_subtype_code}
          customerCode={updateDialog[0].customer_code}
          salesQuantity={updateDialog[0].sales_quantity}
          close={onUpdateDialogClose}
        />
      )}
    </>
  );
};

export default ActivityMatchingGrid;
