import { useState } from "react";
import { useForm } from "react-hook-form";

import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message, Hint } from "@zendeskgarden/react-forms";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";

import { Autocomplete, Form } from "../../../components/Form";
import LoadingModal from "../../../components/LoadingModal";
import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { BudgetForecast } from "../../../interfaces";

const BudgetForecastReforecastDialog = (props: { forecast: BudgetForecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const watchSubType = watch("subType");
  const subTypeOptions = [
    { value: "REVIEW", label: "Actualisation" },
    { value: "SUBSTITUTION", label: "Substitution" },
  ];

  const addReforecastBudgetForecast = (data: any) => {
    setLoading(true);
    const subType = data.subType === "Substitution" ? "SUBSTITUTION" : "REVIEW";
    api
      .post(`forecast`, {
        ...data,
        subType: subType,
        sourceBudget: { id: props.forecast.id },
      })
      .then((response) => {
        const forecast: BudgetForecast = response.data;
        addToast("Prévision révisée créée", `La prévision révisée #${forecast.id} a été créée`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la création de la prévision", "error", "top");
        setLoading(false);
      });
  };

  function defaultLabel() {
    if (!watchSubType) {
      return `Budget ${props.forecast.year} - Révision du ${moment().format("YYYY-MM-DD")}`;
    }
    if (watchSubType === "Actualisation") {
      return `Budget ${props.forecast.year} - Actualisation ${moment().week()}`;
    } else {
      return `Budget ${props.forecast.year} - Substitution du ${moment().format("YYYY-MM-DD")}`;
    }
  }

  return (
    <LoadingModal loading={loading} loadingMessage="Révision de budget en cours..." onClose={() => props.close(false)}>
      <ModalHeader>Réviser la prévision budgétaire #{props.forecast.id}</ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(addReforecastBudgetForecast)}>
          <Field>
            <Label>Type de révision</Label>
            <Autocomplete
              className="mt-2"
              control={control}
              name="subType"
              options={[""].concat(subTypeOptions.map((option) => `${option.label}`).sort()) || []}
              rules={{ required: true }}
              validation={errors.subType ? "error" : undefined}
              value={watchSubType}
            />
            {errors.subType && (
              <Message className="mt-2" validation="error">
                Vous devez choisir un type de révision
              </Message>
            )}
          </Field>
          {watchSubType === "Actualisation" && (
            <Field>
              <Label>Semaine d'actualisation</Label>
              <Hint>
                Indiquer la numéro de la semaine S de recalcul de la prévision, les données réelles seront prises en
                compte de la semaine 1 à S-1.
              </Hint>
              <Input
                type="number"
                {...register("reviewWeek", { value: moment().week(), required: true })}
                validation={errors.reviewWeek ? "error" : undefined}
              />
              {errors.reviewWeek && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner une semaine d'actualisation
                </Message>
              )}
            </Field>
          )}
          {watchSubType && (
            <Field>
              <Label>Libellé de la révision</Label>
              <Input
                type="text"
                {...register("label", {
                  value: defaultLabel(),
                  required: true,
                })}
                validation={errors.label ? "error" : undefined}
              />
              {errors.label && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner un libellé
                </Message>
              )}
            </Field>
          )}
        </Form>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary type="submit" form="dialog_form">
            Créer la révision
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default BudgetForecastReforecastDialog;
