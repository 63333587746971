import { Link, Route, useParams, Routes } from "react-router-dom";

import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Body as ChromeBody, HeaderItemWrapper, Content, Main } from "@zendeskgarden/react-chrome";
import { MD, Span } from "@zendeskgarden/react-typography";

import ActivityView from "../activity/ActivityView";
import ForecastInputView from "../common/ForecastInputView";
import ForecastReservationView from "./ForecastReservationView";

import ForecastOverwrite from "./components/ForecastOverwrite";
import ForecastParams from "./components/ForecastParams";

import Header from "../../components/Header";
import { RouteTabs, RouteTab, RouteTabPanel } from "../../components/RouteTabs";
import { CompactTabList } from "../../components/Styled";
import { useApiGet } from "../../hooks/useApi";
import ForecastResultView from "./ForecastResultView";

const ForecastView = () => {
  const { id } = useParams();

  const [{ data: forecast }, refetch] = useApiGet(`/forecast/${id}`, {});

  return (
    <ChromeBody>
      <Header helmetTitle={forecast && `Hebdo #${forecast.id}`}>
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/forecast">Prévisions hebdomadaire</Link>
            <Span>{forecast && `Hebdo #${forecast.id} - ${forecast.label}`}</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
      </Header>
      <Content>
        <Main>
          {forecast && forecast.type === "BUDGET" && (
            <>
              <MD style={{ color: "red", marginLeft: 18, marginTop: 20 }} isBold>
                La prévision #{forecast.id} n'est pas une prévision hebdomadaire !
              </MD>
              <MD style={{ color: "red", marginLeft: 18 }}>
                Retrouvez-la ici : <Link to={`/budget/${forecast.id}`}>{forecast.label}</Link>
              </MD>
            </>
          )}
          {forecast && forecast.type === "WEEKLY" && (
            <RouteTabs>
              <CompactTabList>
                <RouteTab to="">Vue générale</RouteTab>
                <RouteTab to="overwrite">Surcharge scénario</RouteTab>
                <RouteTab to="activity">Historique</RouteTab>
                <RouteTab to="reservation" disabled={!forecast.generated}>
                  Réservations
                </RouteTab>
                <RouteTab to="input" disabled={!forecast.generated}>
                  Apports
                </RouteTab>
                <RouteTab to="result" disabled={!forecast.generated || !forecast.forecasted}>
                  Prévision
                </RouteTab>
              </CompactTabList>
              <RouteTabPanel>
                <Routes>
                  <Route index element={<ForecastParams forecast={forecast} refetch={refetch} />} />
                  <Route path="overwrite/*" element={<ForecastOverwrite forecast={forecast} refetch={refetch} />} />
                  <Route path="activity/*" element={<ActivityView forecast={forecast} refetch={refetch} />} />
                  <Route
                    path="reservation/*"
                    element={<ForecastReservationView forecast={forecast} refetch={refetch} />}
                  />
                  <Route path="input/*" element={<ForecastInputView forecast={forecast} refetch={refetch} />} />
                  <Route path="result/*" element={<ForecastResultView forecast={forecast} refetch={refetch} />} />
                </Routes>
              </RouteTabPanel>
            </RouteTabs>
          )}
        </Main>
      </Content>
    </ChromeBody>
  );
};

export default ForecastView;
