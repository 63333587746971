import { Route, Routes, Navigate } from "react-router-dom";

import ForecastValidation from "./components/ForecastValidation";
import { RouteTabs, RouteTab, RouteTabPanel } from "../../components/RouteTabs";
import { CompactTabList } from "../../components/Styled";
import { ApiRefetch } from "../../hooks/useApi";
import { Forecast } from "../../interfaces";

const ForecastResultView = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast, refetch } = props;

  return (
    <RouteTabs>
      <CompactTabList>
        <RouteTab to="mc" disabled={!forecast.generated || !forecast.forecasted}>
          Familles mono-composants
        </RouteTab>
        <RouteTab to="mm" disabled={!forecast.generated || !forecast.forecasted}>
          Familles méli-mélos
        </RouteTab>
        <RouteTab to="mcmm" disabled={!forecast.generated || !forecast.forecasted || !forecast.forecastedStep2}>
          Familles mono-composants associées
        </RouteTab>
      </CompactTabList>
      <RouteTabPanel>
        <Routes>
          <Route index element={<Navigate to="mc" />} />
          <Route path="mc/*" element={<ForecastValidation forecast={forecast} familyType={"mc"} refetch={refetch} />} />
          <Route path="mm/*" element={<ForecastValidation forecast={forecast} familyType={"mm"} refetch={refetch} />} />
          <Route
            path="mcmm/*"
            element={<ForecastValidation forecast={forecast} familyType={"mcmm"} refetch={refetch} />}
          />
        </Routes>
      </RouteTabPanel>
    </RouteTabs>
  );
};

export default ForecastResultView;
