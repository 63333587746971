import { useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";

import { Datepicker, Form } from "../../../components/Form";
import Autocomplete from "../../../components/Form/Autocomplete";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { FamilyOption, WeeklyForecast } from "../../../interfaces";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Alert, Title } from "@zendeskgarden/react-notifications";

const InputTransferCreateDialog = (props: { close: Function; forecast?: WeeklyForecast }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: familyOptions }] = useApiGet("/datasource/family/values/family_code/family_label");

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchFromDate = watch("fromDate");
  const watchToDate = watch("toDate");

  const addInputTransfer = (data: any) => {
    setLoading(true);
    setApiError("");
    api
      .post(`forecast/${props.forecast?.id}/transfer`, {
        ...data,
        fromFamilyCode: data.fromFamilyCode
          ? familyOptions.find(
              (option: FamilyOption) => `${option.family_code} - ${option.family_label}` === data.fromFamilyCode
            ).family_code
          : null,
        toFamilyCode: data.toFamilyCode
          ? familyOptions.find(
              (option: FamilyOption) => `${option.family_code} - ${option.family_label}` === data.toFamilyCode
            ).family_code
          : null,
        toDate: data.toDate ? moment(data.toDate).format("YYYY-MM-DD") : null,
        fromDate: data.fromDate ? moment(data.fromDate).format("YYYY-MM-DD") : null,
      })
      .then(() => {
        addToast("Transfert créée", `Le tranfert a été créée`, "success", "top");
        props.close(true);
      })
      .catch((error) => {
        setApiError(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Modal isLarge onClose={() => props.close(false)}>
      <ModalHeader>Créer un transfert d'apport</ModalHeader>
      <form onSubmit={handleSubmit(addInputTransfer)}>
        <Body>
          <Form>
            <Row>
              <Col size={6}>
                <Field>
                  <Label>Filière d'origine</Label>
                  <Autocomplete
                    className="mt-2"
                    control={control}
                    name="fromFamilyCode"
                    rules={{ required: true }}
                    validation={errors.fromFamilyCode ? "error" : undefined}
                    options={
                      familyOptions
                        ?.map((option: FamilyOption) => `${option.family_code} - ${option.family_label}`)
                        .sort() || []
                    }
                  />
                  {errors.fromFamilyCode && (
                    <Message className="mt-2" validation="error">
                      Vous devez choisir une filière d'origine
                    </Message>
                  )}
                </Field>
              </Col>
              <Col size={6}>
                <Field>
                  <Label>Filière de destination</Label>
                  <Autocomplete
                    className="mt-2"
                    control={control}
                    name="toFamilyCode"
                    rules={{ required: true }}
                    validation={errors.toFamilyCode ? "error" : undefined}
                    options={
                      familyOptions
                        ?.map((option: FamilyOption) => `${option.family_code} - ${option.family_label}`)
                        .sort() || []
                    }
                  />
                  {errors.toFamilyCode && (
                    <Message className="mt-2" validation="error">
                      Vous devez choisir une filière de destination
                    </Message>
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col size={6}>
                <Field>
                  <Label>Date début</Label>
                  <Datepicker value={watchFromDate} onChange={(d) => setValue("fromDate", d)} maxValue={watchToDate}>
                    <Input
                      {...register("fromDate", { required: true })}
                      validation={errors.fromDate ? "error" : undefined}
                    />
                  </Datepicker>
                  {errors.fromDate && (
                    <Message className="mt-2" validation="error">
                      Vous devez renseigner une date de début
                    </Message>
                  )}
                </Field>
              </Col>
              <Col size={6}>
                <Field>
                  <Label>Date fin</Label>
                  <Datepicker value={watchToDate} onChange={(d) => setValue("toDate", d)} minValue={watchFromDate}>
                    <Input
                      {...register("toDate", { required: true })}
                      validation={errors.toDate ? "error" : undefined}
                    />
                  </Datepicker>
                  {errors.toDate?.type === "required" && (
                    <Message className="mt-2" validation="error">
                      Vous devez renseigner une date de fin
                    </Message>
                  )}
                  {errors.toDate?.type === "validate" && (
                    <Message className="mt-2" validation="error">
                      La date de fin doit être ultérieure à la date de début
                    </Message>
                  )}
                </Field>
              </Col>
            </Row>
            <Field>
              <Label>Quantité transférée (hebdomadaire, en kg)</Label>
              <Input
                type="number"
                {...register("quantityKg", {
                  required: true,
                })}
                validation={errors.quantityKg ? "error" : undefined}
              />
              {errors.quantityKg && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner une quantité transférée, exprimée en kg
                </Message>
              )}
            </Field>
          </Form>
          {apiError && (
            <Alert type="error" style={{ marginTop: 10 }}>
              <Title>{apiError}</Title>
              Veuillez corriger les données saisies.
            </Alert>
          )}
        </Body>
        <Footer>
          <FooterItem>
            <Button onClick={() => props.close(false)} isBasic>
              Annuler
            </Button>
          </FooterItem>
          <FooterItem>
            <Button isPrimary disabled={loading} type="submit">
              {loading ? "Création en cours..." : "Créer un transfert d'apport"}
            </Button>
          </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
      </form>
    </Modal>
  );
};

export default InputTransferCreateDialog;
