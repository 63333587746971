import { useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import FlexView from "react-flexview";

import {
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  MenuItemDef,
  RowDoubleClickedEvent,
  ValueFormatterParams,
} from "ag-grid-community";

import { FooterItem } from "@zendeskgarden/react-chrome";
import { Button } from "@zendeskgarden/react-buttons";
import { ReactComponent as HasInputIcon } from "@zendeskgarden/svg-icons/src/12/circle-full-fill.svg";
import { ReactComponent as HasNoInputIcon } from "@zendeskgarden/svg-icons/src/12/circle-full-stroke.svg";

import { ExportStatusTag } from "../../../components/ExportStatusTag";
import { ValidationStatusTag } from "../../../components/ForecastValidationStatusTag";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { CompactFooter } from "../../../components/Styled";
import ForecastFamilyValidationDialog from "../dialogs/ForecastFamilyValidationDialog";
import ForecastFamilyValidateDialog from "../dialogs/ForecastFamilyValidateDialog";
import { ApiRefetch, useApiInstance } from "../../../hooks/useApi";
import { Forecast } from "../../../interfaces";
import { dateValueFormatter, statusValueFormatter } from "../../../utils/helpers";
import useToast from "../../../hooks/useToast";

const ForecastValidation = (props: { forecast: Forecast; familyType: string; refetch: ApiRefetch<any> }) => {
  const { forecast, familyType, refetch } = props;
  const navigate = useNavigate();
  const gridApi = useRef<GridApi>();
  const { addToast } = useToast();
  const api = useApiInstance();

  const [validateDialog, setValidateDialog] = useState<string[] | undefined>(undefined);
  const [filterModel, setFilterModel] = useState<{ [key: string]: any } | null>(null);

  const baseUrl = `/validation/${forecast.id}/${familyType}`;

  const addAction = (api: GridApi) => {
    const focusedCell = api?.getFocusedCell(); // gets selected cell positon
    if (focusedCell) {
      const row = api?.getDisplayedRowAtIndex(focusedCell?.rowIndex); // gets selected row
      const column = api?.getColumnDef(focusedCell.column); // gets selected column
      if (row && column) {
        const familyCode = row.data.family_code;
        const columnField = column.field; // gets selected column
        if (columnField) {
          setValidateDialog([familyCode]);
        }
      }
    }
  };

  const getContextMenuItems = ({ api }: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    return [
      {
        name: "Valider la prévision pour cette filière",
        action: () => addAction(api!),
        disabled: !api!.getSelectedRows().length,
        cssClasses: ["text-blue-600", "font-bold"],
      },
      "separator",
      ...ServerSideGrid.defaultContextMenuItems,
    ];
  };

  const onGridFilterUpdate = (event: FilterChangedEvent) => {
    const gridFilterModel = event.api?.getFilterModel();
    if (gridFilterModel && Object.keys(gridFilterModel).length === 0) {
      setFilterModel(null);
    } else {
      setFilterModel(gridFilterModel);
    }
  };

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "family_code", headerName: "Code", filter: true, maxWidth: 120 },
      { field: "family_label", headerName: "Filière", filter: "agTextColumnFilter", minWidth: 250 },
      {
        field: "input_flag",
        headerName: "Apports",
        filter: true,
        maxWidth: 120,
        cellRendererFramework: (params: any) => {
          return params.value === "Avec apports" ? <HasInputIcon /> : <HasNoInputIcon />;
        },
      },
      {
        field: "status",
        headerName: "Statut",
        filter: true,
        maxWidth: 150,
        cellRendererFramework: (params: any) => {
          return <ValidationStatusTag status={params.getValue()} />;
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            return statusValueFormatter(params.value);
          },
        },
      },
      {
        field: "validated",
        headerName: "Date de validation",
        valueFormatter: dateValueFormatter("DD/MM/YYYY (hh:mm:ss)"),
        filter: false,
        maxWidth: 200,
      },
      {
        field: "export_status",
        headerName: "Export",
        filter: true,
        maxWidth: 100,
        cellRendererFramework: (params: any) => {
          return <ExportStatusTag status={params.getValue()} />;
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            return statusValueFormatter(params.value);
          },
        },
      },
      {
        field: "exported",
        headerName: "Date d'export",
        valueFormatter: dateValueFormatter("DD/MM/YYYY (hh:mm:ss)"),
        filter: false,
      },
      {
        field: "updated",
        headerName: "Date de mise à jour",
        valueFormatter: dateValueFormatter("DD/MM/YYYY (hh:mm:ss)"),
        filter: false,
      },
    ],
    getContextMenuItems,
  };

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    navigate(`/forecast/${forecast.id}/result/${familyType}/${event.data.family_code}`);
  };

  const onDialogClose = (success: boolean) => {
    navigate(`/forecast/${forecast.id}/result/${familyType}`);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  function onValidationButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (filterModel === null) {
      addToast("Erreur", `Un filtre doit être défini pour effectuer cette validation.`, "error", "top");
    } else {
      let families: string[];
      api
        .post(`${baseUrl}/values`, {
          field: `family_code`,
          filterModel: filterModel,
        })
        .then((req) => {
          families = req.data;
          // apply family_code filter (as long it is excluded by GetValuesRequestHandler, line 39)
          const familyCodeFilter = filterModel?.family_code;
          if (families && familyCodeFilter && familyCodeFilter.filterType === "set" && familyCodeFilter.values) {
            families = families.filter((element) => familyCodeFilter.values.includes(element));
          }
          setValidateDialog(families);
        })
        .catch(() => {
          addToast("Erreur", `Erreur à la récupération de la liste des valeurs.`, "error", "top");
        });
    }
  }

  const onValidateClose = (success: boolean) => {
    setValidateDialog(undefined);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
      refetch();
    }
  };

  return (
    <>
      <FlexView column height="100%">
        <ServerSideGrid
          baseUrl={baseUrl}
          gridOptions={gridOptions}
          gridApiRef={gridApi}
          onRowDoubleClicked={onRowDoubleClicked}
          onFilterChanged={(event: FilterChangedEvent) => onGridFilterUpdate(event)}
        />
        <CompactFooter>
          <FooterItem>
            <Button
              size="small"
              isPrimary
              disabled={!["DRAFT", "PARTIAL"].includes(forecast.status) || !forecast.generated || !forecast.forecasted}
              onClick={(event) => onValidationButtonClick(event)}
            >
              Valider les filières affichées
            </Button>
          </FooterItem>
        </CompactFooter>
      </FlexView>
      <Routes>
        <Route path=":code" element={<ForecastFamilyValidationDialog forecast={forecast} close={onDialogClose} />} />
      </Routes>
      {validateDialog && (
        <ForecastFamilyValidateDialog forecast={forecast} familyCodes={validateDialog} close={onValidateClose} />
      )}
    </>
  );
};

export default ForecastValidation;
