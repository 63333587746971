import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph } from "@zendeskgarden/react-typography";

import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";

const ForecastForecastDialog = (props: { forecast: Forecast; step: number; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();
  const { forecast, step } = props;

  const handleForecast = () => {
    setLoading(true);
    api
      .post(`weekly/forecast/${forecast.id}/generate/${step}`)
      .then((response) => {
        let message = `La prévision #${forecast.id} a été générée.`;
        if (step === 2) {
          message = `La prévision #${forecast.id} a été complétée des familles associées aux composants de méli-mélos.`;
        }
        addToast("Prévision générée", message, "success", "top");
        const anomalyCount = response.data.length;
        if (anomalyCount > 0) {
          const anomalyMessage =
            anomalyCount > 1
              ? `${anomalyCount} anomalies sont apparues et sont consultables sur le tableau de bord qualité.`
              : `${anomalyCount} anomalie est apparue et est consultable sur le tableau de bord qualité.`;
          addToast("Anomalie(s) créée(s)", anomalyMessage, "warning", "top");
        }
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", `Échec à la génération de la prévision #${forecast.id}`, "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Génération en cours..." onClose={() => props.close(false)}>
      <ModalHeader>
        Générer la prévision "{props.forecast.label}" (#{props.forecast.id})
      </ModalHeader>
      <Body>
        <Paragraph>
          {step === 1
            ? `Confirmez-vous la génération de la prévision ?`
            : `Confirmez-vous la génération de la prévision des familles associées aux composants de méli-mélos ?`}
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => handleForecast()}>
            Générer la prévision
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};
export default ForecastForecastDialog;
