import { useState } from "react";

import { GridApi, GridOptions } from "ag-grid-community";

import ProductLaunchDialog from "../dialogs/ProductLaunchDialog";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useServerSideGridDeleteRows } from "../../../hooks/aggrid/useServerSideGridDeleteRows";
import { dateValueFormatter, numberValueFormatter } from "../../../utils/helpers";

const BLUE = "#1f73b7";

const ProductLaunchGrid = (props: { gridApi: React.MutableRefObject<GridApi | undefined> }) => {
  const { gridApi } = props;
  const [deleteDialog, setDeleteDialog] = useState<any[] | undefined>(undefined);
  const [editDialog, setEditDialog] = useState<any[] | undefined>(undefined);

  const baseUrl = "/launch";
  const deleteRows = useServerSideGridDeleteRows(baseUrl, gridApi.current);

  const onEditDialogClose = (success: boolean) => {
    setEditDialog(undefined);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "id", hide: true, filter: false },
      { field: "product_code", headerName: "Produit" },
      { field: "product_label", headerName: "Libellé", filter: false },
      { field: "product_launch_type", headerName: "Type de lancement" },
      {
        field: "total_quantity",
        headerName: "Quantité",
        cellStyle: { textAlign: "right", color: BLUE },
        filter: "agNumberColumnFilter",
        editable: true,
        cellEditor: "numberCellEditor",
        valueFormatter: numberValueFormatter(),
      },
      { field: "customer_type_label", headerName: "Type client", filter: "agTextColumnFilter" },
      { field: "customer_subtype_label", headerName: "Enseigne", filter: "agTextColumnFilter" },
      { field: "customer_code", headerName: "Client" },
      { field: "customer_name", headerName: "Nom", filter: "agTextColumnFilter" },
      { field: "shipping_warehouse_code", headerName: "Entrepôt" },
      {
        field: "start_date",
        headerName: "Date début",
        cellStyle: { color: BLUE },
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "dateCellEditor",
        valueFormatter: dateValueFormatter(),
      },
      {
        field: "end_date",
        headerName: "Date fin",
        cellStyle: { color: BLUE },
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "dateCellEditor",
        valueFormatter: dateValueFormatter(),
      },
      {
        field: "updated",
        headerName: "Mis à jour",
        filter: "agDateColumnFilter",
        valueFormatter: dateValueFormatter("DD/MM/YYYY HH:mm"),
      },
    ],
  };

  return (
    <>
      <ServerSideGrid
        baseUrl={baseUrl}
        menuDeleteAction={setDeleteDialog}
        menuEditAction={setEditDialog}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
      />
      {deleteDialog && (
        <ConfirmDialog
          onCancel={() => setDeleteDialog(undefined)}
          onValidate={() => deleteRows(deleteDialog, () => setDeleteDialog(undefined))}
        >
          Êtes-vous sûr de supprimer le lancement ?
        </ConfirmDialog>
      )}
      {editDialog && <ProductLaunchDialog selectedProductLaunch={editDialog[0]} close={onEditDialogClose} />}
    </>
  );
};

export default ProductLaunchGrid;
