import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Chrome } from "@zendeskgarden/react-chrome";
import { ToastProvider } from "@zendeskgarden/react-notifications";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import Navbar from "./components/Navbar";
import ServerJobsToast from "./components/ServerJobsToast";
import theme from "./config/theme";
import KeycloakProvider from "./contexts/KeycloakProvider";
import StoreProvider from "./contexts/StoreProvider";
import { initializePopper } from "./utils/popper";

// AGGrid
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// pages
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import ForecastList from "./pages/forecast/ForecastList";
import ForecastView from "./pages/forecast/ForecastView";
import DatasourceList from "./pages/datasource/DatasourceList";
import DatasourceView from "./pages/datasource/DatasourceView";
import BudgetForecastList from "./pages/budget/BudgetForecastList";
import BudgetForecastView from "./pages/budget/BudgetForecastView";
import Settings from "./pages/settings/Settings";
import ActivityExclusion from "./pages/settings/ActivityExclusion";
import ActivityMatching from "./pages/settings/ActivityMatching";
import ActivityOverwrite from "./pages/settings/ActivityOverwrite";
import ClosedDay from "./pages/settings/ClosedDay";
import ClosedWarehouse from "./pages/settings/ClosedWarehouse";
import Lifecycle from "./pages/settings/Lifecycle";
import ProductLaunch from "./pages/settings/ProductLaunch";

import { LicenseManager } from "ag-grid-enterprise";
// styles
import "@zendeskgarden/css-bedrock";

import "./styles.css";
// moment locale
import Moment from "react-moment";
import "moment/locale/fr";
Moment.globalLocale = "fr";

// aggrid license locale
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY!);

// initialize popper
initializePopper();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <KeycloakProvider>
        <StoreProvider>
          <BrowserRouter>
            <Chrome isFluid>
              <Navbar />
              <ToastProvider zIndex={1}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  {/* Datasources */}
                  <Route path="datasource" element={<DatasourceList />} />
                  <Route path="datasource/:code" element={<DatasourceView />} />
                  {/* Settings */}
                  <Route path="settings" element={<Settings />} />
                  <Route path="settings/closed/day" element={<ClosedDay />} />
                  <Route path="settings/closed/warehouse" element={<ClosedWarehouse />} />
                  <Route path="settings/lifecycle" element={<Lifecycle />} />
                  <Route path="settings/activity/matching" element={<ActivityMatching />} />
                  <Route path="settings/activity/exclusion" element={<ActivityExclusion />} />
                  <Route path="settings/activity/overwrite" element={<ActivityOverwrite />} />
                  <Route path="settings/launch" element={<ProductLaunch />} />
                  {/* BudgetForecast */}
                  <Route path="budget" element={<BudgetForecastList />} />
                  <Route path="budget/:id/*" element={<BudgetForecastView />} />
                  {/* HebdoForecast */}
                  <Route path="forecast" element={<ForecastList />} />
                  <Route path="forecast/:id/*" element={<ForecastView />} />
                  {/* Others */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <ServerJobsToast />
              </ToastProvider>
            </Chrome>
          </BrowserRouter>
        </StoreProvider>
      </KeycloakProvider>
    </ThemeProvider>
  );
}

export default App;
