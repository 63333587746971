import { useState, useEffect } from "react";
import { Multiselect as ZenMultiselect, Field, Dropdown, Item, Menu } from "@zendeskgarden/react-dropdowns";
import { ReactComponent as SearchIcon } from "@zendeskgarden/svg-icons/src/16/search-stroke.svg";

import { Controller, Control, RegisterOptions, FieldValues, FieldPath } from "react-hook-form";
import { VALIDATION } from "@zendeskgarden/react-dropdowns/dist/typings/utils/validation";
import { Tag } from "@zendeskgarden/react-tags";

export default function Multiselect({
  control,
  options,
  name,
  rules,
  validation,
  className,
  value,
  disabled,
}: {
  control: Control;
  options: string[];
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  validation?: VALIDATION;
  className?: string;
  value?: string[];
  disabled?: boolean;
}) {
  value = value !== undefined ? value : [];
  const [selectedItems, setSelectedItems] = useState(value as any[]);
  const [inputValue, setInputValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState([] as string[]);

  useEffect(() => {
    if (inputValue) {
      setMatchingOptions(
        options.filter((option) => option.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1)
      );
    } else {
      setMatchingOptions(options);
    }
  }, [options, inputValue]);

  const onSelect = (items: any[], field: any) => {
    setSelectedItems(items);
    field.onChange(items);
  };

  return (
    <Controller
      render={({ field }) => (
        <Dropdown
          {...field}
          inputValue={inputValue}
          selectedItems={selectedItems}
          onSelect={(items) => onSelect(items, field)}
          onInputValueChange={(value) => setInputValue(value)}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
          <Field>
            <ZenMultiselect
              className={className}
              start={<SearchIcon />}
              validation={validation}
              disabled={disabled}
              renderItem={({ value, removeValue }: any) => (
                <Tag>
                  <span>{value}</span>
                  <Tag.Close onClick={() => removeValue()} />
                </Tag>
              )}
            >
              {" "}
              {selectedItems}{" "}
            </ZenMultiselect>
          </Field>
          <Menu>
            {matchingOptions.length ? (
              matchingOptions.map((option) => (
                <Item key={option} value={option}>
                  {" "}
                  {/* pour une better UI fournir une liste d'option avec label et value à utilisé ici */}
                  <span>{option}</span>
                </Item>
              ))
            ) : (
              <Item disabled>No matches found</Item>
            )}
          </Menu>
        </Dropdown>
      )}
      name={name}
      control={control}
      rules={rules}
      defaultValue={value}
    />
  );
}
