import { Route, Routes } from "react-router-dom";

import { Alert } from "@zendeskgarden/react-notifications";

import BudgetForecastResultGrid from "../grids/BudgetForecastResultGrid";
import BudgetForecastInputResultGrid from "../grids/BudgetForecastInputResultGrid";
import BudgetForecastReviewResultGrid from "../grids/BudgetForecastReviewResultGrid";

import { RouteTab, RouteTabPanel, RouteTabs } from "../../../components/RouteTabs";
import { CompactTabList } from "../../../components/Styled";
import { BudgetForecast } from "../../../interfaces";
import { ApiRefetch, useApiInstance } from "../../../hooks/useApi";

const BudgetForecastResultView = (props: { forecast: BudgetForecast; refetch: ApiRefetch<any> }) => {
  const { forecast, refetch } = props;
  const api = useApiInstance();

  const isReview = forecast.subType === "REVIEW";
  const generated = isReview ? true : forecast.generated !== null;
  const forecastId = isReview ? forecast.referentBudget?.id : forecast.id;
  const baseUrl = `/budget/forecast/${forecastId}`;

  if (!generated) {
    return (
      <Alert className="m-5" type="warning">
        La prévision n'a pas encore été générée...
      </Alert>
    );
  }

  return (
    <RouteTabs>
      <CompactTabList>
        <RouteTab to="">Prévision</RouteTab>
        <RouteTab to="input">Consommations et surcharges</RouteTab>
        {/*{isReview && <RouteTab to="reforecast">Actualisation</RouteTab>}*/}
      </CompactTabList>
      <RouteTabPanel>
        <Routes>
          <Route index element={<BudgetForecastResultGrid forecast={forecast} />} />
          <Route
            path="input/*"
            element={
              <BudgetForecastInputResultGrid forecast={forecast} onQtyUpdate={() => console.log("quantity update")} />
            }
          />
          <Route
            path="reforecast/*"
            element={
              <BudgetForecastReviewResultGrid forecast={forecast} onQtyUpdate={() => console.log("quantity update")} />
            }
          />
        </Routes>
      </RouteTabPanel>
    </RouteTabs>
  );
};

export default BudgetForecastResultView;
