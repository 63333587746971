import { useState } from "react";

import { GridApi, GridOptions, ValueGetterParams } from "ag-grid-community";

import BudgetMelimeloOverwriteDialog from "../../budget/dialogs/BudgetMelimeloOverwriteDialog";
import MelimeloOverwriteDialog from "../../forecast/dialogs/MelimeloOverwriteDialog";
import frameworkComponents from "../../../components/AgGrid";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import ConfirmDialog from "../../../components/ConfirmDialog";
import aggridLocale from "../../../config/aggrid-locale.fr";
import { useServerSideGridDeleteRows } from "../../../hooks/aggrid/useServerSideGridDeleteRows";
import { useApiInstance } from "../../../hooks/useApi";
import { Forecast, GetDetailRowDataParams } from "../../../interfaces";

const ForecastMelimeloGrid = ({
  forecast,
  gridApi,
}: {
  forecast: Forecast;
  gridApi: React.MutableRefObject<GridApi | undefined>;
}) => {
  const baseUrl = `/forecast/${forecast.id}/nomenclature`;
  const [updateDialog, setUpdateDialog] = useState<any[] | undefined>(undefined);
  const [deleteDialog, setDeleteDialog] = useState<any[] | undefined>(undefined);
  const deleteRows = useServerSideGridDeleteRows(baseUrl, gridApi.current);
  const api = useApiInstance();

  const onUpdateDialogClose = (success: boolean) => {
    setUpdateDialog(undefined);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  const detailCellRendererParams = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
      frameworkComponents,
      localeText: aggridLocale,
      defaultColDef: {
        flex: 1,
        sortable: true,
        resizable: true,
      },
      columnDefs: [
        { field: "component_family_label", headerName: "Composant" },
        { field: "component_family_code", headerName: "Code" },
        { field: "quantity_percent", headerName: "Proportion (%)" },
        {
          headerName: "Profil",
          colId: "proportion",
          valueGetter: (params: ValueGetterParams) => params.data.quantity_percent / 100,
          cellRenderer: "progressCellRenderer",
        },
      ],
    },
    getDetailRowData: (params: GetDetailRowDataParams) => {
      api.get(`/forecast/${forecast.id}/nomenclature/value/${params.data.product_code}`).then((res) => {
        params.successCallback(res.data);
      });
    },
  };
  const gridOptions: GridOptions = {
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams,
    columnDefs: [
      { field: "product_code", headerName: "Produit", cellRenderer: "agGroupCellRenderer", filter: true },
      { field: "product_label", headerName: "Libellé produit", filter: "agTextColumnFilter" },
      { field: "product_line_code", headerName: "Ligne", filter: true },
      { field: "product_line_label", headerName: "Libellé ligne", filter: "agTextColumnFilter" },
      { field: "weight_kg", headerName: "Poids (kg)", filter: "agNumberColumnFilter" },
      { field: "palletizing", headerName: "Palettisation", filter: "agNumberColumnFilter" },
      { field: "pcb", headerName: "PCB", filter: "agNumberColumnFilter" },
    ],
  };

  return (
    <>
      <ServerSideGrid
        baseUrl={baseUrl}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
        menuEditAction={setUpdateDialog}
        menuDeleteAction={setDeleteDialog}
      />
      ;
      {updateDialog && forecast.type === "BUDGET" && (
        <BudgetMelimeloOverwriteDialog
          forecast={forecast}
          close={onUpdateDialogClose}
          id={updateDialog[0].product_code}
          productLabel={updateDialog[0].product_label}
        />
      )}
      {updateDialog && forecast.type === "WEEKLY" && (
        <MelimeloOverwriteDialog
          forecast={forecast}
          close={onUpdateDialogClose}
          id={updateDialog[0].product_code}
          productLabel={updateDialog[0].product_label}
        />
      )}
      {deleteDialog && (
        <ConfirmDialog
          onCancel={() => setDeleteDialog(undefined)}
          onValidate={() => deleteRows(deleteDialog, () => setDeleteDialog(undefined))}
        >
          Êtes-vous sûr de supprimer la surcharge méli-mélo ?
        </ConfirmDialog>
      )}
    </>
  );
};

export default ForecastMelimeloGrid;
