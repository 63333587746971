import { Route, Routes } from "react-router-dom";

import ForecastProductLaunchReservation from "./components/ForecastProductLaunchReservation";
import ForecastMelimeloReservation from "./components/ForecastMelimeloReservation";
import ForecastPromotionReservation from "./components/ForecastPromotionReservation";

import { RouteTabs, RouteTab, RouteTabPanel } from "../../components/RouteTabs";
import { CompactTabList } from "../../components/Styled";
import { ApiRefetch } from "../../hooks/useApi";
import { Forecast } from "../../interfaces";

const ForecastReservationView = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;

  return (
    <RouteTabs>
      <CompactTabList>
        <RouteTab to="" disabled={!forecast.generated}>
          Réservations méli-mélos
        </RouteTab>
        <RouteTab to="promotion" disabled={!forecast.generated}>
          Réservations promotions
        </RouteTab>
        <RouteTab to="launch" disabled={!forecast.generated}>
          Réservations lancements
        </RouteTab>
      </CompactTabList>
      <RouteTabPanel>
        <Routes>
          <Route index element={<ForecastMelimeloReservation {...props} />} />
          <Route path="launch" element={<ForecastProductLaunchReservation {...props} />} />
          <Route path="promotion" element={<ForecastPromotionReservation {...props} />} />
        </Routes>
      </RouteTabPanel>
    </RouteTabs>
  );
};

export default ForecastReservationView;
