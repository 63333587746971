import { PropsWithChildren } from "react";
import { ITabPanelProps, ITabProps, ITabsProps, Tab } from "@zendeskgarden/react-tabs";
import { To, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { FlexTabs, FlexTabPanel } from "./Styled";

export const RouteTabs = (props: PropsWithChildren<ITabsProps>) => {
  const { children } = props;

  return (
    <FlexTabs selectedItem="current" {...props}>
      {children}
    </FlexTabs>
  );
};

export const RouteTab = (props: PropsWithChildren<{ to: To } & ITabProps>) => {
  const { children, to, disabled } = props;
  const navigate = useNavigate();
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: to === "" });

  return (
    <Tab item={match ? "current" : to} onClick={() => !disabled && navigate(to)} {...props}>
      {children}
    </Tab>
  );
};

export const RouteTabPanel = (props: PropsWithChildren<ITabPanelProps>) => {
  const { children } = props;

  return (
    <FlexTabPanel item="current" {...props}>
      {children}
    </FlexTabPanel>
  );
};
