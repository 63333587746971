import { useState, useRef } from "react";

import {
  CellValueChangedEvent,
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  MenuItemDef,
} from "ag-grid-community";

import ActivityOverwriteCreateDialog from "../dialogs/ActivityOverwriteCreateDialog";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { numberValueFormatter, toCamelCaseObject } from "../../../utils/helpers";

const ActivityOverwriteGrid = (props: { year: Number; display: "sales" | "price" }) => {
  const api = useApiInstance();
  const { addToast } = useToast();
  const [overwriteDialog, setOverwriteDialog] = useState<
    { week: number; productCode: string; value: number } | undefined
  >(undefined);
  const gridApi = useRef<GridApi>();
  const { year, display } = props;

  const baseUrl = `/activity/overwrite/table/${year}/${display}`;

  const onCreateDialogClose = (success: boolean) => {
    setOverwriteDialog(undefined);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  const addAction = (api: GridApi) => {
    const focusedCell = api?.getFocusedCell(); // gets selected cell positon
    if (focusedCell) {
      const row = api?.getDisplayedRowAtIndex(focusedCell?.rowIndex); // gets selected row
      const column = api?.getColumnDef(focusedCell.column); // gets selected column
      if (row && column) {
        const productCode = row.data.product_code; // gets product code selected
        const columnField = column.field; // gets selected column
        const value = api?.getValue(focusedCell?.column, row);
        if (columnField) {
          setOverwriteDialog({
            week: +columnField.split("_")[1],
            productCode: productCode,
            value: value,
          });
        }
      }
    }
  };

  const getContextMenuItems = ({ api }: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    return [
      {
        name: "Surcharger",
        action: () => addAction(api!),
        disabled:
          api!.getValue(
            api!.getFocusedCell()!.column,
            api!.getDisplayedRowAtIndex(api!.getFocusedCell()!.rowIndex)!
          ) === null,
      },
      "separator",
      ...ServerSideGrid.defaultContextMenuItems,
    ];
  };

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    if (params.oldValue !== params.newValue) {
      api
        .put(`activity/overwrite/${params.data.id}`, toCamelCaseObject(params.data))
        .then((req) => addToast("Mise à jour", `Mise à jour effectuée pour ${params.data.product_code}.`, "success"))
        .catch(() => {
          addToast("Erreur", `La valeur ${params.newValue} est erronée. Veuillez corriger.`, "error");
        });
    }
  };

  const createWeekColumns = (weeks = 53) => {
    const array: ColDef[] = [];
    const valueFormatterOpts =
      display === "sales"
        ? { minimumFractionDigits: 0, maximumFractionDigits: 0, suffix: "kg" }
        : { minimumFractionDigits: 2, maximumFractionDigits: 2, suffix: "€" };
    for (let index = 1; index <= weeks; index++) {
      array.push({
        field: `week_${index}`,
        headerName: `S. ${index.toString().padStart(2, "0")}`,
        filter: false,
        width: 80,
        type: "numericColumn",
        valueFormatter: numberValueFormatter(valueFormatterOpts),
      });
    }
    return array;
  };

  const columnDefs = [
    {
      field: "product_code",
      headerName: "Produit",
      width: 120,
      pinned: "left",
    },
    {
      field: "product_label",
      headerName: "Libellé",
      filter: "agTextColumnFilter",
      width: 200,
      pinned: "left",
    },
    {
      field: "family_label",
      headerName: "Filière",
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      field: "product_line_label",
      headerName: "Format",
      filter: "agTextColumnFilter",
      hide: true,
    },
    ...createWeekColumns(),
  ];

  const gridOptions: GridOptions = {
    columnHoverHighlight: true,
    defaultColDef: {
      flex: 0,
    },
    onCellValueChanged,
    enableRangeSelection: true,
    allowContextMenuWithControlKey: true,
    getContextMenuItems,
  };

  return (
    <>
      <ServerSideGrid
        baseUrl={baseUrl}
        gridOptions={gridOptions}
        columnDefs={columnDefs}
        onGridReady={({ api }) => {
          gridApi.current = api;
        }}
      />
      {overwriteDialog && (
        <ActivityOverwriteCreateDialog
          display={display}
          close={onCreateDialogClose}
          year={year}
          week={overwriteDialog.week}
          productCode={overwriteDialog.productCode}
          value={overwriteDialog.value}
        />
      )}
    </>
  );
};

export default ActivityOverwriteGrid;
