import { useState } from "react";
import moment from "moment";

import { GridApi, GridOptions } from "ag-grid-community";

import { Span } from "@zendeskgarden/react-typography";

import ConfirmDialog from "../../../components/ConfirmDialog";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { useServerSideGridDeleteRows } from "../../../hooks/aggrid/useServerSideGridDeleteRows";
import { dateValueFormatter } from "../../../utils/helpers";

const ClosedWarehouseGrid = (props: { gridApi: React.MutableRefObject<GridApi | undefined> }) => {
  const { gridApi } = props;
  const [deleteDialog, setDeleteDialog] = useState<any[] | undefined>(undefined);

  const baseUrl = "/closed/warehouse";
  const deleteRows = useServerSideGridDeleteRows(baseUrl, gridApi.current);

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "closed_warehouse_code", headerName: "Entrepôt fermé", filter: true },
      { field: "substitution_warehouse_code", headerName: "Entrepôt de substitution", filter: true },
      {
        field: "product_code",
        headerName: "Produit",
        filter: true,
      },
      { field: "year", headerName: "Année", filter: "agNumberColumnFilter", maxWidth: 100 },
      { field: "from_date", headerName: "Du", filter: "agDateColumnFilter", valueFormatter: dateValueFormatter() },
      { field: "to_date", headerName: "Au", filter: "agDateColumnFilter", valueFormatter: dateValueFormatter() },
      {
        field: "updated",
        headerName: "Mise à jour",
        filter: "agDateColumnFilter",
        valueFormatter: dateValueFormatter("DD/MM/YYYY HH:mm"),
      },
    ],
  };

  return (
    <>
      <ServerSideGrid
        baseUrl={baseUrl}
        menuDeleteAction={setDeleteDialog}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
      />
      {deleteDialog && (
        <ConfirmDialog
          title="Suppression de fermeture de dépôt"
          onCancel={() => setDeleteDialog(undefined)}
          onValidate={() => deleteRows(deleteDialog, () => setDeleteDialog(undefined))}
        >
          Etes-vous sûr de supprimer la fermeture du dépôt
          <Span isBold> {deleteDialog[0].closed_warehouse_code} </Span>
          (du {moment(deleteDialog[0].from_date).format("DD/MM/YYYY")} au{" "}
          {moment(deleteDialog[0].to_date).format("DD/MM/YYYY")}) ?
        </ConfirmDialog>
      )}
    </>
  );
};

export default ClosedWarehouseGrid;
