import { useState } from "react";
import { GridApi, GridOptions } from "ag-grid-community";

import { dateValueFormatter } from "../../../utils/helpers";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useServerSideGridDeleteRows } from "../../../hooks/aggrid/useServerSideGridDeleteRows";

const ActivityExclusionGrid = (props: { gridApi: React.MutableRefObject<GridApi | undefined> }) => {
  const { gridApi } = props;
  const [deleteDialog, setDeleteDialog] = useState<any[] | undefined>(undefined);

  const baseUrl = "/activity/exclusion";
  const deleteRows = useServerSideGridDeleteRows(baseUrl, gridApi.current);

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "id", hide: true, filter: false },
      { field: "product_code", headerName: "Produit" },
      { field: "product_label", headerName: "Libellé", filter: "agTextColumnFilter" },
      { field: "product_family_label", headerName: "Filière", filter: "agTextColumnFilter" },
      { field: "product_line_label", headerName: "Conditionnement", filter: "agTextColumnFilter" },
      { field: "customer_subtype_code", headerName: "Code Enseigne", hide: true },
      { field: "customer_subtype_label", headerName: "Enseigne" },
      { field: "customer_code", headerName: "Client" },
      { field: "comment", headerName: "Commentaire", filter: "agTextColumnFilter", editable: true },
      {
        field: "exclusion_start",
        headerName: "Date début",
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "dateCellEditor",
        valueFormatter: dateValueFormatter(),
      },
      {
        field: "exclusion_end",
        headerName: "Date fin",
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "dateCellEditor",
        valueFormatter: dateValueFormatter(),
      },
      {
        field: "updated",
        headerName: "Mise à jour",
        filter: "agDateColumnFilter",
        valueFormatter: dateValueFormatter("DD/MM/YYYY HH:mm"),
      },
    ],
  };

  return (
    <>
      <ServerSideGrid
        baseUrl={baseUrl}
        menuDeleteAction={setDeleteDialog}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
      />
      {deleteDialog && (
        <ConfirmDialog
          onCancel={() => setDeleteDialog(undefined)}
          onValidate={() => deleteRows(deleteDialog, () => setDeleteDialog(undefined))}
        >
          Êtes-vous sûr de supprimer l'exclusion ?
        </ConfirmDialog>
      )}
    </>
  );
};

export default ActivityExclusionGrid;
