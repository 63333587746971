import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Alert, Title } from "@zendeskgarden/react-notifications";

import { Autocomplete, Form } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Combination, WeeklyForecast } from "../../../interfaces";

const CombinationExceptionDialog = (props: {
  close: Function;
  forecast: WeeklyForecast;
  exceptionType: string;
  familyCode: string;
  gridCombinations: Combination[] | [];
  productCode?: string;
  shippingWharehouseCode?: string;
  customerCode?: string;
  productCodeMatch?: string[];
  salesQuantity?: number;
}) => {
  const { forecast, exceptionType, familyCode, gridCombinations } = props;

  const [loading, setLoading] = useState(false);
  const [combinationError, setCombinationError] = useState("");

  const { addToast } = useToast();
  const api = useApiInstance();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [{ data: productOptions }] = useApiGet(`/weekly/forecast/${forecast.id}/${familyCode}/product`);
  const [{ data: warehouseOptions }] = useApiGet("/datasource/activity/values/shipping_warehouse_code");
  const [{ data: customerOptions }] = useApiGet(
    "/datasource/customer/values/delivered_customer_code/delivered_customer_name"
  );

  const getOptions = (options: { [key: string]: string }[], code: string, label: string) => {
    return (
      [""].concat(options?.map((option: { [key: string]: string }) => `${option[code]} - ${option[label]}`).sort()) ||
      []
    );
  };

  const getCodeFromCodeLabel = (
    codeLabel: string,
    options: { [key: string]: string }[],
    code: string,
    label: string
  ) => {
    return codeLabel
      ? options.find((option: { [key: string]: string }) => `${option[code]} - ${option[label]}` === codeLabel)![code]
      : null;
  };

  const getPayload = (data: { [key: string]: string }) => {
    return {
      productCode: getCodeFromCodeLabel(data.productCodeLabel, productOptions, "product_code", "product_label"),
      shippingWarehouseCode: data.shippingWarehouseCode,
      customerCode: getCodeFromCodeLabel(
        data.customerCodeName,
        customerOptions,
        "delivered_customer_code",
        "delivered_customer_name"
      ),
      productCodeMatch: getCodeFromCodeLabel(
        data.productCodeLabelMatch,
        productOptions,
        "product_code",
        "product_label"
      ),
      salesQuantity: data.salesQuantity ? data.salesQuantity : null,
    };
  };

  const addCombination = (data: any) => {
    setCombinationError("");
    setLoading(true);
    const payload = getPayload(data);
    // check combination exists (if closing) or not exists (if opening)
    const targetCombination: Combination = {
      product_code: payload.productCode ?? "",
      shipping_warehouse_code: payload.shippingWarehouseCode ?? "",
      delivered_customer_code: payload.customerCode ?? "",
    };
    const found = gridCombinations.find(
      (combination) =>
        combination.product_code === targetCombination.product_code &&
        combination.shipping_warehouse_code === targetCombination.shipping_warehouse_code &&
        combination.delivered_customer_code === targetCombination.delivered_customer_code
    );
    if (exceptionType === "opening" && found) {
      setCombinationError("La combinaison est déjà ouverte");
      setLoading(false);
      return;
    }
    if (exceptionType === "closing" && !found) {
      setCombinationError("La combinaison n'est pas ouverte, impossible de la fermer");
      setLoading(false);
      return;
    }
    // check if product match combination exists
    if (payload.productCodeMatch) {
      const matchCombination: Combination = {
        product_code: payload.productCodeMatch ?? "",
        shipping_warehouse_code: payload.shippingWarehouseCode ?? "",
        delivered_customer_code: payload.customerCode ?? "",
      };
      const foundMatch = gridCombinations.find(
        (combination) =>
          combination.product_code === matchCombination.product_code &&
          combination.shipping_warehouse_code === matchCombination.shipping_warehouse_code &&
          combination.delivered_customer_code === matchCombination.delivered_customer_code
      );
      if (exceptionType === "opening" && !foundMatch) {
        setCombinationError("La combinaison du produit d'alignement n'est pas utilisable");
        setLoading(false);
        return;
      }
    }
    api
      .post(`/weekly/forecast/${forecast.id}/${familyCode}/combination/${exceptionType}`, payload)
      .then((response) => {
        const name = `Création ${exceptionType === "opening" ? "d'ouverture" : "de fermeture"}`;
        const message = `${exceptionType === "opening" ? "L'ouverture" : "La fermeture"} du produit #${
          response.data.product_code
        } a été créée`;
        addToast(name, message, "success", "top");
        props.close(true);
      })
      .catch((error) => {
        setCombinationError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
        setLoading(false);
      });
  };

  const updateCombination = (data: any) => {
    setCombinationError("");
    setLoading(true);
    api
      .put(`/weekly/forecast/${forecast.id}/${familyCode}/combination/${exceptionType}`, getPayload(data))
      .then(() => {
        addToast(
          `${exceptionType === "opening" ? "Ouverture" : "Fermeture"} mise à jour`,
          `${exceptionType === "opening" ? "L'ouverture" : "La fermeture"} du produit #${
            props.productCode
          } a été mise à jour`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à jour", "error", "top");
        setLoading(false);
      });
  };

  const handleFormSubmit = (data: any) => {
    addCombination(data);
  };

  return (
    <Modal isLarge onClose={() => props.close()}>
      <ModalHeader>{exceptionType === "opening" ? "Ouvrir" : "Fermer"} une combinaison</ModalHeader>
      <Body>
        {productOptions && warehouseOptions && customerOptions && (
          <Form id="dialog_form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Field>
              <Label>Produit concerné</Label>
              <Autocomplete
                className="mt-2"
                control={control}
                // disabled={props.productCode !== undefined}
                name="productCodeLabel"
                options={getOptions(productOptions, "product_code", "product_label")}
                rules={{ required: true }}
                validation={errors.productCodeLabel ? "error" : undefined}
                value={props.productCode ? `${props.productCode}` : ""}
              />
              {errors.productCodeLabel && (
                <Message className="mt-2" validation="error">
                  Vous devez choisir un produit à ouvrir
                </Message>
              )}
            </Field>
            <Row>
              <Col size={6}>
                <Field>
                  <Label>Entrepôt</Label>
                  <Autocomplete
                    className="mt-2"
                    control={control}
                    name="shippingWarehouseCode"
                    options={[""].concat(warehouseOptions).sort() || []}
                    rules={{ required: true }}
                    validation={errors.shippingWarehouseCode ? "error" : undefined}
                    value={props.shippingWharehouseCode}
                  />
                  {errors.shippingWarehouseCode && (
                    <Message className="mt-2" validation="error">
                      Vous devez choisir un dépôt
                    </Message>
                  )}
                </Field>
              </Col>
              <Col size={6}>
                <Field>
                  <Label>Client</Label>
                  <Autocomplete
                    className="mt-2"
                    control={control}
                    name="customerCodeName"
                    options={getOptions(customerOptions, "delivered_customer_code", "delivered_customer_name")}
                    rules={{ required: true }}
                    value={props.customerCode ? `${props.customerCode}` : ""}
                  />
                  {errors.customerCodeName && (
                    <Message className="mt-2" validation="error">
                      Vous devez choisir un client livré
                    </Message>
                  )}
                </Field>
              </Col>
            </Row>
            {exceptionType === "opening" && (
              <Row>
                <Col size={8}>
                  <Field>
                    <Label>Produit d'alignement</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="productCodeLabelMatch"
                      options={getOptions(productOptions, "product_code", "product_label")}
                      rules={{ required: true }}
                      validation={errors.productCodeLabelMatch ? "error" : undefined}
                      value={props.productCodeMatch ? `${props.productCodeMatch}` : ""}
                    />
                    {errors.productCodeLabelMatch && (
                      <Message className="mt-2" validation="error">
                        Vous devez choisir un produit de référence
                      </Message>
                    )}
                  </Field>
                </Col>
                <Col size={4}>
                  <Field>
                    <Label>Quantité (kg)</Label>
                    <Input
                      type="number"
                      step="any"
                      {...register("salesQuantity", { required: false, value: props.salesQuantity })}
                    />
                  </Field>
                </Col>
              </Row>
            )}
          </Form>
        )}
        {combinationError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{combinationError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {loading
              ? "Création en cours..."
              : exceptionType === "opening"
              ? "Créer l'ouverture"
              : "Créer la fermeture"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default CombinationExceptionDialog;
