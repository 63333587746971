import { useEffect, useRef, useState } from "react";

import { IToolPanelParams, ModelUpdatedEvent } from "ag-grid-community";

import { Button } from "@zendeskgarden/react-buttons";
import { Dropdown, Item, Menu, Trigger } from "@zendeskgarden/react-dropdowns";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Body, Head, HeaderCell, Table } from "@zendeskgarden/react-tables";
import { ReactComponent as ChevronIcon } from "@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg";

import { FamilyOption, InputStats, WeeklyForecast } from "../../../../interfaces";
import { useApiGet, useApiInstance } from "../../../../hooks/useApi";
import useToast from "../../../../hooks/useToast";
import { valueDisplay } from "../../../../utils/helpers";
import { AGCell, AGHeaderRow, AGRow } from "../../../../components/Styled";

const DashboardPanel = (
  props: IToolPanelParams & { forecast: WeeklyForecast; familyCode: string; familyComponents: string[] }
) => {
  const { forecast, familyCode, familyComponents, api } = props;

  const preveolApi = useApiInstance();
  const { addToast } = useToast();

  const [familyComponent, setFamilyComponent] = useState<string>(familyComponents[0]);
  const familyComponentRef = useRef<string>(familyComponent);

  const [{ data: familyOptions }] = useApiGet("/datasource/family/values/family_code/family_label");
  const [rotated, setRotated] = useState<boolean | undefined>();
  const [inputStats, setInputStats] = useState<InputStats[] | null>();

  const updateInput = (familyComponent: string) => {
    preveolApi
      .get(`/input/${forecast.id}/${familyCode}/${familyComponent}/stats`)
      .then((req) => {
        const data = req.data;
        if (data === "") {
          setInputStats(null);
        } else {
          setInputStats(data);
        }
      })
      .catch(() => addToast("Erreur", "Impossible de charger les données.", "error"));
  };

  const update = (evt: ModelUpdatedEvent) => {
    if (evt.newData) {
      updateInput(familyComponentRef.current);
    }
  };

  useEffect(() => {
    api.addEventListener("modelUpdated", update);
    return () => api.removeEventListener("modelUpdated", update);
  }, []);

  useEffect(() => {
    familyComponentRef.current = familyComponent;
    updateInput(familyComponent);
  }, [familyComponent]);

  const getOptionLabel = (component: string) => {
    if (familyOptions && component) {
      const familyOption: FamilyOption = familyOptions.find(
        (familyOption: FamilyOption) => familyOption.family_code === component
      );
      return `${familyOption.family_label} (${familyOption.family_code})`;
    }
    return null;
  };

  return (
    <>
      <Row>
        {familyOptions && (
          <Col className="m-2">
            {familyComponents.length > 1 ? (
              <Dropdown
                selectedItem={familyComponent}
                onSelect={(item) => setFamilyComponent(item)}
                onStateChange={(options) => options.hasOwnProperty("isOpen") && setRotated(options.isOpen)}
              >
                <Trigger>
                  <Button isPrimary size="small">
                    Composant : {getOptionLabel(familyComponent)}
                    <Button.EndIcon isRotated={rotated}>
                      <ChevronIcon />
                    </Button.EndIcon>
                  </Button>
                </Trigger>
                <Menu isCompact>
                  {familyComponents
                    ? familyComponents.map((option: string) => {
                        const familyOption = familyOptions.find(
                          (familyOption: FamilyOption) => familyOption.family_code === option
                        );
                        return (
                          <Item key={option} value={option}>
                            {`${familyOption.family_label} (${familyOption.family_code})`}
                          </Item>
                        );
                      })
                    : ""}
                </Menu>
              </Dropdown>
            ) : (
              <Button size="small">Composant : {getOptionLabel(familyComponent)}</Button>
            )}
          </Col>
        )}
      </Row>

      {familyComponent && inputStats && (
        <>
          <InputTable inputStats={inputStats} minWidth={400} />
          <ReservationTable inputStats={inputStats} minWidth={400} />
          <BalanceTable inputStats={inputStats} minWidth={400} />
        </>
      )}
    </>
  );
};

const InputTable = (props: { inputStats: InputStats[]; minWidth: number }) => {
  const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "decimal", maximumFractionDigits: 0 });
  return (
    <Table size="small" style={{ fontSize: "small", minWidth: props.minWidth }}>
      <Head>
        <AGHeaderRow>
          <HeaderCell width={155}>APPORTS</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S+1</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S+2</HeaderCell>
        </AGHeaderRow>
      </Head>
      {props.inputStats && (
        <Body>
          <AGRow>
            <AGCell>Apports extranet</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["input_quantity_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Reports d'apports</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["carry_over_quantity_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Transferts d'apports</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["input_transfer_quantity_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Apports totaux</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["input_total_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Evolution</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["input_variation_kg"], 100, "%")}
              </AGCell>
            ))}
          </AGRow>
        </Body>
      )}
    </Table>
  );
};

const ReservationTable = (props: { inputStats: InputStats[]; minWidth: number }) => {
  const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "decimal", maximumFractionDigits: 0 });
  return (
    <Table size="small" style={{ fontSize: "small", minWidth: props.minWidth }}>
      <Head>
        <AGHeaderRow>
          <HeaderCell width={155}>ENGAGEMENTS</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S+1</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S+2</HeaderCell>
        </AGHeaderRow>
      </Head>
      {props.inputStats && (
        <Body>
          <AGRow>
            <AGCell>Taux engagements</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(
                  numberFormatter,
                  props.inputStats[w]["input_total_kg"] > 1
                    ? (props.inputStats[w]["reservation_consumption_kg"] +
                        props.inputStats[w]["not_fdr_overwrite_consumption_kg"] +
                        props.inputStats[w]["melimelo_overwrite_consumption_kg"]) /
                        props.inputStats[w]["input_total_kg"]
                    : null,
                  100,
                  "%"
                )}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Taux mini</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["rate_mini"], 100, "%")}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Taux maxi</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["rate_maxi"], 100, "%")}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Conso Méli-mélos</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["melimelo_consumption_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Conso promotions</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["promotion_consumption_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Conso lancements</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["product_launch_consumption_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Total réservations</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(numberFormatter, props.inputStats[w]["reservation_consumption_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Surcharges</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(
                  numberFormatter,
                  props.inputStats[w]["not_fdr_overwrite_consumption_kg"] +
                    props.inputStats[w]["melimelo_overwrite_consumption_kg"]
                )}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Total engagements</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(
                  numberFormatter,
                  props.inputStats[w]["reservation_consumption_kg"] +
                    props.inputStats[w]["not_fdr_overwrite_consumption_kg"] +
                    props.inputStats[w]["melimelo_overwrite_consumption_kg"]
                )}
              </AGCell>
            ))}
          </AGRow>
        </Body>
      )}
    </Table>
  );
};

const BalanceTable = (props: { inputStats: InputStats[]; minWidth: number }) => {
  const numberFormatter = new Intl.NumberFormat("fr-FR", { style: "decimal", maximumFractionDigits: 0 });

  return (
    <Table size="small" style={{ fontSize: "small", minWidth: props.minWidth }}>
      <Head>
        <AGHeaderRow>
          <HeaderCell width={155}>ATTERRISSAGE</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S+1</HeaderCell>
          <HeaderCell style={{ textAlign: "right" }}>S+2</HeaderCell>
        </AGHeaderRow>
      </Head>
      {props.inputStats && (
        <Body>
          <AGRow>
            <AGCell>Apports restants</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(
                  numberFormatter,
                  props.inputStats[w]["remaining_input_kg"] -
                    props.inputStats[w]["not_fdr_overwrite_consumption_kg"] -
                    props.inputStats[w]["melimelo_overwrite_consumption_kg"]
                )}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Conso FDR (hors MM)</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(
                  numberFormatter,
                  props.inputStats[w]["a_ontheshelf_consumption_kg"] +
                    props.inputStats[w]["sf_ontheshelf_consumption_kg"]
                )}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>- réelle</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {w === 0 && valueDisplay(numberFormatter, props.inputStats[w]["a_ontheshelf_consumption_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>- prévisionnelle</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {w === 0 && valueDisplay(numberFormatter, props.inputStats[w]["sf_ontheshelf_consumption_kg"])}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>- avancement</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {w === 0 &&
                  valueDisplay(
                    numberFormatter,
                    props.inputStats[w]["sf_ontheshelf_consumption_kg"] > 1
                      ? props.inputStats[w]["a_ontheshelf_consumption_kg"] /
                          props.inputStats[w]["sf_ontheshelf_consumption_kg"]
                      : null,
                    100,
                    "%"
                  )}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Solde</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(
                  numberFormatter,
                  props.inputStats[w]["remaining_input_kg"] -
                    props.inputStats[w]["not_fdr_overwrite_consumption_kg"] -
                    props.inputStats[w]["melimelo_overwrite_consumption_kg"] -
                    props.inputStats[w]["a_ontheshelf_consumption_kg"] -
                    props.inputStats[w]["sf_ontheshelf_consumption_kg"]
                )}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Equilibrage</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(
                  numberFormatter,
                  1 -
                    (props.inputStats[w]["remaining_input_kg"] -
                      props.inputStats[w]["not_fdr_overwrite_consumption_kg"] -
                      props.inputStats[w]["melimelo_overwrite_consumption_kg"] -
                      props.inputStats[w]["a_ontheshelf_consumption_kg"] -
                      props.inputStats[w]["sf_ontheshelf_consumption_kg"]) /
                      (props.inputStats[w]["remaining_input_kg"] -
                        props.inputStats[w]["not_fdr_overwrite_consumption_kg"] -
                        props.inputStats[w]["melimelo_overwrite_consumption_kg"]),
                  100,
                  "%"
                )}
              </AGCell>
            ))}
          </AGRow>
          <AGRow>
            <AGCell>Atterrissage</AGCell>
            {[0, 1, 2].map((w: number) => (
              <AGCell key={w} style={{ textAlign: "right" }}>
                {valueDisplay(
                  numberFormatter,
                  props.inputStats[w]["reservation_consumption_kg"] +
                    props.inputStats[w]["not_fdr_overwrite_consumption_kg"] +
                    props.inputStats[w]["melimelo_overwrite_consumption_kg"] +
                    props.inputStats[w]["a_ontheshelf_consumption_kg"] +
                    props.inputStats[w]["sf_ontheshelf_consumption_kg"]
                )}
              </AGCell>
            ))}
          </AGRow>
        </Body>
      )}
    </Table>
  );
};

export default DashboardPanel;
