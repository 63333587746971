import { PropsWithChildren } from "react";

import { Tag } from "@zendeskgarden/react-tags";
import { PALETTE } from "@zendeskgarden/react-theming";

import { Forecast } from "../interfaces";

const ForecastSubTypeTag = (props: PropsWithChildren<{ forecast: Forecast }>) => {
  switch (props.forecast.subType) {
    case "ORIGINAL":
      return (
        <Tag isPill hue={PALETTE.grey[200]}>
          Initial
        </Tag>
      );
    case "REVIEW":
      return (
        <Tag isPill hue={PALETTE.crimson[600]}>
          Actualisation
        </Tag>
      );
    case "SUBSTITUTION":
      return (
        <Tag isPill hue={PALETTE.orange[600]}>
          Substitution
        </Tag>
      );
    default:
      return null;
  }
};

export default ForecastSubTypeTag;
