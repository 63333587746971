import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Input, Label, Message } from "@zendeskgarden/react-forms";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";

import { Autocomplete, Form } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { WeeklyForecast } from "../../../interfaces";

type ComponentOption = {
  family_code: string;
  family_label: string;
};

type ProductOption = {
  product_code: string;
  product_label: string;
};

type ProductRecipeItem = {
  component_family_code: string;
  component_family_label: string;
  quantity_percent: string | number;
};

const BudgetMelimeloOverwriteDialog = (props: {
  close: Function;
  forecast: WeeklyForecast;
  id?: string;
  productLabel?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: options }] = useApiGet(`/forecast/${props.forecast.id}/nomenclature/melimelo/values`);
  const [{ data: components }] = useApiGet(`/forecast/${props.forecast.id}/nomenclature/available`);
  const [componentList, setComponentList] = useState<ProductRecipeItem[]>([]);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const watchProductCode = watch("productCode");
  const watchNewComponentCode = watch("newComponentCode");
  const watchRecipe = watch("recipe");

  const getCode = (code: string) => {
    return code.length < 3 ? "0".concat(code) : code;
  };

  useEffect(() => {
    if (watchProductCode) {
      const code =
        props.id ??
        options.find((option: ProductOption) => `${option.product_code} - ${option.product_label}` === watchProductCode)
          .product_code;
      api
        .get(`/forecast/${props.forecast.id}/nomenclature/value/${code}`)
        .then((req) => {
          setComponentList(req.data);
          updateRecipe(req.data);
          // remove from available components list
          req.data.forEach((component: ProductRecipeItem) => {
            const index = components.findIndex(
              (item: ComponentOption) => item.family_code === component.component_family_code
            );
            if (index !== -1) {
              components.splice(index, 1);
            }
          });
        })
        .catch(() => addToast("Erreur", `Impossible de charger la liste des valeurs.`, "error"));
    }
  }, [watchProductCode]);

  useEffect(() => {
    if (watchNewComponentCode) {
      const code = components.find(
        (option: ComponentOption) => `${option.family_code} - ${option.family_label}` === watchNewComponentCode
      ).family_code;
      console.log(`add ${code}`);
      updateComponentLists(code);
    }
  }, [watchNewComponentCode]);

  const updateRecipe = (components: ProductRecipeItem[]) => {
    let recipe: { quantity: number }[] = [];
    components.forEach((component: ProductRecipeItem) => {
      // add to recipe
      recipe[+component.component_family_code] = {
        quantity:
          component.quantity_percent > 1 ? +component.quantity_percent : Math.floor(+component.quantity_percent * 100),
      };
      setValue("recipe", recipe);
    });
  };

  const updateComponentLists = (newComponentCode: string) => {
    const newComponentIndex = components.findIndex((item: ComponentOption) => item.family_code === newComponentCode);
    console.log(components, newComponentCode, newComponentIndex);
    // add new component in recipe
    const productRecipe = [
      ...componentList,
      {
        component_family_code: newComponentCode,
        component_family_label: components[newComponentIndex].family_label,
        quantity_percent: 0.0,
      },
    ];
    setComponentList(productRecipe);
    updateRecipe(productRecipe);
    // remove from available components list
    components.splice(newComponentIndex, 1);
    setValue("newComponentCode", null);
  };

  const updateNomenclatureOverwrite = (data: any) => {
    setLoading(true);
    const recipe = [];
    for (let i = 0; i < data.recipe.length; i++) {
      if (data.recipe[i]) {
        recipe.push({ familyCode: getCode(i.toString()), percent: data.recipe[i].quantity });
      }
    }
    api
      .post(`forecast/${props.forecast?.id}/nomenclature`, { productCode: props.id, recipe: recipe })
      .then(() => {
        addToast("Prévision mise à jour", `La prévision #${props.forecast.id} a été mise à jour`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à jour de la prévision", "error", "top");
        setLoading(false);
      });
  };

  const createNomenclatureOverwrite = (data: any) => {
    setLoading(true);
    const recipe = [];
    for (let i = 0; i < data.recipe.length; i++) {
      if (data.recipe[i]) {
        recipe.push({ familyCode: getCode(i.toString()), percent: data.recipe[i].quantity });
      }
    }
    api
      .post(`forecast/${props.forecast?.id}/nomenclature`, {
        productCode: options.find(
          (option: ProductOption) => `${option.product_code} - ${option.product_label}` === data.productCode
        ).product_code,
        recipe: recipe,
      })
      .then(() => {
        addToast("Prévision mise à jour", `La prévision #${props.forecast.id} a été mise à jour`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à jour de la prévision", "error", "top");
        setLoading(false);
      });
  };

  const handleFormSubmit = (data: any) => {
    if (props.id) {
      updateNomenclatureOverwrite(data);
    } else {
      createNomenclatureOverwrite(data);
    }
  };

  const totalPercent = (recipe: { quantity: number }[]) => {
    return recipe
      .map((component: { quantity: number }) => {
        if (isNaN(component.quantity)) return 0;
        return component.quantity;
      })
      .reduce((sum: number, next: number) => sum + next);
  };

  const createInputComponentField = (component: ProductRecipeItem) => (
    <Field key={component.component_family_code}>
      {" "}
      <Label>
        {component.component_family_code} - {component.component_family_label}{" "}
      </Label>
      <Input
        isCompact
        type="integer"
        {...register(`recipe.${+component.component_family_code}.quantity` as const, {
          required: true,
          valueAsNumber: true,
        })}
      />
    </Field>
  );

  return (
    <Modal onClose={() => props.close(false)}>
      <ModalHeader>Surcharger une recette méli-mélo</ModalHeader>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Body>
          <Form>
            <Field>
              <Label>Produit Méli-mélo</Label>
              <Autocomplete
                control={control}
                name={"productCode"}
                options={
                  options?.map((option: ProductOption) => `${option.product_code} - ${option.product_label}`).sort() ||
                  []
                }
                rules={{ required: true }}
                disabled={!!props.id}
                className={"mt-2"}
                value={props.id ? `${props.id} - ${props.productLabel}` : ""}
                validation={errors.productCode ? "error" : undefined}
              />
              {errors.productCode && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner un code produit
                </Message>
              )}
            </Field>
            {componentList.length > 0 && componentList.map(createInputComponentField)}
            {componentList.length > 0 && (
              <Field>
                <Label>Ajouter un composant...</Label>
                <Autocomplete
                  control={control}
                  name={"newComponentCode"}
                  options={
                    components
                      ?.map((option: ComponentOption) => `${option.family_code} - ${option.family_label}`)
                      .sort() || []
                  }
                  className={"mt-2"}
                />
              </Field>
            )}
          </Form>
        </Body>
        <Footer>
          {watchRecipe && <FooterItem>Pourcentage total : {totalPercent(watchRecipe)}</FooterItem>}
          <FooterItem>
            <Button onClick={() => props.close(false)} isBasic>
              Annuler
            </Button>
          </FooterItem>
          <FooterItem>
            <Button isPrimary disabled={loading || !watchRecipe || totalPercent(watchRecipe) !== 100} type="submit">
              {loading ? "Modification en cours..." : "Modifier la recette"}
            </Button>
          </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
      </form>
    </Modal>
  );
};

export default BudgetMelimeloOverwriteDialog;
