import { Link } from "react-router-dom";

import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Body as ChromeBody, Content, HeaderItemWrapper } from "@zendeskgarden/react-chrome";
import { Title, Well } from "@zendeskgarden/react-notifications";
import { Span, UnorderedList } from "@zendeskgarden/react-typography";

import Header from "../../components/Header";
import { PaddedMain } from "../../components/Styled";

const Settings = () => {
  return (
    <ChromeBody>
      <Header helmetTitle="Paramètres">
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Span>Paramètres</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
      </Header>
      <Content>
        <PaddedMain>
          <Well className="mb-6">
            <Title className="mb-1">
              <Link to="/settings/closed">Fermetures</Link>
            </Title>
            Gérer les fermetures des sites et les règles de report associées
            <UnorderedList className="mt-3">
              <UnorderedList.Item>
                <Link to="/settings/closed/day">Jours de fermeture</Link>
              </UnorderedList.Item>
              <UnorderedList.Item>
                <Link to="/settings/closed/warehouse">Fermetures de dépôts</Link>
              </UnorderedList.Item>
            </UnorderedList>
          </Well>

          <Well className="mb-6">
            <Title className="mb-1">
              <Link to="/settings/lifecycle">Dates de commercialisation</Link>
            </Title>
            Gérer le cycle de vie des produits
          </Well>

          <Well className="mb-6">
            <Title className="mb-1">
              <Link to="/settings/launch">Lancements de produits</Link>
            </Title>
            Gérer les lancements de produits
          </Well>

          <Well className="mb-6">
            <Title className="mb-1">Historique des ventes</Title>
            Gérer les différents ajustements de l'historique des ventes
            <UnorderedList className="mt-3">
              <UnorderedList.Item>
                <Link to="/settings/activity/matching">Alignements</Link>
              </UnorderedList.Item>
              <UnorderedList.Item>
                <Link to="/settings/activity/exclusion">Exclusions</Link>
              </UnorderedList.Item>
              <UnorderedList.Item>
                <Link to="/settings/activity/overwrite">Surcharges</Link>
              </UnorderedList.Item>
            </UnorderedList>
          </Well>
        </PaddedMain>
      </Content>
    </ChromeBody>
  );
};

export default Settings;
