import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Alert, Title } from "@zendeskgarden/react-notifications";

import { Form, Autocomplete, Datepicker } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";

const ProductLaunchDialog = (props: { selectedProductLaunch?: any; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: productOptions }] = useApiGet("/datasource/product/values/product_code");
  const [{ data: customerOptions }] = useApiGet("/datasource/customer/values/customer_code");
  const [{ data: subtypeOptions }] = useApiGet(
    "/datasource/customer/values/customer_subtype_code/customer_subtype_label"
  );
  const [{ data: typeOptions }] = useApiGet("/datasource/customer/values/customer_type_code/customer_type_label");
  const [{ data: shippingWarehouseOptions }] = useApiGet("/datasource/activity/values/shipping_warehouse_code");
  const [{ data: launchTypeOptions }] = useApiGet("/launch/type");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const watchStartDate = watch("startDate");
  const watchEndDate = watch("endDate");
  const watchCustomerTypeLabel = watch("customerTypeLabel");
  const watchCustomerSubtypeLabel = watch("customerSubtypeLabel");
  const watchCustomerCode = watch("customerCode");

  useEffect(() => {
    if (props.selectedProductLaunch) {
      setValue("productCode", props.selectedProductLaunch.product_code);
      setValue("productLaunchType", props.selectedProductLaunch.product_launch_type);
      setValue("customerTypeCode", props.selectedProductLaunch.customer_type_code);
      setValue("customerTypeLabel", props.selectedProductLaunch.customer_type_label);
      setValue("customerSubtypeCode", props.selectedProductLaunch.customer_subtype_code);
      setValue("customerSubtypeLabel", props.selectedProductLaunch.customer_subtype_label);
      setValue("customerCode", props.selectedProductLaunch.customer_code);
      setValue("shippingWarehouseCode", props.selectedProductLaunch.shipping_warehouse_cCode);
      setValue("startDate", new Date(props.selectedProductLaunch.start_date));
      setValue("endDate", new Date(props.selectedProductLaunch.end_date));
    }
  }, []);

  const productLaunchPayload = (data: any) => {
    return {
      ...data,
      id: props.selectedProductLaunch ? props.selectedProductLaunch.id : 0,
      productLaunchType: data.productLaunchType,
      customerTypeCode: data.customerTypeLabel
        ? typeOptions.find(
            (option: { customer_type_code: string; customer_type_label: string }) =>
              option.customer_type_label === data.customerTypeLabel
          ).customer_type_code
        : null,
      customerSubtypeCode: data.customerSubtypeLabel
        ? subtypeOptions.find(
            (option: { customer_subtype_code: string; customer_subtype_label: string }) =>
              option.customer_subtype_label === data.customerSubtypeLabel
          ).customer_subtype_code
        : null,
      customerCode: data.customerCode ? data.customerCode : null,
      shippingWarehouseCode: data.shippingWarehouseCode ? data.shippingWarehouseCode : null,
      startDate: moment(data.startDate).format("YYYY-MM-DD"),
      endDate: moment(data.endDate).format("YYYY-MM-DD"),
    };
  };

  const handleProductLaunch = (data: any) => {
    setApiError("");
    setLoading(true);
    if (props.selectedProductLaunch === undefined) {
      api
        .post(`launch`, productLaunchPayload(data))
        .then((response) => {
          addToast(
            "Création de lancement produit",
            `Le lancement de produit #${response.data.id} a été créé`,
            "success",
            "top"
          );
          props.close(true);
        })
        .catch((error) => {
          setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
          setLoading(false);
        });
    } else {
      api
        .put(`launch/${props.selectedProductLaunch.id}`, productLaunchPayload(data))
        .then((response) => {
          addToast(
            "Modification de lancement produit",
            `Le lancement de produit #${response.data.id} a été modifié`,
            "success",
            "top"
          );
          props.close(true);
        })
        .catch((error) => {
          setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
          setLoading(false);
        });
    }
  };

  let buttonContent =
    props.selectedProductLaunch === undefined ? "Créer le lancement de produit" : "Modifier le lancement de produit";
  if (loading)
    buttonContent = props.selectedProductLaunch === undefined ? "Création en cours..." : "Modification en cours...";

  return (
    <Modal isLarge onClose={() => props.close()}>
      <ModalHeader>
        {props.selectedProductLaunch === undefined
          ? "Créer un nouveau lancement de produit"
          : `Modifier le lancement #${props.selectedProductLaunch?.id} du produit ${props.selectedProductLaunch?.product_code}`}
      </ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(handleProductLaunch)}>
          <Row>
            <Col sm={6} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Date début</Label>
                <Datepicker value={watchStartDate} onChange={(d) => setValue("startDate", d)} maxValue={watchEndDate}>
                  <Input
                    {...register("startDate", { required: true })}
                    validation={errors.startDate ? "error" : undefined}
                  />
                </Datepicker>
                {errors.startDate && (
                  <Message className="mt-2" validation="error">
                    Vous devez renseigner une date de début
                  </Message>
                )}
              </Field>
            </Col>
            <Col sm={6} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Date fin</Label>
                <Datepicker value={watchEndDate} onChange={(d) => setValue("endDate", d)} minValue={watchStartDate}>
                  <Input
                    {...register("endDate", { required: true })}
                    validation={errors.endDate ? "error" : undefined}
                  />
                </Datepicker>
                {errors.endDate?.type === "required" && (
                  <Message className="mt-2" validation="error">
                    Vous devez renseigner une date de fin
                  </Message>
                )}
                {errors.endDate?.type === "validate" && (
                  <Message className="mt-2" validation="error">
                    La date de fin doit être ultérieure à la date de début
                  </Message>
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col sm={5} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Type de lancement</Label>
                <Autocomplete
                  className="mt-2"
                  control={control}
                  name="productLaunchType"
                  options={launchTypeOptions || []}
                  rules={{ required: true }}
                  validation={errors.productLaunchType ? "error" : undefined}
                  value={props.selectedProductLaunch?.product_launch_type}
                />
                {errors.productLaunchType && (
                  <Message className="mt-2" validation="error">
                    Vous devez choisir un type de lancement de produit
                  </Message>
                )}
              </Field>
            </Col>
            <Col sm={4} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Produit</Label>
                <Autocomplete
                  className="mt-2"
                  control={control}
                  name="productCode"
                  options={[""].concat(productOptions?.sort()) || []}
                  rules={{ required: true }}
                  validation={errors.productCode ? "error" : undefined}
                  value={props.selectedProductLaunch?.product_code}
                  disabled={props.selectedProductLaunch !== undefined}
                />
                {errors.productCode && (
                  <Message className="mt-2" validation="error">
                    Vous devez choisir un code produit
                  </Message>
                )}
              </Field>
            </Col>
            <Col sm={3} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Quantité totale (kg)</Label>
                <Input
                  type="number"
                  step="any"
                  {...register("totalQuantity", {
                    required: true,
                    min: 1,
                    value: props.selectedProductLaunch?.total_quantity,
                  })}
                  required
                />
                {errors.totalQuantity && (
                  <Message className="mt-2" validation="error">
                    Vous devez renseigner une quantité totale valide
                  </Message>
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col sm={4} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Type client</Label>
                <Autocomplete
                  className="mt-2"
                  control={control}
                  name="customerTypeLabel"
                  options={
                    [""].concat(
                      typeOptions
                        ?.map(
                          (option: { customer_type_code: string; customer_type_label: string }) =>
                            option.customer_type_label
                        )
                        .sort()
                    ) || []
                  }
                  validation={errors.customerTypeLabel ? "error" : undefined}
                  value={props.selectedProductLaunch?.customer_type_label}
                  disabled={!!watchCustomerCode || !!watchCustomerSubtypeLabel}
                />
              </Field>
            </Col>
            <Col sm={4} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Enseigne</Label>
                <Autocomplete
                  className="mt-2"
                  control={control}
                  name="customerSubtypeLabel"
                  options={
                    [""].concat(
                      subtypeOptions
                        ?.map(
                          (option: { customer_subtype_code: string; customer_subtype_label: string }) =>
                            option.customer_subtype_label
                        )
                        .sort()
                    ) || []
                  }
                  validation={errors.subtypeLabel ? "error" : undefined}
                  value={props.selectedProductLaunch?.customer_subtype_label}
                  disabled={!!watchCustomerCode || !!watchCustomerTypeLabel}
                />
              </Field>
            </Col>
            <Col sm={4} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Code Client</Label>
                <Autocomplete
                  className="mt-2"
                  control={control}
                  name="customerCode"
                  options={[""].concat(customerOptions).sort() || []}
                  validation={errors.customerCode ? "error" : undefined}
                  value={props.selectedProductLaunch?.customer_code}
                  disabled={!!watchCustomerTypeLabel || !!watchCustomerSubtypeLabel}
                />
              </Field>
            </Col>
            <Col sm={4} style={{ marginTop: "10px" }}>
              <Field>
                <Label>Entrepôt</Label>
                <Autocomplete
                  className="mt-2"
                  control={control}
                  name="shippingWarehouseCode"
                  options={[""].concat(shippingWarehouseOptions).sort() || []}
                  validation={errors.shippingWarehouseCode ? "error" : undefined}
                  value={props.selectedProductLaunch?.shipping_warehouse_code}
                />
              </Field>
            </Col>
          </Row>
        </Form>
        {apiError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{apiError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {buttonContent}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default ProductLaunchDialog;
