import { Client, IMessage } from "@stomp/stompjs";
import { useEffect, useState } from "react";

import { Notification, useToast } from "@zendeskgarden/react-notifications";
import { PALETTE } from "@zendeskgarden/react-theming";

import ProgressBar from "./ProgressBar";
import { Job } from "../interfaces";

const JobNotification = (props: { job: Job }) => {
  const { job } = props;
  const animated = job.status === "RUNNING";
  const progress = job.status === "PENDING" ? 0 : 100;
  let color = PALETTE.blue[600];
  switch (job.status) {
    case "ERROR":
      color = PALETTE.red[600];
      break;
    case "COMPLETE":
      color = PALETTE.green[600];
      break;
  }
  return (
    <div className="my-4 text-sm">
      <div className="mb-1">{job.description}</div>
      <ProgressBar color={color} animated={animated} progress={progress} />
      {job.error && <div className="mt-1 text-red-600">Erreur : {job.error}</div>}
    </div>
  );
};

const JobsNotification = (props: { jobs: Job[] }) => {
  const { jobs } = props;
  return (
    <Notification className="p-3 py-0" style={{ width: 350 }}>
      {jobs.map((job) => (
        <JobNotification job={job} key={job.uuid} />
      ))}
    </Notification>
  );
};

const ServerJobsToast = () => {
  const { addToast, updateToast, removeToast } = useToast();

  const [jobs, setJobs] = useState<Job[]>([]);
  const [toastId, setToastId] = useState<string | undefined>(undefined);

  // prepare client
  const client = new Client({
    brokerURL: process.env.REACT_APP_WEBSOCKET,
    debug: function (str) {
      // console.log(str);
    },
  });

  // prepare subscription
  client.onConnect = () => {
    const callback = (message: IMessage) => {
      setJobs(JSON.parse(message.body) as Job[]);
    };
    client.subscribe("/app/jobs/init", callback);
    client.subscribe("/jobs/update", callback);
  };

  // activate client
  useEffect(() => {
    client.activate();
    return () => {
      client.deactivate();
    };
  }, []);

  // logic
  useEffect(() => {
    if (jobs.length) {
      // there is jobs
      if (toastId) {
        updateToast(toastId, {
          content: ({ close }) => <JobsNotification jobs={jobs} />,
        });
      } else {
        const id = addToast(({ close }) => <JobsNotification jobs={jobs} />, {
          placement: "bottom-start",
          autoDismiss: false,
        });
        setToastId(id);
      }
    } else {
      // there is no jobs
      if (toastId) {
        removeToast(toastId);
        setToastId(undefined);
      }
    }
  }, [jobs]);

  return <></>;
};

export default ServerJobsToast;
