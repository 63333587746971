import { PropsWithChildren } from "react";
import moment from "moment";
import styled from "styled-components";
import { ReactComponent as ClockIcon } from "@zendeskgarden/svg-icons/src/16/clock-fill.svg";
import { Tag } from "@zendeskgarden/react-tags";

export const NiceTag = styled(Tag)`
  margin-left: 3px;
  margin-right: 10px;
`;

const UpdateTag = (props: PropsWithChildren<{ updated: string }>) => {
  const now = moment(new Date());
  const last = moment(props.updated);
  const duration = moment.duration(now.diff(last));
  let color = "#63b01e";
  if (duration.asHours() > 48) {
    color = "red";
  } else if (duration.asHours() > 24) {
    color = "#ff771c";
  }
  return (
    <NiceTag size="medium" hue={color}>
      <ClockIcon style={{ color: "white" }} />
    </NiceTag>
  );
};

export default UpdateTag;
