import { IModalProps } from "@zendeskgarden/react-modals/dist/typings/elements/Modal";
import React, { Ref } from "react";
import { Modal } from "@zendeskgarden/react-modals";
import { Dots } from "@zendeskgarden/react-loaders";
import styled, { useTheme } from "styled-components";
import { IGardenTheme } from "@zendeskgarden/react-theming";

export type LoadingModalProps = {
  loading?: boolean;
  loadingMessage?: string;
} & IModalProps;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 999;
  text-align: center;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in;
`;

/**
 * A modal with loading capabilities
 *
 * @param props
 */

const LoadingModal = React.forwardRef((props: LoadingModalProps, ref?: Ref<HTMLDivElement>) => {
  const { loading, loadingMessage, children, ...modalProps } = props;
  const theme = useTheme() as IGardenTheme;

  return (
    <Modal ref={ref} {...modalProps}>
      <Backdrop className={loading ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}>
        <Dots size={48} color={theme.colors.primaryHue} />
        {loadingMessage && <div className="mt-0 text-sm">{loadingMessage}</div>}
      </Backdrop>
      {children}
    </Modal>
  );
});

export default LoadingModal;
