import { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import FlexView from "react-flexview";

import { Button } from "@zendeskgarden/react-buttons";
import { Body as ChromeBody, Content, FooterItem, HeaderItemWrapper } from "@zendeskgarden/react-chrome";
import { Dots } from "@zendeskgarden/react-loaders";
import { Title, Well } from "@zendeskgarden/react-notifications";
import { Tag } from "@zendeskgarden/react-tags";
import { PALETTE } from "@zendeskgarden/react-theming";
import { LG, Span, UnorderedList } from "@zendeskgarden/react-typography";

import { ReactComponent as ConnectedIcon } from "@zendeskgarden/svg-icons/src/16/arrow-trending-fill.svg";
import QualityGrid from "./quality/QualityGrid";
import ConfirmDialog from "../components/ConfirmDialog";
import Header from "../components/Header";
import { CompactFooter, PaddedMain } from "../components/Styled";
import UpdateTag from "../components/UpdateTag";
import { useApiGet, useApiInstance } from "../hooks/useApi";
import useToast from "../hooks/useToast";

function Home() {
  const api = useApiInstance();
  const { addToast } = useToast();

  const [{ data, loading, error }, refetch] = useApiGet("/user");
  const [{ data: dataSources }, updateDataSources] = useApiGet("/datasource");
  const [{ data: budgetForecast }, updateBudget] = useApiGet("/forecast/last/BUDGET");
  const [{ data: weeklyForecast }, updateWeekly] = useApiGet("/forecast/last/WEEKLY");

  const [waiting, setWaiting] = useState(false);
  const [runAnalyzerDialog, setRunAnalyzerDialog] = useState<boolean>(false);

  const handleRefech = async () => {
    try {
      await refetch();
      updateDataSources();
      updateBudget();
      updateWeekly();
    } catch (err) {
      console.error(err);
    }
  };

  const runAnalyzer = () => {
    setRunAnalyzerDialog(false);
    setWaiting(true);
    api
      .post(`anomaly/analyze`, {})
      .then((response) => {
        addToast("Analyse qualité", `L'analyse qualité a été effectuée.`, "success", "top");
        setWaiting(false);
      })
      .catch((error) => {
        addToast("Analyse qualité", `L'analyse qualité est en erreur.`, "error", "top");
        setWaiting(false);
      });
  };

  // @ts-ignore
  return (
    <ChromeBody>
      <Header helmetTitle="Accueil">
        <HeaderItemWrapper maxX>
          <LG>Bienvenue sur Prévéol</LG>
        </HeaderItemWrapper>
      </Header>
      <Content id="home-content">
        <PaddedMain>
          <FlexView column height="100%">
            <FlexView>
              <Well isRecessed>
                <Title>
                  <Span>API</Span>
                </Title>
                <Span>
                  <Span.StartIcon>
                    <ConnectedIcon />
                  </Span.StartIcon>
                </Span>
                {loading && <Dots />}
                {data && (
                  <Tag hue="blue">
                    <span>&nbsp;connectée</span>
                  </Tag>
                )}
                {error && (
                  <div>
                    <Tag hue="red">
                      <span>&nbsp;non connectée</span>
                    </Tag>
                    &nbsp;
                    <Button size="small" onClick={handleRefech}>
                      Re-tester
                    </Button>
                  </div>
                )}
              </Well>
              {dataSources && (
                <Well isRecessed style={{ marginLeft: 10 }}>
                  <Title>
                    <Span>Données d'activité</Span>
                  </Title>
                  <Span>
                    <UpdateTag updated={dataSources.find((src: any) => src.code === "activity").updated} />
                    <Moment fromNow withTitle titleFormat="D MMM YYYY à HH:mm">
                      {dataSources.find((src: any) => src.code === "activity").updated}
                    </Moment>
                  </Span>
                </Well>
              )}
              {weeklyForecast && budgetForecast && (
                <Well isRecessed style={{ marginLeft: 10 }}>
                  <Title>
                    <Span>Dernières prévisions actives</Span>
                  </Title>
                  <UnorderedList size="small" style={{ listStyleType: "none", marginLeft: 0 }}>
                    <UnorderedList.Item>
                      Budget : <Link to={`/budget/${budgetForecast.id}`}>{budgetForecast.label}</Link>
                    </UnorderedList.Item>
                    <UnorderedList.Item>
                      Hebdo : <Link to={`/forecast/${weeklyForecast.id}`}>{weeklyForecast.label}</Link>
                    </UnorderedList.Item>
                  </UnorderedList>
                </Well>
              )}
            </FlexView>
            {!error && <LG style={{ margin: 5 }}>Anomalies des données</LG>}
            <FlexView grow={10} shrink={1}>
              {waiting && !error && <Dots size={16} color={PALETTE.green[600]} style={{ margin: 5 }} />}
              {!waiting && !error && <QualityGrid />}
            </FlexView>
            <CompactFooter align="end">
              <FooterItem>
                <Button size="small" isPrimary disabled={!!error} onClick={() => setRunAnalyzerDialog(true)}>
                  Lancer l'analyse qualité
                </Button>
              </FooterItem>
            </CompactFooter>
          </FlexView>
          {runAnalyzerDialog && (
            <ConfirmDialog onCancel={() => setRunAnalyzerDialog(false)} onValidate={() => runAnalyzer()}>
              Êtes-vous sûr de lancer l'analyse qualité ?
            </ConfirmDialog>
          )}
        </PaddedMain>
      </Content>
    </ChromeBody>
  );
}

export default Home;
