import { PropsWithChildren } from "react";

import { Tag } from "@zendeskgarden/react-tags";
import { PALETTE } from "@zendeskgarden/react-theming";

import { statusValueFormatter } from "../utils/helpers";

export const ExportStatusTag = ({ status }: PropsWithChildren<{ status: string }>) => {
  const hues: { [key: string]: string } = {
    NOT_EXPORTED: PALETTE.grey[200],
    EXPORTED: PALETTE.green[700],
    ERROR: PALETTE.orange[600],
  };

  return (
    <Tag hue={hues[status]} size="small">
      {statusValueFormatter(status)}
    </Tag>
  );
};
