import React, { Ref } from "react";
import styled from "styled-components";
import LoadingModal, { LoadingModalProps } from "./LoadingModal";

/**
 * A fullscreen modal
 */

const StyledLoadingModal = styled(LoadingModal)`
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
`;

const FullScreenModal = React.forwardRef((props: LoadingModalProps, ref?: Ref<HTMLDivElement>) => {
  const { children, ...modalProps } = props;

  return (
    <StyledLoadingModal ref={ref} {...modalProps}>
      {children}
    </StyledLoadingModal>
  );
});

export default FullScreenModal;
