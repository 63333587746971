import { useState } from "react";
import { useParams } from "react-router-dom";

import { ButtonGroup, Button, ToggleIconButton } from "@zendeskgarden/react-buttons";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Header, Body, Close } from "@zendeskgarden/react-modals";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { ReactComponent as FilterIcon } from "@zendeskgarden/svg-icons/src/12/minimize-stroke.svg";

import ForecastResultGrid from "../grids/ForecastResultGrid";
import { ValidationStatusTag } from "../../../components/ForecastValidationStatusTag";
import FullScreenModal from "../../../components/FullScreenModal";
import { useApiGet } from "../../../hooks/useApi";
import { Forecast } from "../../../interfaces";
import { Tag } from "@zendeskgarden/react-tags";

const ForecastFamilyValidationDialog = (props: { forecast: Forecast; close: (success: boolean) => void }) => {
  const { code: familyCode } = useParams();
  const { forecast, close } = props;
  const [unit, setUnit] = useState<string>("colis");
  const [filterPressed, setFilterPressed] = useState(false);
  const [{ data }] = useApiGet(`/validation/${forecast.id}/${familyCode}`);
  const [{ data: productProperties }] = useApiGet(
    `weekly/forecast/${forecast.id}/${familyCode}/information/product`,
    {}
  );
  const [{ data: combinationProperties }] = useApiGet(
    `weekly/forecast/${forecast.id}/${familyCode}/information/combination`,
    {}
  );

  const handleFilterPressed = () => {
    setFilterPressed(!filterPressed);
    if (filterPressed) {
    }
  };

  if (!data || !productProperties || !combinationProperties) {
    return null;
  }

  return (
    <FullScreenModal onClose={() => close(false)}>
      <Header>
        <Row alignItems="end">
          <Col>
            Hebdo #{forecast.id} - {forecast.label}
          </Col>
          <Col>
            <Tag hue="#3A3A3A" size="medium">
              {data.family_label}
            </Tag>
          </Col>
          <Col>
            <ValidationStatusTag status={data.status} />
          </Col>
          <Col sm={1} textAlign={"end"}>
            <Tooltip placement="start" content="Limiter à S-1, S et S+1">
              <ToggleIconButton
                aria-label="Sélection 3 semaines"
                isPressed={filterPressed}
                onClick={handleFilterPressed}
                size="small"
                isPrimary
              >
                <FilterIcon />
              </ToggleIconButton>
            </Tooltip>
          </Col>
          <Col sm={2}>
            <ButtonGroup selectedItem={unit} onSelect={setUnit} style={{ marginRight: 50 }}>
              <Button size="small" value="colis" isPrimary>
                colis
              </Button>
              <Button size="small" value="kg" isPrimary>
                kg
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Header>
      <Body className="p-0">
        <ForecastResultGrid
          forecast={forecast}
          familyCode={familyCode!}
          productInformation={productProperties}
          combinationInformation={combinationProperties}
          status={data.status}
          unit={unit}
          filter={filterPressed}
        />
      </Body>
      <Close aria-label="Close modal" />
    </FullScreenModal>
  );
};
export default ForecastFamilyValidationDialog;
