import { GridOptions, ValueFormatterParams } from "ag-grid-community";

import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { ApiRefetch } from "../../../hooks/useApi";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const ActivityReference = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;

  const baseUrl = `/activity/${forecast.id}`;

  const gridOptions: GridOptions = {
    columnDefs: [
      {
        field: "shipping_date",
        headerName: "Date d'expédition",
        filter: "agDateColumnFilter",
        valueFormatter: (params: ValueFormatterParams) => params.data.shipping_date.substring(0, 10),
      },
      { field: "shipping_warehouse_code", headerName: "Dépôt" },
      { field: "customer_type_code", headerName: "Code type client", hide: true },
      { field: "customer_type_label", headerName: "Type client", filter: "agTextColumnFilter" },
      { field: "customer_subtype_code", headerName: "Code Enseigne", hide: true },
      { field: "customer_subtype_label", headerName: "Enseigne", filter: "agTextColumnFilter" },
      { field: "customer_code", headerName: "Code client" },
      { field: "customer_name", headerName: "Client", filter: "agTextColumnFilter" },
      { field: "delivered_customer_code", headerName: "Code client livré" },
      { field: "delivered_customer_name", headerName: "Client livré", filter: "agTextColumnFilter" },
      { field: "product_family_code", headerName: "Code filière" },
      { field: "product_family_label", headerName: "Filière", filter: "agTextColumnFilter" },
      { field: "product_line_code", headerName: "Code conditionnement", hide: true },
      { field: "product_line_label", headerName: "Conditionnement", filter: "agTextColumnFilter" },
      {
        field: "product_code",
        headerName: "Code produit",
        cellStyle: { color: "green" },
      },
      { field: "product_label", headerName: "Produit", filter: "agTextColumnFilter" },
      { field: "order_type", headerName: "Type" },
      // { field: "order_status", headerName: "Statut" },
      { field: "consumer_unit", headerName: "Unité" },
      {
        field: "sales_quantity",
        headerName: "Nb colis",
        cellStyle: { textAlign: "right" },
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_quantity_kg",
        headerName: "Qté (kg)",
        cellStyle: { textAlign: "right" },
        filter: "agNumberColumnFilter",
      },
      {
        field: "pld_price_uc",
        headerName: "PU €",
        cellStyle: { textAlign: "right" },
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ suffix: "€", minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      },
      {
        field: "pld_amount",
        headerName: "Montant €",
        cellStyle: { textAlign: "right" },
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ suffix: "€", minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
    ],
  };

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions}></ServerSideGrid>;
};

export default ActivityReference;
