import DateCellEditor from "./DateCellEditor";
import NumberCellEditor from "./NumberCellEditor";
import ProgressCellRenderer from "./ProgressCellRenderer";

const agGridComponents = {
  progressCellRenderer: ProgressCellRenderer,
  dateCellEditor: DateCellEditor,
  numberCellEditor: NumberCellEditor,
};

export default agGridComponents;
