import { useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Body, Close, Footer, FooterItem, Header as ModalHeader, Modal } from "@zendeskgarden/react-modals";

import { Alert, Title } from "@zendeskgarden/react-notifications";
import { Autocomplete, Datepicker, Form } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";

type ProductOption = {
  product_code: string;
  product_label: string;
};

const ClosedWarehouseDialog = (props: { close: Function }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: warehouseCodeOptions }] = useApiGet("/datasource/activity/values/shipping_warehouse_code");
  const [{ data: productOptions }] = useApiGet("/datasource/product/values/product_code/product_label");

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchFromDate = watch("fromDate");
  const watchToDate = watch("toDate");
  const watchClosedWarehouseCode = watch("closedWarehouseCode");

  const onSubmit = (data: any) => {
    setApiError("");
    setLoading(true);
    api
      .post(`closed/warehouse`, {
        ...data,
        id: 0,
        fromDate: moment(data.fromDate).format("YYYY-MM-DD"),
        toDate: moment(data.toDate).format("YYYY-MM-DD"),
        productCode: data.productCodeLabel
          ? productOptions.find(
              (option: ProductOption) => `${option.product_code} - ${option.product_label}` === data.productCodeLabel
            ).product_code
          : null,
      })
      .then((response) => {
        addToast(
          "Création d'une fermeture de dépôt",
          `La fermeture du dépôt ${response.data.closedWarehouseCode} a été enregistrée.`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch((error) => {
        setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
        setLoading(false);
      });
  };

  let buttonContent = "Créer la fermeture de dépôt";
  if (loading) buttonContent = "Création en cours...";

  return (
    <Modal onClose={() => props.close(false)}>
      <ModalHeader>Créer une fermeture de dépôt</ModalHeader>
      <Form id="dialog_form" onSubmit={handleSubmit(onSubmit)}>
        <Body>
          {warehouseCodeOptions && productOptions && (
            <Form>
              <Row>
                <Col>
                  <Field>
                    <Label>Entrepôt fermé</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="closedWarehouseCode"
                      options={warehouseCodeOptions || []}
                      rules={{ required: true }}
                      validation={errors.closedWarehouseCode ? "error" : undefined}
                    />
                    {errors.closedWarehouseCode && (
                      <Message className="mt-2" validation="error">
                        Vous devez choisir un dépôt.
                      </Message>
                    )}
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label>Entrepôt de substitution</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="substitutionWarehouseCode"
                      options={warehouseCodeOptions.filter((code: string) => code !== watchClosedWarehouseCode) || []}
                      rules={{ required: false }}
                    />
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field>
                    <Label>Du</Label>
                    <Datepicker value={watchFromDate} onChange={(d) => setValue("fromDate", d)} maxValue={watchToDate}>
                      <Input
                        {...register("fromDate", { required: true })}
                        validation={errors.fromDate ? "error" : undefined}
                      />
                    </Datepicker>
                    {errors.fromDate && (
                      <Message className="mt-2" validation="error">
                        Vous devez renseigner une date
                      </Message>
                    )}
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label>Au</Label>
                    <Datepicker
                      value={watchToDate}
                      onChange={(d) => setValue("toDate", d)}
                      minValue={watchFromDate}
                      maxValue={watchFromDate ? new Date(`${watchFromDate.getFullYear()}-12-31`) : undefined}
                    >
                      <Input
                        {...register("toDate", {
                          required: true,
                          validate: (value) =>
                            value && value >= watchFromDate && value.getYear() === watchFromDate.getYear(),
                        })}
                        validation={errors.toDate ? "error" : undefined}
                      />
                    </Datepicker>
                    {errors.toDate?.type === "required" && (
                      <Message className="mt-2" validation="error">
                        Vous devez renseigner une date
                      </Message>
                    )}
                    {errors.toDate?.type === "validate" && (
                      <Message className="mt-2" validation="error">
                        La date de fin doit être après la date de début et dans la même année
                      </Message>
                    )}
                  </Field>
                </Col>
              </Row>
              <Field>
                <Label>(Éventuellement) pour le seul produit</Label>
                <Autocomplete
                  className="mt-2"
                  control={control}
                  name="productCodeLabel"
                  options={
                    [""].concat(
                      productOptions
                        ?.map((option: ProductOption) => `${option.product_code} - ${option.product_label}`)
                        .sort()
                    ) || []
                  }
                  rules={{ required: false }}
                  validation={errors.productCodeLabel ? "error" : undefined}
                />
              </Field>
            </Form>
          )}
          {apiError && (
            <Alert type="error" style={{ marginTop: 10 }}>
              <Title>{apiError}</Title>
              Veuillez corriger les données saisies.
              <br />
              Vérifiez en particulier que le dépôt de substitution n'est pas fermé sur la période sélectionnée.
            </Alert>
          )}
        </Body>
        <Footer>
          <FooterItem>
            <Button onClick={() => props.close(false)} isBasic>
              Annuler
            </Button>
          </FooterItem>
          <FooterItem>
            <Button isPrimary disabled={loading} type="submit">
              {buttonContent}
            </Button>
          </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
      </Form>
    </Modal>
  );
};

export default ClosedWarehouseDialog;
