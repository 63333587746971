import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { MD } from "@zendeskgarden/react-typography";

import Keycloak from "keycloak-js";
import { PropsWithChildren } from "react";

function KeycloakProvider(props: PropsWithChildren<{}>) {
  const keycloak = new (Keycloak as any)({
    url: process.env.REACT_APP_AUTH_URL,
    realm: "saveol-services",
    clientId: "preveol-app",
  });

  const initOptions = {
    pkceMethod: "S256",
    onLoad: "login-required",
  };

  const handleOnEvent = async (event: string, error: any) => {
    // console.log(event, error);
  };

  const loadingComponent = (
    <Grid>
      <Row alignItems="center" style={{ minHeight: "20em" }}>
        <Col textAlign="center">
          <MD>Chargement en cours...</MD>
        </Col>
      </Row>
    </Grid>
  );

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={loadingComponent}
      onEvent={(event, error) => handleOnEvent(event, error)}
    >
      {props.children}
    </ReactKeycloakProvider>
  );
}

export default KeycloakProvider;
