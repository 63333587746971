import { GridOptions, GridReadyEvent } from "ag-grid-community";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const BudgetForecastInputGrid = ({
  forecast,
  onGridReady,
}: {
  forecast: Forecast;
  onGridReady?(event: GridReadyEvent): void;
}) => {
  const baseUrl = `/sales/input/week/${forecast.id}`;

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "component_family_label", headerName: "Filière d'apport", filter: "agTextColumnFilter" },
      { field: "component_family_code", headerName: "Code filière", filter: true, minWidth: 80 },
      {
        field: "week",
        headerName: "Semaine",
        filter: "agNumberColumnFilter",
        cellStyle: { fontWeight: "bold", color: "#1f73b7", textAlign: "center" },
        maxWidth: 80,
        valueFormatter: numberValueFormatter({
          minimumIntegerDigits: 2,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      },
      {
        field: "budget_input_quantity_kg",
        headerName: "Apports budget",
        filter: false,
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "input_transfer_quantity_kg",
        headerName: "Transferts",
        filter: false,
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "input_quantity_kg",
        headerName: "Apports nets",
        filter: false,
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
    ],
  };

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions} onGridReady={onGridReady} />;
};

export default BudgetForecastInputGrid;
