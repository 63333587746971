import { useRef, useState } from "react";

import { GetContextMenuItemsParams, GridApi, GridOptions, MenuItemDef, ValueFormatterParams } from "ag-grid-community";

import { Span } from "@zendeskgarden/react-typography";
import { ReactComponent as ValidationIcon } from "@zendeskgarden/svg-icons/src/16/check-box-fill.svg";

import frameworkComponents from "../../components/AgGrid";
import ConfirmDialog from "../../components/ConfirmDialog";
import ServerSideGrid from "../../components/AgGrid/ServerSideGrid";
import { dateValueFormatter } from "../../utils/helpers";
import { useApiInstance } from "../../hooks/useApi";
import useToast from "../../hooks/useToast";

const BLUE = "#1f73b7";

const CheckboxRenderer = (props: any) => {
  return props.data.acknowledged ? (
    <Span hue={BLUE}>
      <ValidationIcon />
    </Span>
  ) : (
    ""
  );
};

const QualityGrid = () => {
  const api = useApiInstance();
  const { addToast } = useToast();
  const gridApi = useRef<GridApi>();
  const baseUrl = `/anomaly`;
  const [updateDialog, setUpdateDialog] = useState<any[] | undefined>(undefined);

  const onUpdateDialogClose = (success: boolean) => {
    if (updateDialog && updateDialog.length === 1) {
      api
        .put(`anomaly/${updateDialog[0].id}/acknowledge`, {})
        .then((response) => {
          if (success) gridApi.current?.refreshServerSideStore({});
          addToast("Aquittement", `L'anomalie est acquittée.`, "success", "top");
        })
        .catch((error) => {
          addToast("Aquittement", `L'anomalie n'a pas pu être acquittée.`, "error", "top");
        });
    }
    setUpdateDialog(undefined);
  };

  const typeFormatter = (params: ValueFormatterParams) => {
    if (params.data) {
      return params.data.type === "ANALYZER" ? "Analyse" : "Traitement";
    }
    if (params.value) {
      return params.value === "ANALYZER" ? "Analyse" : "Traitement";
    }
    return "";
  };

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "id", headerName: "Code", filter: "agNumberColumnFilter", maxWidth: 100 },
      {
        field: "type",
        headerName: "Type",
        filter: true,
        filterParams: {
          valueFormatter: typeFormatter,
        },
        valueFormatter: typeFormatter,
        maxWidth: 100,
      },
      { field: "item", headerName: "Item", filter: true, maxWidth: 70 },
      { field: "object", headerName: "Objet", filter: true, maxWidth: 90 },
      { field: "description", headerName: "Description", filter: false },
      { field: "forecast_id", headerName: "Prévision", filter: true, maxWidth: 100 },
      { field: "datasource", headerName: "Table", filter: true, maxWidth: 150 },
      {
        field: "acknowledged",
        headerName: "Acquittée",
        filter: true,
        cellRenderer: "checkboxRenderer",
        maxWidth: 100,
      },
      {
        field: "updated",
        headerName: "Mise à jour",
        filter: "agDateColumnFilter",
        valueFormatter: dateValueFormatter("DD/MM/YYYY HH:mm"),
        maxWidth: 140,
      },
    ],
    frameworkComponents: {
      ...frameworkComponents,
      checkboxRenderer: CheckboxRenderer,
    },
  };

  return (
    <>
      <ServerSideGrid
        baseUrl={baseUrl}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
        getContextMenuItems={(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
          const { api } = params;
          return [
            {
              name: "Acquitter l'anomalie",
              action: () => setUpdateDialog(api?.getSelectedRows()),
              disabled: !api.getSelectedRows().length,
              cssClasses: ["text-blue-600", "font-bold"],
              tooltip: "Marquer l'anomalie comme traitée",
            },
            "separator",
            "copy",
            "copyWithHeaders",
            "paste",
            "separator",
            "export",
          ];
        }}
      />
      {updateDialog && (
        <ConfirmDialog onCancel={() => setUpdateDialog(undefined)} onValidate={() => onUpdateDialogClose(true)}>
          Êtes-vous sûr d'acquitter l'anomalie ?
        </ConfirmDialog>
      )}
    </>
  );
};

export default QualityGrid;
