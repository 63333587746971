import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Alert, Title, Well } from "@zendeskgarden/react-notifications";

import { Autocomplete, Datepicker, Form, Multiselect } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { FamilyOption } from "../../../interfaces";

type ProductOption = {
  product_code: string;
  product_label: string;
};
type ProductLineOption = {
  product_line_code: string;
  product_line_label: string;
};
type CustomerSubtypeOption = {
  customer_subtype_code: string;
  customer_subtype_label: string;
};
type CustomerOption = {
  customer_code: string;
  customer_name: string;
};

const ActivityMatchingCreateDialog = (props: {
  close: Function;
  id?: string;
  productCode?: string;
  productLabel?: string;
  productCodeMatch?: string[];
  customerSubtypeCode?: string[];
  customerCode?: string[];
  salesQuantity?: number;
  matchingStart?: Date;
  matchingEnd?: Date;
  comment?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const { addToast } = useToast();
  const api = useApiInstance();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const watchFamilyLabel = watch("familyLabel");
  const watchProductLineLabel = watch("productLineLabel");
  const watchMatchingStart = watch("matchingStart", props.matchingStart);
  const watchMatchingEnd = watch("matchingEnd", props.matchingEnd);
  const watchCustomerSubtypeCodeLabel = watch("customerSubtypeCodeLabel", props.customerSubtypeCode);
  const watchCustomerCodeName = watch("customerCodeName", props.customerCode);

  const [{ data: familyOptions }] = useApiGet("/datasource/family/values/family_code/family_label");
  const [{ data: initProductLineOptions }] = useApiGet(
    "/datasource/product/values/product_line_code/product_line_label"
  );
  const [{ data: initProductOptions }] = useApiGet("/datasource/product/values/product_code/product_label");
  const [{ data: customerOptions }] = useApiGet("/datasource/customer/values/customer_code/customer_name");
  const [{ data: customerSubtypeOptions }] = useApiGet(
    "/datasource/customer/values/customer_subtype_code/customer_subtype_label"
  );
  const [productLineOptions, setProductLineOptions] = useState(initProductLineOptions);
  const [productOptions, setProductOptions] = useState(initProductOptions);

  useEffect(() => {
    let body = {
      family_label: watchFamilyLabel ? watchFamilyLabel : "%",
      product_line_label: watchProductLineLabel ? watchProductLineLabel : "%",
    };
    api
      .post(`/activity/matching/product/values/product_line_code/product_line_label`, body)
      .then((req) => setProductLineOptions(req.data))
      .catch(() => {
        addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
      });
    api
      .post(`/activity/matching/product/values/product_code/product_label`, body)
      .then((req) => setProductOptions(req.data))
      .catch(() => {
        addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
      });
  }, [watchFamilyLabel, watchProductLineLabel]);

  useEffect(() => {
    if (props.id) {
      api
        .get(`/activity/matching/${props.id}`)
        .then((req) => {
          setValue("matchingStart", new Date(req.data.matchingStart));
          setValue("matchingEnd", new Date(req.data.matchingEnd));
          setValue("comment", req.data.comment);
        })
        .catch(() => {
          addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
        });
    } else {
      if (props.matchingStart) {
        setValue("matchingStart", new Date(props.matchingStart));
      }
      if (props.matchingEnd) {
        setValue("matchingEnd", new Date(props.matchingEnd));
      }
    }
  }, []);

  const matchingPayload = (data: any) => {
    let returnedProductCode = props.productCode;
    if (!props.id) {
      returnedProductCode = data.productCodeLabel
        ? initProductOptions.find(
            (option: ProductOption) => `${option.product_code} - ${option.product_label}` === data.productCodeLabel
          ).product_code
        : null;
    }
    return {
      id: props.id ?? 0,
      productCode: returnedProductCode,
      productCodeMatch: data.productCodeLabelMatch.map((productCodeLabel: string) =>
        productCodeLabel
          ? initProductOptions.find(
              (option: ProductOption) => `${option.product_code} - ${option.product_label}` === productCodeLabel
            ).product_code
          : null
      ),
      customerSubtypeCode: data.customerSubtypeCodeLabel.map((customerSubtypeCodeLabel: string) =>
        customerSubtypeCodeLabel
          ? customerSubtypeOptions.find(
              (option: CustomerSubtypeOption) =>
                `${option.customer_subtype_code} - ${option.customer_subtype_label}` === customerSubtypeCodeLabel
            ).customer_subtype_code
          : null
      ),
      customerCode: data.customerCodeName.map((customerCodeName: string) =>
        customerCodeName
          ? customerOptions.find(
              (option: CustomerOption) => `${option.customer_code} - ${option.customer_name}` === customerCodeName
            ).customer_code
          : null
      ),
      matchingStart: data.matchingStart ? moment(data.matchingStart).format("YYYY-MM-DD") : null,
      matchingEnd: data.matchingEnd ? moment(data.matchingEnd).format("YYYY-MM-DD") : null,
      salesQuantity: data.salesQuantity ? data.salesQuantity : null,
      comment: data.comment,
    };
  };

  const addActivityMatching = (data: any) => {
    setApiError("");
    setLoading(true);
    api
      .post(`activity/matching`, matchingPayload(data))
      .then((response) => {
        addToast("Création d'alignement", `L'alignement #${response.data.id} a été créé`, "success", "top");
        props.close(true);
      })
      .catch((error) => {
        setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
        setLoading(false);
      });
  };

  const updateActivityMatching = (data: any) => {
    setApiError("");
    setLoading(true);
    api
      .put(`activity/matching/${props.id}`, matchingPayload(data))
      .then(() => {
        addToast("Alignement mis à jour", `L'alignement #${props.id} a été mis à jour`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à jour de la prévision", "error", "top");
        setLoading(false);
      });
  };

  const handleFormSubmit = (data: any) => {
    if (!props.id) {
      addActivityMatching(data);
    } else {
      updateActivityMatching(data);
    }
  };

  return (
    <Modal isLarge onClose={() => props.close()}>
      <ModalHeader>
        {props.id === undefined
          ? "Créer un nouvel alignement"
          : `Modifier l'alignement du produit ${props.productCode}`}
      </ModalHeader>
      <Body>
        {familyOptions && productLineOptions && productOptions && customerSubtypeOptions && customerOptions && (
          <Form id="dialog_form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Well isRecessed style={{ padding: 8 }}>
              <Title isRegular>Filtres de sélection des produits</Title>
              <Row>
                <Col>
                  <Field>
                    <Label>Filière</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="familyLabel"
                      options={
                        [""].concat(familyOptions?.map((option: FamilyOption) => `${option.family_label}`).sort()) || []
                      }
                      rules={{ required: false }}
                      value={watchFamilyLabel}
                    />
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label>Conditionnement</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="productLineLabel"
                      options={
                        [""].concat(
                          productLineOptions?.map((option: ProductLineOption) => `${option.product_line_label}`).sort()
                        ) || []
                      }
                      rules={{ required: false }}
                      value={watchProductLineLabel}
                    />
                  </Field>
                </Col>
              </Row>
            </Well>
            <Field>
              <Label>Produit concerné</Label>
              <Autocomplete
                className="mt-2"
                control={control}
                name="productCodeLabel"
                options={
                  [""].concat(
                    productOptions
                      ?.map((option: ProductOption) => `${option.product_code} - ${option.product_label}`)
                      .sort()
                  ) || []
                }
                rules={{ required: true }}
                validation={errors.productCodeLabel ? "error" : undefined}
                value={props.productCode ? `${props.productCode} - ${props.productLabel}` : ""}
                disabled={props.productCode !== undefined || !!props.id}
              />
              {errors.productCodeLabel && (
                <Message className="mt-2" validation="error">
                  Vous devez choisir un code produit
                </Message>
              )}
            </Field>
            <Row>
              <Col size={watchCustomerCodeName?.length > 0 ? 3 : watchCustomerSubtypeCodeLabel?.length > 0 ? 9 : 6}>
                <Field>
                  <Label>Enseigne(s)</Label>
                  <Multiselect
                    className="mt-2"
                    control={control}
                    name="customerSubtypeCodeLabel"
                    options={
                      [""].concat(
                        customerSubtypeOptions
                          ?.map(
                            (option: CustomerSubtypeOption) =>
                              `${option.customer_subtype_code} - ${option.customer_subtype_label}`
                          )
                          .sort()
                      ) || []
                    }
                    rules={{ required: false }}
                    value={props.customerSubtypeCode?.map((customerSubtypeCode: string) => {
                      let customerSubtypeLabel = customerSubtypeOptions?.find(
                        (option: CustomerSubtypeOption) => option.customer_subtype_code === customerSubtypeCode
                      ).customer_subtype_label;
                      return `${customerSubtypeCode} - ${customerSubtypeLabel}`;
                    })}
                    disabled={watchCustomerCodeName?.length > 0}
                  />
                </Field>
              </Col>
              <Col size={watchCustomerSubtypeCodeLabel?.length > 0 ? 3 : watchCustomerCodeName?.length > 0 ? 9 : 6}>
                <Field>
                  <Label>Client(s)</Label>
                  <Multiselect
                    className="mt-2"
                    control={control}
                    name="customerCodeName"
                    options={
                      [""].concat(
                        customerOptions
                          ?.map((option: CustomerOption) => `${option.customer_code} - ${option.customer_name}`)
                          .sort()
                      ) || []
                    }
                    rules={{ required: false }}
                    value={props.customerCode?.map((customerCode: string) => {
                      let customerName = customerOptions?.find(
                        (option: CustomerOption) => option.customer_code === customerCode
                      ).customer_label;
                      return `${customerCode} - ${customerName}`;
                    })}
                    disabled={watchCustomerSubtypeCodeLabel?.length > 0}
                  />
                </Field>
              </Col>
            </Row>

            <Field>
              <Label>Produit(s) de référence</Label>
              <Multiselect
                className="mt-2"
                control={control}
                name="productCodeLabelMatch"
                options={
                  [""].concat(
                    productOptions
                      ?.map((option: ProductOption) => `${option.product_code} - ${option.product_label}`)
                      .sort()
                  ) || []
                }
                rules={{ required: true }}
                validation={errors.productCodeLabelMatch ? "error" : undefined}
                value={props.productCodeMatch?.map((productCode: string) => {
                  let productLabel = initProductOptions?.find(
                    (option: ProductOption) => option.product_code === productCode
                  ).product_label;
                  return `${productCode} - ${productLabel}`;
                })}
              />
              {errors.productCodeLabelMatch && (
                <Message className="mt-2" validation="error">
                  Vous devez choisir un ou plusieurs codes produit de référence
                </Message>
              )}
            </Field>

            <Field>
              <Label>Volume de vente (kg)</Label>
              <Input
                type="number"
                step="any"
                {...register("salesQuantity", { required: false, value: props.salesQuantity })}
              />
            </Field>

            <Row>
              <Col>
                <Field>
                  <Label>Date début</Label>
                  <Datepicker
                    value={watchMatchingStart}
                    onChange={(d) => setValue("matchingStart", d)}
                    maxValue={watchMatchingEnd}
                  >
                    <Input
                      {...register("matchingStart", { required: true })}
                      validation={errors.matchingStart ? "error" : undefined}
                    />
                  </Datepicker>
                  {errors.matchingStart && (
                    <Message className="mt-2" validation="error">
                      Vous devez renseigner une date de début
                    </Message>
                  )}
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Date fin</Label>
                  <Datepicker
                    value={watchMatchingEnd}
                    onChange={(d) => setValue("matchingEnd", d)}
                    minValue={watchMatchingStart}
                  >
                    <Input
                      {...register("matchingEnd", { required: true })}
                      validation={errors.matchingEnd ? "error" : undefined}
                    />
                  </Datepicker>
                  {errors.matchingEnd?.type === "required" && (
                    <Message className="mt-2" validation="error">
                      Vous devez renseigner une date de fin
                    </Message>
                  )}
                </Field>
              </Col>
            </Row>

            <Field>
              <Label>Commentaire</Label>
              <Input {...register("comment", { value: props.comment })} />
            </Field>
          </Form>
        )}
        {apiError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{apiError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {loading ? "Création en cours..." : props.id ? "Modifier l'alignement" : "Créer l'alignement"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default ActivityMatchingCreateDialog;
