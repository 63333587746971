import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, SM } from "@zendeskgarden/react-typography";

import { Autocomplete, Form } from "../../../components/Form";
import LoadingModal from "../../../components/LoadingModal";
import useToast from "../../../hooks/useToast";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import { WeeklyForecast } from "../../../interfaces";

const DAYS = ["lun", "mar", "mer", "jeu", "ven", "sam"];

const ForecastResultOverwriteDialog = (props: {
  forecast: WeeklyForecast;
  familyCode: string;
  data: { [key: string]: any };
  node: { [key: string]: any };
  products: { [key: string]: string };
  unit: string;
  close: (success: boolean) => void;
}) => {
  const api = useApiInstance();
  const { addToast } = useToast();
  const { forecast, familyCode, data, node, products, unit, close } = props;
  const [loading, setLoading] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [{ data: melimeloFamilies }] = useApiGet("/datasource/melimelo");

  const handleFormSubmit = (formData: any) => {
    setLoading(true);
    let detail: Array<{ [key: string]: any }> = [];
    let balancing_product_code = "";
    if (data.day === "total") {
      // total overwrite
      // convert original and initial values from 'colis' to 'kg' if necessary
      if (unit === "colis") {
        data.original = data.original * parseFloat(data.weight_kg);
        data.initial = data.initial ? data.initial * parseFloat(data.weight_kg) : null;
      }
      // convert overwrite input from 'colis' to 'kg'
      // except if null as this value has a reset purpose
      let overwrite = formData.overwrite;
      if (overwrite) {
        overwrite = formData.overwrite * parseFloat(data.weight_kg);
      }
      // add week detail
      DAYS.forEach((weekDay, index) => {
        detail[index] = {
          weekDay: weekDay,
          forecast: node[`p_${weekDay}`] * (unit === "colis" ? parseFloat(data.weight_kg) : 1),
          original: node[`_${weekDay}`] * (unit === "colis" ? parseFloat(data.weight_kg) : 1),
          initial:
            unit === "colis" && node[`__${weekDay}`]
              ? node[`__${weekDay}`] * parseFloat(data.weight_kg)
              : node[`__${weekDay}`],
        };
      });
      api
        .post(`/weekly/forecast/${forecast.id}/overwrite/total`, { balancing_product_code, ...data, overwrite, detail })
        .then(() => {
          close(true);
        })
        .catch(() => {
          addToast("Erreur", "Erreur à la mise à jour de la valeur", "error", "top");
          setLoading(false);
        });
    } else {
      // week day overwrite
      // convert original and initial values from 'colis' to 'kg' if necessary
      if (unit === "colis") {
        data.original = data.original * parseFloat(data.weight_kg);
        data.initial = data.initial ? data.initial * parseFloat(data.weight_kg) : null;
      }
      // convert overwrite input from 'colis' to 'kg'
      // except if null as this value has a reset purpose
      if (formData.overwrite) {
        formData.overwrite = formData.overwrite * parseFloat(data.weight_kg);
      }
      // balancing products
      if (formData.productCodeLabel && products !== undefined) {
        balancing_product_code =
          Object.keys(products).find((code: string) => `${code} - ${products[code]}` === formData.productCodeLabel) ??
          "";
      }
      api
        .post(`/weekly/forecast/${forecast.id}/overwrite/day`, { balancing_product_code, ...data, ...formData })
        .then(() => {
          close(true);
        })
        .catch(() => {
          addToast("Erreur", "Erreur à la mise à jour de la valeur", "error", "top");
          setLoading(false);
        });
    }
  };

  const quantityInParcel = (quantity: string) => {
    const rowQuantity = parseFloat(quantity);
    // form data always expressed in 'colis'
    if (unit === "kg" && rowQuantity) {
      return rowQuantity / parseFloat(data.weight_kg);
    }
    return rowQuantity;
  };

  const levelInfo = () => {
    let subLabel = data.shipping_week ? `S${data.shipping_week}` : "";
    subLabel += data.order_type ? ` - ${data.order_type}` : "";
    subLabel += data.shipping_warehouse_code ? ` - ${data.shipping_warehouse_code}` : "";
    subLabel += data.customer_subtype_code ? ` - ${data.customer_subtype_code}` : "";
    subLabel += data.delivered_customer_code ? ` - ${data.delivered_customer_code}` : "";
    subLabel += data.delivered_customer_name ? ` (${data.delivered_customer_name})` : "";
    return subLabel;
  };

  const productOptions: string[] = [""];
  const codes = Object.keys(products);
  codes.forEach((code) => productOptions.push(`${code} - ${products[code]}`));
  productOptions.sort();

  return (
    <LoadingModal loading={loading} loadingMessage="Surcharge en cours..." onClose={() => props.close(false)}>
      <ModalHeader>
        <Paragraph>Surcharger une quantité pour le produit {data.product_code}</Paragraph>
        <SM isMonospace>{levelInfo()}</SM>
      </ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(handleFormSubmit)}>
          <Row>
            <Col>
              <Field>
                <Label>Quantité calculée (colis)</Label>
                <Input type="number" value={quantityInParcel(data.original).toFixed(2)} readOnly={true} step="0.01" />
              </Field>
            </Col>
            <Col>
              <Field>
                <Label>Quantité surchargée (colis)</Label>
                <Input
                  type="number"
                  step="0.01"
                  autoFocus={true}
                  {...register("overwrite", { value: quantityInParcel(data.overwrite).toFixed(2), required: false })}
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col sm={6}></Col>
            <Col sm={6}>
              <Field>
                <Label>Quantité initiale (colis)</Label>
                <Input type="number" value={quantityInParcel(data.initial).toFixed(2)} readOnly={true} step="0.01" />
              </Field>
            </Col>
          </Row>
          {melimeloFamilies && !melimeloFamilies.includes(familyCode) && data.day !== "total" && (
            <Row>
              <Col>
                <Field>
                  <Label>Produit d'équilibrage</Label>
                  <Autocomplete
                    className="mt-2"
                    control={control}
                    name="productCodeLabel"
                    options={productOptions}
                    rules={{ required: false }}
                    value={""}
                  />
                </Field>
              </Col>
            </Row>
          )}
        </Form>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary type="submit" form="dialog_form">
            Surcharger la valeur
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default ForecastResultOverwriteDialog;
