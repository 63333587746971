import { Link } from "react-router-dom";
import Moment from "react-moment";

import { Body as ChromeBody, HeaderItemWrapper, Content } from "@zendeskgarden/react-chrome";
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from "@zendeskgarden/react-tables";
import { Code, Span } from "@zendeskgarden/react-typography";
import {
  Modal,
  Header as ModalHeader,
  Body as ModalBody,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import { useState } from "react";
import { Button } from "@zendeskgarden/react-buttons";
import Header from "../../components/Header";
import { PaddedMain } from "../../components/Styled";
import { useApiGet, useApiInstance } from "../../hooks/useApi";
import { Datasource } from "../../interfaces";
import UpdateTag from "../../components/UpdateTag";

const DatasourceList = () => {
  const [{ data: datasources }] = useApiGet("/datasource");
  const [synchroDialog, setSynchroDialog] = useState(false);
  const api = useApiInstance();

  const synchronize = (name?: string) => {
    const url = name ? `/jobs/talend/import/run/${name}` : "/jobs/talend/import/run";
    api.get(url).then((res) => {
      setSynchroDialog(false);
    });
  };

  return (
    <ChromeBody>
      <Header helmetTitle="Données">
        <HeaderItemWrapper maxX>
          <Span>Données</Span>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          <Button isPrimary size="small" onClick={() => setSynchroDialog(true)}>
            Lancer une synchronisation
          </Button>
        </HeaderItemWrapper>
      </Header>
      <Content>
        <PaddedMain>
          {datasources && (
            <Table>
              <Head>
                <HeaderRow>
                  <HeaderCell width={200}>Nom</HeaderCell>
                  <HeaderCell width={200}>Table</HeaderCell>
                  <HeaderCell>Description</HeaderCell>
                  <HeaderCell width={240}>Dernière mise à jour</HeaderCell>
                </HeaderRow>
              </Head>
              <Body>
                {datasources.map((datasource: Datasource) => (
                  <Row key={datasource.code}>
                    <Cell width={200}>
                      <Link to={`/datasource/${datasource.code}`}>{datasource.name}</Link>
                    </Cell>
                    <Cell width={200}>
                      <Code>{datasource.code}</Code>
                    </Cell>
                    <Cell>{datasource.description}</Cell>
                    <Cell width={240}>
                      {datasource.updated && (
                        <>
                          <UpdateTag updated={datasource.updated} />
                          <Moment fromNow withTitle titleFormat="D MMM YYYY à HH:mm">
                            {datasource.updated}
                          </Moment>
                        </>
                      )}
                    </Cell>
                  </Row>
                ))}
              </Body>
            </Table>
          )}
        </PaddedMain>
      </Content>

      {synchroDialog && (
        <Modal onClose={() => setSynchroDialog(false)}>
          <ModalHeader>Lancer une synchronisation</ModalHeader>
          <ModalBody>
            Une nouvelle synchronisation des sources de données va être lancée. Assurez-vous qu'aucun autre utilisateur
            n'est en train de travailler sur un scénario.
          </ModalBody>
          <Footer>
            <FooterItem>
              <Button onClick={() => setSynchroDialog(false)} isBasic>
                Annuler
              </Button>
            </FooterItem>
            <FooterItem>
              <Button
                isPrimary
                onClick={() => {
                  synchronize();
                }}
              >
                Lancer la synchronisation
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" />
        </Modal>
      )}
    </ChromeBody>
  );
};

export default DatasourceList;
