import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GridApi } from "ag-grid-community";

import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Button } from "@zendeskgarden/react-buttons";
import { Content, HeaderItemWrapper, Body as ChromeBody } from "@zendeskgarden/react-chrome";
import { Span } from "@zendeskgarden/react-typography";

import ActivityExclusionGrid from "./grids/ActivityExclusionGrid";
import ActivityExclusionCreateDialog from "./dialogs/ActivityExclusionCreateDialog";
import Header from "../../components/Header";

const ActivityExclusion = () => {
  const [newDialog, setNewDialog] = useState<boolean>(false);

  const gridApiExclusion = useRef<GridApi>();

  const onCreateDialogClose = (success: boolean) => {
    setNewDialog(false);
    if (success) {
      gridApiExclusion.current?.refreshServerSideStore({});
    }
  };

  return (
    <ChromeBody>
      <Header helmetTitle="Exclusions">
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/settings">Paramètres</Link>
            <Span>Exclusions</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          <Button isPrimary size="small" onClick={() => setNewDialog(true)}>
            Nouvelle exclusion
          </Button>
        </HeaderItemWrapper>
      </Header>
      <Content>
        <ActivityExclusionGrid gridApi={gridApiExclusion} />
      </Content>

      {newDialog && <ActivityExclusionCreateDialog close={onCreateDialogClose} />}
    </ChromeBody>
  );
};

export default ActivityExclusion;
