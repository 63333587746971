import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GridApi } from "ag-grid-community";
import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { Button } from "@zendeskgarden/react-buttons";
import { Body as ChromeBody, Content, HeaderItemWrapper } from "@zendeskgarden/react-chrome";
import { Span } from "@zendeskgarden/react-typography";

import LifecycleGrid from "./grids/LifecycleGrid";
import Header from "../../components/Header";
import LifecycleCreateDialog from "./dialogs/LifecycleCreateDialog";

const Lifecycle = () => {
  const [newDialog, setNewDialog] = useState<boolean>(false);
  const gridApi = useRef<GridApi>();

  const onCreateDialogClose = (success: boolean) => {
    setNewDialog(false);
    if (success) {
      gridApi.current?.refreshServerSideStore({});
    }
  };

  return (
    <ChromeBody>
      <Header helmetTitle="Cycle de vie des produits">
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/settings">Paramètres</Link>
            <Span>Cycle de vie des produits</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          <Button isPrimary size="small" onClick={() => setNewDialog(true)}>
            Nouveau produit
          </Button>
        </HeaderItemWrapper>
      </Header>
      <Content>
        <LifecycleGrid gridApi={gridApi} />
      </Content>

      {newDialog && <LifecycleCreateDialog close={onCreateDialogClose} />}
    </ChromeBody>
  );
};

export default Lifecycle;
