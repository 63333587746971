import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Paragraph, Span } from "@zendeskgarden/react-typography";
import { useState } from "react";

import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import { useApiInstance } from "../../../hooks/useApi";
import LoadingModal from "../../../components/LoadingModal";

const ForecastArchiveDialog = (props: { forecast: Forecast; close: Function }) => {
  const { addToast } = useToast();
  const api = useApiInstance();

  const [loading, setLoading] = useState(false);

  const archiveForecast = () => {
    const { forecast } = props;
    forecast.status = "ARCHIVED";
    setLoading(true);
    api
      .put(`forecast/${forecast.id}/archive`)
      .then(() => {
        addToast("Prévision archivée", `La prévision #${forecast.id} a été archivée`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", `Erreur à l'archivage de la prévision #${forecast.id}`, "error", "top");
        forecast.status = "VALIDATED";
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Archivage en cours..." onClose={() => props.close()}>
      <ModalHeader>Archiver la prévision budgétaire #{props.forecast.id}</ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous l'archivage de la prévision budgétaire : "<Span isBold>{props.forecast.label}</Span>" (#
          {props.forecast.id}) ?
        </Paragraph>
        <Paragraph>
          <Span hue="red">L'archivage du budget est définitif.</Span>
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => archiveForecast()}>
            Archiver la prévision
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default ForecastArchiveDialog;
