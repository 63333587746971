import { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Message } from "@zendeskgarden/react-forms";
import { Body, Close, Footer, FooterItem, Header as ModalHeader, Modal } from "@zendeskgarden/react-modals";

import { Alert, Title } from "@zendeskgarden/react-notifications";
import { Autocomplete, Form, Multiselect } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { BudgetForecast } from "../../../interfaces";

type CustomerSubtypeOption = {
  customer_subtype_code: string;
  customer_subtype_label: string;
};

const BudgetForecastSaleSwitchDialog = (props: { forecast: BudgetForecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: toSalesOptions }] = useApiGet("/datasource/customer/values/commercial_name");
  const [{ data: fromSalesOptions }] = useApiGet(`budget/forecast/${props.forecast.id}/switch/sale/options`);
  const [{ data: customerSubtypeOptions }] = useApiGet(
    "/datasource/customer/values/customer_subtype_code/customer_subtype_label"
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setApiError("");
    setLoading(true);
    const customerSubtypes =
      data.customerSubtypes?.map((value: string) => {
        return value
          ? customerSubtypeOptions.find(
              (option: CustomerSubtypeOption) =>
                `${option.customer_subtype_code} - ${option.customer_subtype_label}` === value
            ).customer_subtype_code
          : null;
      }) || [];
    api
      .post(`budget/forecast/${props.forecast.id}/switch/sale`, {
        ...data,
        customerSubtypes,
      })
      .then((response) => {
        addToast(
          "Substitution de commercial",
          `La substitution du commercial ${response.data.fromSaleName} a été effectuée.`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch((error) => {
        setApiError(error.response.status === 405 ? error.message : "Erreur serveur");
        setLoading(false);
      });
  };

  let buttonContent = "Substituer les commerciaux";
  if (loading) buttonContent = "Substitution en cours...";

  return (
    <Modal onClose={() => props.close(false)}>
      <ModalHeader>Substituer un commercial dans le budget</ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>Commercial à remplacer</Label>
            <Autocomplete
              className="mt-2"
              control={control}
              name="fromSaleName"
              options={fromSalesOptions?.sort() || []}
              rules={{ required: true }}
              validation={errors.fromSaleName ? "error" : undefined}
            />
            {errors.fromSaleName && (
              <Message className="mt-2" validation="error">
                Vous devez choisir un commercial à remplacer.
              </Message>
            )}
          </Field>
          <Field>
            <Label>Commercial de substitution</Label>
            <Autocomplete
              className="mt-2"
              control={control}
              name="toSaleName"
              options={toSalesOptions?.sort() || []}
              rules={{ required: true }}
              validation={errors.toSaleName ? "error" : undefined}
            />
            {errors.toSaleName && (
              <Message className="mt-2" validation="error">
                Vous devez choisir un commercial de remplacement.
              </Message>
            )}
          </Field>
          <Field>
            <Label>(Éventuellement) restreint aux enseignes</Label>
            <Multiselect
              className="mt-2"
              control={control}
              name="customerSubtypes"
              options={
                [""].concat(
                  customerSubtypeOptions
                    ?.map(
                      (option: CustomerSubtypeOption) =>
                        `${option.customer_subtype_code} - ${option.customer_subtype_label}`
                    )
                    .sort()
                ) || []
              }
              rules={{ required: false }}
            />
          </Field>
        </Form>
        {apiError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{apiError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {buttonContent}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default BudgetForecastSaleSwitchDialog;
