import { Link, Route, useParams, Routes } from "react-router-dom";

import { Body as ChromeBody, HeaderItemWrapper, Content, Main } from "@zendeskgarden/react-chrome";
import { Breadcrumb } from "@zendeskgarden/react-breadcrumbs";
import { MD, Span } from "@zendeskgarden/react-typography";

import BudgetForecastOverwrite from "./components/BudgetForecastOverwrite";
import BudgetForecastParams from "./components/BudgetForecastParams";
import BudgetForecastProposal from "./components/BudgetForecastProposal";
import BudgetForecastResultView from "./components/BudgetForecastResultView";

import ActivityView from "../activity/ActivityView";
import ForecastInputView from "../common/ForecastInputView";

import ForecastSubTypeTag from "../../components/ForecastSubTypeTag";
import Header from "../../components/Header";
import { RouteTabs, RouteTab, RouteTabPanel } from "../../components/RouteTabs";
import { CompactTabList } from "../../components/Styled";
import { useApiGet } from "../../hooks/useApi";

const BudgetForecastView = () => {
  const { id } = useParams();
  const [{ data: forecast }, refetch] = useApiGet(`/forecast/${id}`, {});

  const isReview = forecast && forecast.subType === "REVIEW";

  return (
    <ChromeBody>
      <Header helmetTitle={forecast && `Budget #${forecast.id}`}>
        <HeaderItemWrapper maxX>
          <Breadcrumb>
            <Link to="/budget">Prévisions budgétaires</Link>
            <Span>{forecast && `Budget #${forecast.id} - ${forecast.label}`}</Span>
          </Breadcrumb>
        </HeaderItemWrapper>
        <HeaderItemWrapper>
          {forecast && forecast.type === "BUDGET" && <ForecastSubTypeTag forecast={forecast}></ForecastSubTypeTag>}
        </HeaderItemWrapper>
      </Header>
      <Content>
        <Main>
          {forecast && forecast.type === "WEEKLY" && (
            <>
              <MD style={{ color: "red", marginLeft: 18, marginTop: 20 }} isBold>
                La prévision #{forecast.id} n'est pas une prévision budget !
              </MD>
              <MD style={{ color: "red", marginLeft: 18 }}>
                Retrouvez-la ici : <Link to={`/forecast/${forecast.id}`}>{forecast.label}</Link>
              </MD>
            </>
          )}
          {forecast && forecast.type === "BUDGET" && (
            <RouteTabs>
              <CompactTabList>
                <RouteTab to="">Vue générale</RouteTab>
                <RouteTab to="overwrite">Surcharge scénario</RouteTab>
                <RouteTab to="activity">Historique</RouteTab>
                <RouteTab to="input">Apports</RouteTab>
                <RouteTab to="proposal" disabled={!isReview && !forecast.generated}>
                  Proposition
                </RouteTab>
                <RouteTab to="validation" disabled={!isReview && !forecast.generated}>
                  Validation
                </RouteTab>
                {isReview && <RouteTab to="review">Révision</RouteTab>}
                <RouteTab to="result" disabled={!isReview && (!forecast.generated || !forecast.forecasted)}>
                  Prévision
                </RouteTab>
              </CompactTabList>
              <RouteTabPanel>
                <Routes>
                  <Route index element={<BudgetForecastParams forecast={forecast} refetch={refetch} />} />
                  <Route
                    path="overwrite/*"
                    element={<BudgetForecastOverwrite forecast={forecast} refetch={refetch} />}
                  />
                  <Route path="activity/*" element={<ActivityView forecast={forecast} refetch={refetch} />} />
                  <Route path="input/*" element={<ForecastInputView forecast={forecast} refetch={refetch} />} />
                  <Route
                    path="proposal/*"
                    element={<BudgetForecastProposal forecast={forecast} refetch={refetch} step={"proposal"} />}
                  />
                  <Route
                    path="validation/*"
                    element={<BudgetForecastProposal forecast={forecast} refetch={refetch} step={"validation"} />}
                  />
                  <Route
                    path="review/*"
                    element={<BudgetForecastProposal forecast={forecast} refetch={refetch} step={"review"} />}
                  />
                  <Route path="result/*" element={<BudgetForecastResultView forecast={forecast} refetch={refetch} />} />
                </Routes>
              </RouteTabPanel>
            </RouteTabs>
          )}
        </Main>
      </Content>
    </ChromeBody>
  );
};

export default BudgetForecastView;
