import { useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Toggle, Message } from "@zendeskgarden/react-forms";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Alert, Title } from "@zendeskgarden/react-notifications";

import { Form, Multiselect } from "../../../components/Form";
import LoadingModal from "../../../components/LoadingModal";
import Autocomplete from "../../../components/Form/Autocomplete";
import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { WeeklyForecast } from "../../../interfaces";

const ForecastCreateDialog = (props: { close: Function; forecast?: WeeklyForecast; weeks?: string[] }) => {
  const [loading, setLoading] = useState(false);
  const splitOptions = ["ACTIVITY", "FORECAST"];
  const splitOptionsFr = ["Historique", "Précédente prévision"];

  const { addToast } = useToast();
  const api = useApiInstance();
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const watchWeek = watch("period", props.forecast ? props.forecast.period : moment().add(7, "days").format("W"));

  const addForecast = (data: any) => {
    setError("");
    setLoading(true);
    api
      .post(`forecast`, {
        ...data,
        type: "WEEKLY",
        splitType: data.splitType === splitOptionsFr[0] ? splitOptions[0] : splitOptions[1],
      })
      .then((response) => {
        const forecast: WeeklyForecast = response.data;
        addToast("Prévision créée", `La prévision #${forecast.id} a été créée`, "success", "top");
        props.close(true);
      })
      .catch((e) => {
        setError(`Erreur à la création de la prévision. ${e.response.data.message}`);
        setLoading(false);
      });
  };

  const updateForecast = (data: any) => {
    setLoading(true);
    let payload = {
      ...props.forecast,
      label: data.label,
      splitType: data.splitType === splitOptionsFr[0] ? splitOptions[0] : splitOptions[1],
      inputStockOn: data.inputStockOn,
      activityMatchingOn: data.activityMatchingOn,
      activityExclusionOn: data.activityExclusionOn,
      activityOverwriteOn: data.activityOverwriteOn,
      weeks: data.weeks,
    };
    api
      .put(`forecast/${props.forecast?.id}`, payload)
      .then(() => {
        console.log("update budget forecast");
        addToast("Prévision mise à jour", `La prévision #${props.forecast?.id} a été mise à jour`, "success", "top");
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à jour de la prévision", "error", "top");
        setLoading(false);
      });
  };

  const handleFormSubmit = (data: any) => {
    if (props.forecast) {
      updateForecast(data);
    } else {
      addForecast(data);
    }
  };

  const getOptions = (week: string) => {
    if (!week) {
      week = moment().add(7, "days").format("W");
    }
    const options = [];
    for (let i = 1; i <= 10; i++) {
      const number = ((+week + 53 - i) % 53).toString();
      if (number === "0") {
        options.push("53");
      } else {
        options.push(((+week + 53 - i) % 53).toString());
      }
    }
    return options;
  };

  return (
    <LoadingModal
      loading={loading}
      loadingMessage={props.forecast ? "Modification en cours..." : "Création en cours..."}
      onClose={() => props.close(false)}
    >
      <ModalHeader>{props.forecast ? "Modifier une" : "Créer une nouvelle"} prévision hebdo</ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(handleFormSubmit)}>
          <Field>
            <Label>Libellé</Label>
            <Input
              defaultValue={props.forecast ? props.forecast.label : `Semaine ${moment().add(7, "days").format("W")}`}
              {...register("label", {
                required: true,
              })}
              validation={errors.label ? "error" : undefined}
            />
            {errors.label && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner un libellé
              </Message>
            )}
          </Field>
          <Field>
            <Label>Année cible</Label>
            <Input
              type="number"
              defaultValue={props.forecast ? props.forecast.year : new Date().getFullYear()}
              {...register("year", {
                value: props.forecast?.year,
                required: true,
              })}
              validation={errors.year ? "error" : undefined}
              disabled={props.forecast !== undefined}
            />
            {errors.year && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner une année
              </Message>
            )}
          </Field>
          <Field>
            <Label>Semaine cible</Label>
            <Input
              type="number"
              min="1"
              max="53"
              defaultValue={props.forecast ? props.forecast.period : moment().add(7, "days").format("W")}
              {...register("period", {
                required: true,
                value: props.forecast?.period,
              })}
              validation={errors.period ? "error" : undefined}
              disabled={props.forecast !== undefined}
            />
            {errors.period && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner une semaine cible
              </Message>
            )}
          </Field>
          <Field>
            <Label>Semaines de références</Label>
            <Multiselect
              className="mt-2"
              control={control}
              name="weeks"
              options={getOptions(watchWeek)}
              rules={{ required: true }}
              validation={errors.weeks ? "error" : undefined}
              disabled={!watchWeek}
              value={props.weeks}
            />
            {errors.weeks && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner une semaine de référence
              </Message>
            )}
          </Field>
          <Field>
            <Label>Type d'éclatement</Label>
            <Autocomplete
              className="mt-2"
              control={control}
              name="splitType"
              options={splitOptionsFr}
              rules={{ required: true }}
              validation={errors.splitType ? "error" : undefined}
              value={
                props.forecast
                  ? props.forecast.splitType === "ACTIVITY"
                    ? splitOptionsFr[0]
                    : splitOptionsFr[1]
                  : splitOptionsFr[0]
              }
            />
            {errors.splitType && (
              <Message className="mt-2" validation="error">
                Vous devez choisir un type d'éclatement
              </Message>
            )}
          </Field>
          <Field>
            <Toggle {...register("inputStockOn", { value: props.forecast ? props.forecast.inputStockOn : true })}>
              <Label>Activer les reports d'apports</Label>
            </Toggle>
          </Field>
          <Field>
            <Toggle
              {...register("activityMatchingOn", {
                value: props.forecast ? props.forecast.activityMatchingOn : true,
              })}
            >
              <Label>Activer les alignements</Label>
            </Toggle>
          </Field>
          <Field>
            <Toggle
              {...register("activityExclusionOn", {
                value: props.forecast ? props.forecast.activityExclusionOn : true,
              })}
            >
              <Label>Activer les exclusions</Label>
            </Toggle>
          </Field>
          <Field>
            <Toggle
              {...register("activityOverwriteOn", {
                value: props.forecast ? props.forecast.activityOverwriteOn : true,
              })}
            >
              <Label>Activer les surcharges</Label>
            </Toggle>
          </Field>
          {/* <Field>
              <Label>Taux d'évolution des méli-mélos</Label>
              <Input
                type="number"
                step="any"
                {...register("melimeloTrend", {
                  value: props.forecast ? props.forecast.melimeloTrend : 1,
                  required: true,
                })}
              />
            </Field> */}
        </Form>
        {error && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{error}</Title>
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary type="submit" form="dialog_form">
            {props.forecast ? "Modifier la prévision" : "Créer la prévision"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default ForecastCreateDialog;
