import { useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";

import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { Forecast } from "../../../interfaces";
import LoadingModal from "../../../components/LoadingModal";
import { Paragraph, Span } from "@zendeskgarden/react-typography";

const BudgetForecastRecipeDialog = (props: { close: Function; forecast: Forecast }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const api = useApiInstance();

  const updateRecipe = () => {
    setLoading(true);
    api
      .post(`budget/forecast/${props.forecast.id}/recipe`)
      .then(() => {
        addToast(
          "Nomenclature mise à jour",
          `La nomenclature de la prévision budgétaire #${props.forecast.id} a été mise à jour`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch(() => {
        addToast("Erreur", "Erreur à la mise à joru de la nomenclature", "error", "top");
        setLoading(false);
      });
  };

  return (
    <LoadingModal loading={loading} loadingMessage="Mise à jour en cours..." onClose={() => props.close(false)}>
      <ModalHeader>Mettre à jour la nomenclature de la prévision budgétaire</ModalHeader>
      <Body>
        <Paragraph>
          Confirmez-vous la mise à jour de la nomenclature de la prévision budgétaire : "
          <Span isBold>{props.forecast.label}</Span>" (#{props.forecast.id}) ?
        </Paragraph>
        <Paragraph>
          <Span hue="blue">
            La dernière mise à jour de la nomenclature (telle que visible dans le menu "Données") remplacera celle
            intégrée à la prévision.
          </Span>
        </Paragraph>
        <Paragraph style={{ marginTop: "5px" }}>
          <Span hue="blue">
            Les prévisions annuelles seront conservées, seules les prévisions éclatées à la semaine sont recalculées.
          </Span>
        </Paragraph>
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={() => updateRecipe()}>
            Mettre à jour
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </LoadingModal>
  );
};

export default BudgetForecastRecipeDialog;
