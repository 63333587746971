import { PropsWithChildren } from "react";

import { Tag } from "@zendeskgarden/react-tags";
import { PALETTE } from "@zendeskgarden/react-theming";

import { statusValueFormatter } from "../utils/helpers";

export const ValidationStatusTag = ({ status }: PropsWithChildren<{ status: string }>) => {
  const hues: { [key: string]: string } = {
    NO_VALIDATION: PALETTE.orange[600],
    STRONG_VALIDATION: PALETTE.green[700],
    WEAK_VALIDATION: PALETTE.kale[500],
  };

  return (
    <Tag hue={hues[status]} size="small">
      {statusValueFormatter(status)}
    </Tag>
  );
};
