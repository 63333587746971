import { SortDirection } from "../interfaces";
import moment from "moment";
import { IAggFuncParams, ValueFormatterParams, ValueGetterParams, ValueParserParams } from "ag-grid-community";

/**
 * sortCallback
 */
export const sortCallback = (field: string, order: SortDirection | undefined) => {
  return (a: any, b: any) => {
    const aValue = a[field];
    const bValue = b[field];
    if (order) {
      if (aValue && bValue && aValue > bValue) {
        return order === "asc" ? 1 : -1;
      } else if (aValue && bValue && aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
    }
    return 0;
  };
};

export const toCamelCaseString = (key: string) => key.replace(/_([a-z])/g, (_, m) => m.toUpperCase());

export const toCamelCaseObject = (snakeCaseObject: Object) => {
  return Object.fromEntries(Object.entries(snakeCaseObject).map(([key, value]) => [toCamelCaseString(key), value]));
};

/**
 * date value getter/parser/formatter
 */

export const dateValueGetter = (field?: string, format?: string) => {
  return (params: ValueGetterParams) => {
    const key = field ? field : params.colDef.field!;
    return moment(params.data[key]).format(format ? format : "DD/MM/YYYY");
  };
};

export const dateValueParser = (format?: string) => {
  return (params: ValueParserParams) => {
    if (params.newValue === null || params.newValue === "") return null;
    return moment(params.newValue, format ? format : "DD/MM/YYYY").format("YYYY-MM-DD");
  };
};

export const dateValueFormatter = (format?: string) => {
  return (params: ValueFormatterParams) => {
    if (!params.value || params.value === "") {
      return "";
    }
    return moment(params.value).format(format ? format : "DD/MM/YYYY");
  };
};

/**
 * number value getter/parser/formatter
 */

export const numberValueFormatter = (options: Intl.NumberFormatOptions & { suffix?: string } = {}) => {
  return (params: ValueFormatterParams) => {
    if (params.value === null || params.value === "" || isNaN(params.value) || params.value === undefined) {
      return "";
    }

    const { suffix, ...formatOptions } = options;

    let str = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...formatOptions,
    }).format(params.value);

    if (suffix) {
      str = `${str} ${suffix}`;
    }

    return str;
  };
};

/**
 * currency value getter/parser/formatter
 */

export const currencyValueGetter = (field?: string) => {
  return (params: ValueGetterParams) => {
    const key = field ? field : params.colDef.field!;
    let value;
    if (params.node && params.node.group) {
      value = params.node.aggData[key];
    } else {
      value = params.data[key];
    }
    if (value === null) return null;
    return (value / 100).toFixed(2);
  };
};

export const currencyValueParser = () => {
  return (params: ValueParserParams) => {
    if (params.newValue === null || params.newValue === "") return null;
    return Math.round(parseFloat(params.newValue) * 100);
  };
};

export const currencyValueFormatter = (options?: Intl.NumberFormatOptions) => {
  return numberValueFormatter({
    style: "currency",
    currency: "EUR",
    ...options,
  });
};

export const currencyFilterParams = {
  numberParser: (value: number) => {
    return value == null ? null : Math.round(value * 100);
  },
  buttons: ["reset"],
};

export const statusValueFormatter = (status: string): string => {
  const labels: { [key: string]: string } = {
    NO_VALIDATION: "Non validé",
    STRONG_VALIDATION: "Validé explicitement",
    WEAK_VALIDATION: "Validé implicitement",
    NOT_EXPORTED: "Non exporté",
    EXPORTED: "Exporté",
    ERROR: "Export en erreur",
  };

  return labels[status] || "";
};

/**
 * other number value formatter (not for ag-grid)
 */

export const valueDisplay = (
  numberFormatter: Intl.NumberFormat,
  value: number | null,
  multiplier: number | null = null,
  unit: string | null = null
) => {
  if (value === null) return "-";
  if (multiplier) value = multiplier * value;
  if (unit) {
    return `${numberFormatter.format(value)} ${unit}`;
  } else {
    return `${numberFormatter.format(value)}`;
  }
};

export const weightedAverage = (mainField: string, weightField: string) => {
  return (params: IAggFuncParams) => {
    let sum = 0;
    let wSum = 0;
    params.values.forEach((value) => {
      if (value && value[weightField] && value[mainField]) {
        sum += value[weightField];
        wSum += value[weightField] * value[mainField];
      }
    });
    return {
      [mainField]: wSum && sum ? wSum / sum : undefined,
      [weightField]: sum ? sum : undefined,
      toString: () => (wSum && sum ? wSum / sum / 100.0 : undefined),
    };
  };
};
