import DatasourceGrid from "./DatasourceGrid";
import { Datasource, GetDetailRowDataParams } from "../../../interfaces";
import { GridOptions } from "ag-grid-community";
import aggridLocale from "../../../config/aggrid-locale.fr";
import { useApiInstance } from "../../../hooks/useApi";
import frameworkComponents from "../../../components/AgGrid";

const DatasourceMasterDetailGrid = ({ datasource }: { datasource: Datasource }) => {
  const api = useApiInstance();

  const detailCellRendererParams = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
      frameworkComponents,
      localeText: aggridLocale,
      defaultColDef: {
        flex: 1,
        sortable: true,
        resizable: true,
      },
      ...datasource.metadata!.masterDetail!.metadata,
    },
    getDetailRowData: (params: GetDetailRowDataParams) => {
      api.post(`/datasource/${datasource.code}/detail/rows`, params.data).then((res) => {
        params.successCallback(res.data);
      });
    },
  };

  const options: GridOptions = {
    masterDetail: true,
    detailCellRendererParams,
  };

  return <DatasourceGrid datasource={datasource} options={options} />;
};

export default DatasourceMasterDetailGrid;
