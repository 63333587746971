import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Body, Close, Footer, FooterItem, Header as ModalHeader, Modal } from "@zendeskgarden/react-modals";
import { Alert, Title, Well } from "@zendeskgarden/react-notifications";

import { Form, Autocomplete, Datepicker } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { FamilyOption } from "../../../interfaces";

type ProductOption = {
  product_code: string;
  product_label: string;
};
type SubtypeOption = {
  customer_subtype_code: string;
  customer_subtype_label: string;
};
type ProductLineOption = {
  product_line_code: string;
  product_line_label: string;
};

const LifecycleCreateDialog = (props: { close: Function }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: initProductOptions }] = useApiGet("/datasource/product/values/product_code");
  const [{ data: initProductLineOptions }] = useApiGet(
    "/datasource/product/values/product_line_code/product_line_label"
  );
  const [{ data: customerSubtypeOptions }] = useApiGet(
    "/datasource/customer/values/customer_subtype_code/customer_subtype_label"
  );
  const [{ data: familyOptions }] = useApiGet("/datasource/family/values/family_code/family_label");
  const [productOptions, setProductOptions] = useState(initProductOptions);
  const [productLineOptions, setProductLineOptions] = useState(initProductLineOptions);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const watchFamilyLabel = watch("familyLabel");
  const watchProductLineLabel = watch("productLineLabel");
  const watchSalesStart = watch("salesStart");
  const watchSalesEnd = watch("salesEnd");

  useEffect(() => {
    let body = {
      family_label: watchFamilyLabel ? watchFamilyLabel : "%",
      product_line_label: watchProductLineLabel ? watchProductLineLabel : "%",
    };
    api
      .post(`/activity/matching/product/values/product_line_code/product_line_label`, body)
      .then((req) => setProductLineOptions(req.data))
      .catch(() => {
        addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
      });
    api
      .post(`/activity/matching/product/values/product_code/product_label`, body)
      .then((req) => setProductOptions(req.data))
      .catch(() => {
        addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
      });
  }, [watchFamilyLabel, watchProductLineLabel]);

  const onSubmit = (data: any) => {
    setApiError("");
    setLoading(true);
    api
      .post(`lifecycle`, {
        productCode: data.productCode
          ? productOptions.find(
              (option: ProductOption) => `${option.product_code} - ${option.product_label}` === data.productCode
            ).product_code
          : null,
        customerSubtypeCode: data.customerSubtypeCode
          ? customerSubtypeOptions.find(
              (option: SubtypeOption) =>
                `${option.customer_subtype_code} - ${option.customer_subtype_label}` === data.customerSubtypeCode
            ).customer_subtype_code
          : null,
        salesStart: data.salesStart ? moment(data.salesStart).format("YYYY-MM-DD") : null,
        salesEnd: data.salesEnd ? moment(data.salesEnd).format("YYYY-MM-DD") : null,
        year: data.salesStart ? moment(data.salesStart).year() : moment(data.salesEnd).year(),
      })
      .then((response) => {
        addToast(
          "Création de dates de commercialisation",
          `Le cycle de vie du produit #${response.data.productCode} a été créé.`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch((error) => {
        setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
        setLoading(false);
      });
  };

  return (
    <Modal onClose={() => props.close(false)}>
      <ModalHeader>Créer des dates de commercialisation</ModalHeader>
      <Body>
        {familyOptions && productLineOptions && productOptions && (
          <Form id="dialog_form" onSubmit={handleSubmit(onSubmit)}>
            <Well isRecessed style={{ padding: 8 }}>
              <Title isRegular>Filtres de sélection des produits</Title>
              <Row>
                <Col>
                  <Field>
                    <Label>Filière</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="familyLabel"
                      options={
                        [""].concat(familyOptions?.map((option: FamilyOption) => `${option.family_label}`).sort()) || []
                      }
                      rules={{ required: false }}
                      value={watchFamilyLabel}
                    />
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label>Conditionnement</Label>
                    <Autocomplete
                      className="mt-2"
                      control={control}
                      name="productLineLabel"
                      options={
                        [""].concat(
                          productLineOptions?.map((option: ProductLineOption) => `${option.product_line_label}`).sort()
                        ) || []
                      }
                      rules={{ required: false }}
                      value={watchProductLineLabel}
                    />
                  </Field>
                </Col>
              </Row>
            </Well>
            <Field>
              <Label>Produit</Label>
              <Autocomplete
                className="mt-2"
                control={control}
                name="productCode"
                options={
                  [""].concat(
                    productOptions
                      ?.map((option: ProductOption) => `${option.product_code} - ${option.product_label}`)
                      .sort()
                  ) || []
                }
                rules={{ required: true }}
                validation={errors.productCode ? "error" : undefined}
              />
              {errors.productCode && (
                <Message className="mt-2" validation="error">
                  Vous devez choisir un code produit
                </Message>
              )}
            </Field>
            <Field>
              <Label>Enseigne</Label>
              <Autocomplete
                className="mt-2"
                control={control}
                name="customerSubtypeCode"
                options={
                  [""].concat(
                    customerSubtypeOptions
                      ?.map(
                        (option: SubtypeOption) => `${option.customer_subtype_code} - ${option.customer_subtype_label}`
                      )
                      .sort()
                  ) || []
                }
                rules={{ required: false }}
                validation={errors.customerSubtypeCode ? "error" : undefined}
              />
              {errors.customerSubtypeCode && (
                <Message className="mt-2" validation="error">
                  Vous devez choisir un code enseigne
                </Message>
              )}
            </Field>
            {/* <Field>
              <Label>Année</Label>
              <Input
                type="number"
                {...register("year", { value: new Date().getFullYear(), required: true })}
                validation={errors.year ? "error" : undefined}
              />
              {errors.year && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner une année
                </Message>
              )}
            </Field> */}
            <Field>
              <Label>Date début</Label>
              <Datepicker
                value={watchSalesStart}
                onChange={(d) => {
                  setValue("salesStart", d);
                }}
              >
                <Input
                  {...register("salesStart", { required: true })}
                  validation={errors.salesStart ? "error" : undefined}
                />
              </Datepicker>
              {errors.salesStart && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner une date de début
                </Message>
              )}
            </Field>
            <Field>
              <Label>Date fin</Label>
              <Datepicker
                value={watchSalesEnd}
                onChange={(d) => {
                  setValue("salesEnd", d);
                }}
              >
                <Input
                  {...register("salesEnd", { required: true, validate: (value) => value > watchSalesStart })}
                  validation={errors.salesEnd ? "error" : undefined}
                />
              </Datepicker>
              {errors.salesEnd?.type === "required" && (
                <Message className="mt-2" validation="error">
                  Vous devez renseigner une date de fin
                </Message>
              )}
              {errors.salesEnd?.type === "validate" && (
                <Message className="mt-2" validation="error">
                  La date de fin doit être ultérieure à la date de début
                </Message>
              )}
            </Field>
          </Form>
        )}
        {apiError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{apiError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {loading ? "Création en cours..." : "Créer les dates"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default LifecycleCreateDialog;
