import { IModalProps } from "@zendeskgarden/react-modals/dist/typings/elements/Modal";
import React, { PropsWithChildren, Ref } from "react";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";

type ConfirmDialogProps = PropsWithChildren<
  {
    title?: string;
    onCancel: () => void;
    onValidate: () => void;
  } & IModalProps &
    React.HTMLAttributes<HTMLDivElement>
>;

/**
 * A simple confirm modal
 *
 * @param props
 */

const ConfirmDialog = React.forwardRef((props: ConfirmDialogProps, ref?: Ref<HTMLDivElement>) => {
  const { title, onCancel, onValidate, children, ...modalProps } = props;
  return (
    <Modal ref={ref} {...modalProps} onClose={onCancel}>
      <ModalHeader>{title ? title : "Confirmation"}</ModalHeader>
      <Body>{children}</Body>
      <Footer>
        <FooterItem>
          <Button onClick={onCancel} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={onValidate}>
            Valider
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
});

export default ConfirmDialog;
