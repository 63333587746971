import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  CellClassParams,
  CellDoubleClickedEvent,
  CellValueChangedEvent,
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridOptions,
  GridReadyEvent,
  RowGroupingDisplayType,
  RowNode,
  SortChangedEvent,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";

import BudgetForecastProposalOverwriteDialog from "../dialogs/BudgetForecastProposalOverwriteDialog";
import BudgetCombinationExceptionPanel from "./panels/BudgetCombinationExceptionPanel";

import frameworkComponents from "../../../components/AgGrid";
import AG_GRID_LOCALE_FR from "../../../config/aggrid-locale.fr";
import { useGridStorageState } from "../../../hooks/aggrid/useGridStorageState";
import { useApiGet } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { BudgetForecast } from "../../../interfaces";
import {
  currencyValueFormatter,
  dateValueFormatter,
  numberValueFormatter,
  weightedAverage,
} from "../../../utils/helpers";

const BLUE = "#1f73b7";
const BROWN = "#833030";
const DARK_BLUE = "#071b73";
const GREEN = "#365e2b";
const GREY = "#6c6c6c";
const VALIDATION_BG = "#f3faee";
const REVIEW_BG = "#faeeee";

const BudgetForecastValidationGrid = ({
  forecast,
  baseUrl,
  step,
  onQtyUpdate,
  onCellValueUpdate,
  onGridFilterUpdate,
}: {
  forecast: BudgetForecast;
  baseUrl: string;
  step: string;
  onQtyUpdate: () => void;
  onCellValueUpdate: (params: CellValueChangedEvent) => void;
  onGridFilterUpdate: (model: { [p: string]: any } | null) => void;
}) => {
  const { addToast } = useToast();
  const [overwriteDialog, setOverwriteDialog] = useState<{ node: { [key: string]: any } } | undefined>();
  const [{ data }, refetch] = useApiGet(step === "validation" ? baseUrl : `/sales/${forecast.id}`);
  const storageStateOptions = useGridStorageState(`/sales/${forecast.id}_${step}`);

  const onFilterChanged = (event: FilterChangedEvent) => {
    storageStateOptions.onFilterChanged(event);
    const filterModel = event.api?.getFilterModel();
    // rename keys ending with "_1"
    Object.keys(filterModel).forEach((key) => {
      if (key.includes("_1")) {
        delete Object.assign(filterModel, { [key.substring(0, key.length - 2)]: filterModel[key] })[key];
      }
    });
    onGridFilterUpdate(filterModel);
  };

  /**
   * Detailed row cell edition
   */
  const isEditableCell = forecast && forecast.subType === "ORIGINAL" && forecast.status === "DRAFT";
  const isEditableValidationCell = () => {
    if (step === "validation") {
      return forecast && forecast.subType === "ORIGINAL" && forecast.status === "DRAFT";
    }
    // review
    return forecast && forecast.subType === "REVIEW" && forecast.status === "DRAFT";
  };

  /**
   * Grouped row cell edition
   */
  const onCellDoubleClicked = (event: CellDoubleClickedEvent) => {
    if (forecast.status !== "DRAFT") {
      addToast("Erreur", `Seules les modifications sur les brouillons sont autorisées`, "error");
      return;
    }
    if (step === "validation" && forecast.subType !== "ORIGINAL") {
      addToast("Erreur", `Modifications sur les révisions de budget impossibles`, "error");
      return;
    }
    if (step === "review" && forecast.subType !== "REVIEW") {
      addToast("Erreur", `Modifications impossibles`, "error");
      return;
    }

    if (canBeOverwritten(event)) {
      const node = event.node;
      setOverwriteDialog({
        node: {
          column: event.colDef.field ?? event.colDef.colId,
          group: node.group,
          level: node.group ? node.level : null,
          ...getNodeData(node),
        },
      });
    }
  };

  /**
   * Check if value can be overwritten for given event
   */
  const canBeOverwritten = (event: CellDoubleClickedEvent) => {
    // exclude footers
    if (event.node.footer) return false;
    // only grouped rows
    if (!event.node.group) return false;
    // only managed columns
    return (
      event.colDef.cellClass &&
      typeof event.colDef.cellClass === "string" &&
      ["entry", "price_entry", "date"].includes(event.colDef.cellClass)
    );
  };

  /**
   * Get node data
   */
  const getNodeData = (node: RowNode) => {
    let nodeData: { [key: string]: any } = {};
    if (node.group) {
      nodeData = node.aggData;
      const firstRow = node.allLeafChildren[0].data;
      nodeData["product_family_code"] = firstRow.product_family_code;
      nodeData["product_family_label"] = firstRow.product_family_label;
      nodeData["product_code"] = firstRow.product_code;
      nodeData["product_label"] = firstRow.product_label;
      nodeData["customer_subtype_code"] = firstRow.customer_subtype_code;
      nodeData["customer_subtype_label"] = firstRow.customer_subtype_label;
      nodeData["sales_start"] = firstRow.sales_start;
      nodeData["sales_end"] = firstRow.sales_end;
    } else {
      nodeData = node.data;
    }
    return nodeData;
  };

  const priceValueGetter = (params: ValueGetterParams, item: string) => {
    const quantity = `${item}_sales_quantity_kg`;
    const price = `${item}_unit_price`;
    if (!params.node?.group) {
      let value: { [key: string]: any } = {};
      value[quantity] = params.data[quantity];
      value[price] = params.data[price];
      value["toString"] = () => (params.data[price] ? (params.data[price] / 100.0).toFixed(2) : undefined);
      return value;
    }
  };

  const aggCellStyle = (params: CellClassParams) => {
    let style: { [key: string]: string } = { "text-align": "right" };
    if (params.colDef.cellClass && params.colDef.cellClass === "quantity_raw") {
      style = { ...style, "font-weight": "400", color: GREY };
    }
    if (params.colDef.cellClass && params.colDef.cellClass === "quantity") {
      style = { ...style, "font-weight": "400" };
    }
    if (params.colDef.cellClass && params.colDef.cellClass === "date") {
      style = { ...style, "font-weight": "400", color: GREEN };
    }
    if (params.colDef.cellClass && (params.colDef.cellClass === "entry" || params.colDef.cellClass === "price_entry")) {
      style = { ...style, "font-weight": "500", color: params.node.group ? DARK_BLUE : BLUE };
    }
    if (params.colDef.cellClass && (params.colDef.cellClass === "final" || params.colDef.cellClass === "price_final")) {
      const backGroundColor = step === "validation" ? VALIDATION_BG : REVIEW_BG;
      style = { ...style, "font-weight": "500", "background-color": backGroundColor };
    }
    if (params.node.footer) {
      style = { ...style, "font-weight": "500", color: BROWN };
    }
    return style;
  };

  const quantityColDef: ColDef = {
    filter: false,
    valueFormatter: numberValueFormatter({
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      suffix: "",
    }),
    cellStyle: aggCellStyle,
    aggFunc: "sum",
    minWidth: 100,
  };

  const dateColDef: ColDef = {
    cellClass: "date",
    valueFormatter: dateValueFormatter(),
    cellStyle: aggCellStyle,
    minWidth: 100,
  };

  const priceColDef: ColDef = {
    cellStyle: aggCellStyle,
    filter: false,
    minWidth: 80,
    valueFormatter: currencyValueFormatter(),
  };

  const openingPanel = {
    id: "opening",
    width: 450,
    labelDefault: "Ouvertures",
    labelKey: "opening",
    iconKey: "maximize",
    toolPanel: "combinationExceptionPanel",
    toolPanelParams: {
      forecast,
      refreshGrid: () => refetch(),
    },
  };

  const rowGroupingOptions: GridOptions = {
    groupDisplayType: RowGroupingDisplayType.SINGLE_COLUMN,
    autoGroupColumnDef: {
      headerName: "Famille",
      sort: "asc",
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true,
        footerValueGetter: (params: any) => `Total ${params.value}`,
      },
    },
    groupIncludeFooter: true,
    suppressAggFuncInHeader: true,
    onFirstDataRendered: (event: FirstDataRenderedEvent) => {
      event.columnApi.autoSizeColumns(["ag-Grid-AutoColumn"]);
    },
  };

  // for column move from inside column sidebar, upgrade to ag-grid 26.1.0 -- see
  // https://stackoverflow.com/questions/65413802/is-there-way-moving-columns-in-the-column-section-of-the-tool-panel-of-ag-grid
  const gridOptions: GridOptions = {
    ...rowGroupingOptions,
    frameworkComponents: {
      ...frameworkComponents,
      combinationExceptionPanel: BudgetCombinationExceptionPanel,
    },
    rememberGroupStateWhenNewData: true,
    enableBrowserTooltips: true,
    columnHoverHighlight: true,
    sideBar: {
      toolPanels: ["filters", openingPanel],
      defaultToolPanel: "filters",
    },
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "clear"],
      },
    },
    onCellDoubleClicked: onCellDoubleClicked,
    onCellValueChanged: onCellValueUpdate,
    localeText: AG_GRID_LOCALE_FR,
    onFilterChanged: onFilterChanged,
  };

  // common base colums
  const baseColumns: ColDef[] = [
    {
      field: "product_family_code",
      headerName: "Famille",
      rowGroup: true,
      rowGroupIndex: 0,
      hide: true,
      filter: false,
      valueFormatter: (params: any) => {
        const node = params.node.allLeafChildren[0].data;
        return `${node.product_family_code} - ${node.product_family_label}`;
      },
    },
    { field: "product_family_code", headerName: "Code famille", filter: true, hide: true },
    { field: "product_family_label", headerName: "Famille", filter: "agTextColumnFilter", hide: true },
    {
      field: "product_code",
      headerName: "Produit",
      filter: false,
      hide: true,
      rowGroup: true,
      rowGroupIndex: 1,
      valueFormatter: (params: any) => {
        const node = params.node.allLeafChildren[0].data;
        return `${node.product_code} - ${node.product_label}`;
      },
    },
    { field: "product_code", headerName: "Code produit", hide: true, filter: true },
    { field: "product_label", headerName: "Label produit", hide: true, filter: "agTextColumnFilter" },
    {
      field: "customer_subtype_code",
      headerName: "Enseigne",
      filter: false,
      hide: true,
      rowGroup: true,
      rowGroupIndex: 2,
      valueFormatter: (params: any) => {
        const node = params.node.allLeafChildren[0].data;
        return `${node.customer_subtype_code} - ${node.customer_subtype_label}`;
      },
    },
    { field: "customer_subtype_code", headerName: "Code enseigne", hide: true, filter: true },
    { field: "customer_subtype_label", headerName: "Enseigne", filter: "agTextColumnFilter", hide: true },
    { field: "commercial_name", headerName: "Commercial", filter: true, minWidth: 100 },
  ];

  // activity and projection columns
  const activityQuantityColumns: ColDef[] = [
    {
      field: "last_year_raw_sales_quantity_kg",
      headerName: "Qté brute A-1 (kg)",
      cellClass: "quantity_raw",
      ...quantityColDef,
    },
    {
      field: "current_year_raw_sales_quantity_kg",
      headerName: "Qté brute A (kg)",
      cellClass: "quantity_raw",
      ...quantityColDef,
    },
    {
      field: "last_year_sales_quantity_kg",
      headerName: "Qté A-1 (kg)",
      cellClass: "quantity",
      ...quantityColDef,
    },
    {
      field: "current_year_sales_quantity_kg",
      headerName: "Qté A (kg)",
      cellClass: "quantity",
      ...quantityColDef,
    },
    {
      field: "projected_sales_quantity_kg",
      headerName: "Qté A+1 (projection, en kg)",
      cellClass: "quantity",
      ...quantityColDef,
    },
    {
      field: "next_year_sales_quantity_kg",
      headerName: "Qté A+1 (après équilibrage, en kg)",
      cellClass: "quantity",
      ...quantityColDef,
    },
  ];

  //	budget review quantity columns
  const reviewQuantityColumns: ColDef[] = [
    {
      field: "source_sales_quantity_kg",
      headerName: "Qté totale budget (kg)",
      cellClass: "quantity",
      ...quantityColDef,
      editable: false,
    },
    {
      field: "source_to_date_sales_quantity_kg",
      headerName: "Qté à date budget (kg)",
      cellClass: "quantity",
      ...quantityColDef,
      editable: false,
    },
    {
      field: "current_year_sales_quantity_kg",
      headerName: "Qté réelles à date (kg)",
      cellClass: "quantity",
      ...quantityColDef,
      editable: false,
    },
    {
      field: "projected_sales_quantity_kg",
      headerName: "Qté futures (kg)",
      cellClass: "quantity",
      ...quantityColDef,
      editable: false,
    },
  ];

  //	budget review unit price columns
  const reviewPriceColumns: ColDef[] = [
    {
      headerName: "PU dernière révision",
      colId: "source_price",
      valueGetter: (params: ValueGetterParams) => priceValueGetter(params, "source"),
      aggFunc: weightedAverage("source_unit_price", "source_sales_quantity_kg"),
      cellClass: "quantity",
      ...priceColDef,
    },
  ];

  // proposal quantity columns
  const proposalQuantityColumns: ColDef[] = [
    {
      field: "proposed_sales_quantity_kg",
      headerName: "Qté proposée (kg)",
      cellClass: "entry",
      ...quantityColDef,
    },
  ];

  if (step === "review") {
    proposalQuantityColumns.push({
      headerName: "Ecart budget (kg)",
      colId: "gap_quantity",
      cellClass: "quantity",
      ...quantityColDef,
      valueGetter: (params: ValueGetterParams) => {
        if (!params.node?.group) {
          return params.data.proposed_sales_quantity_kg - params.data.source_sales_quantity_kg;
        }
      },
    });
  }

  // validation quantity columns
  const validationQuantityColumns: ColDef[] = [
    {
      field: "validated_sales_quantity_kg",
      headerName: "Qté validée (kg)",
      cellClass: "entry",
      ...quantityColDef,
      editable: isEditableValidationCell,
      cellEditor: "numberCellEditor",
      cellEditorParams: { step: 1 },
    },
    {
      headerName: "Qté finale (kg)",
      colId: "final_sales",
      valueGetter: (params: ValueGetterParams) => {
        if (!params.node?.group) {
          let sales = params.data.validated_sales_quantity_kg;
          if (sales === null) {
            sales = params.data.proposed_sales_quantity_kg ?? params.data.next_year_sales_quantity_kg;
          }
          return sales ?? 0;
        }
      },
      cellClass: "final",
      ...quantityColDef,
    },
  ];

  const activityPriceColumns: ColDef[] = [
    {
      headerName: "PU A-1",
      colId: "last_year_unit_price",
      valueGetter: (params: ValueGetterParams) => priceValueGetter(params, "last_year"),
      aggFunc: weightedAverage("last_year_unit_price", "last_year_sales_quantity_kg"),
      ...priceColDef,
    },
    {
      headerName: "PU A",
      colId: "current_year_unit_price",
      valueGetter: (params: ValueGetterParams) => priceValueGetter(params, "current_year"),
      aggFunc: weightedAverage("current_year_unit_price", "current_year_sales_quantity_kg"),
      ...priceColDef,
    },
  ];

  // validation price columns
  const validationPriceColumns: ColDef[] = [
    {
      headerName: "PU validé",
      colId: "expected_unit_price",
      valueGetter: (params: ValueGetterParams) => {
        if (!params.node?.group) {
          return {
            expected_sales_quantity_kg:
              params.data.validated_sales_quantity_kg ?? params.data.proposed_sales_quantity_kg,
            expected_unit_price: params.data.expected_unit_price,
            toString: () =>
              params.data.expected_unit_price ? (params.data.expected_unit_price / 100.0).toFixed(2) : undefined,
          };
        }
      },
      aggFunc: weightedAverage("expected_unit_price", "expected_sales_quantity_kg"),
      editable: isEditableValidationCell,
      cellEditor: "numberCellEditor",
      cellEditorParams: { step: 1 },
      valueSetter: (params: ValueSetterParams) => {
        const newValue = parseFloat(params.newValue) * 100.0;
        const valueChanged = params.oldValue.expected_unit_price !== newValue;
        if (valueChanged) {
          params.data.expected_unit_price = newValue;
        }
        return valueChanged;
      },
      cellClass: "price_entry",
      ...priceColDef,
    },
    {
      headerName: "PU final",
      colId: "final_price",
      valueGetter: (params: ValueGetterParams) => {
        if (!params.node?.group) {
          let price = params.data.expected_unit_price ?? params.data.current_year_unit_price;
          if (params.data.final_sales_quantity_kg === 0) price = null;
          return {
            final_sales_quantity_kg: params.data.final_sales_quantity_kg,
            final_unit_price: price,
            toString: () => (price ? (price / 100.0).toFixed(2) : undefined),
          };
        }
      },
      aggFunc: weightedAverage("final_unit_price", "final_sales_quantity_kg"),
      valueSetter: (params: ValueSetterParams) => {
        const newValue = parseFloat(params.newValue) * 100.0;
        const valueChanged = params.oldValue.final_unit_price !== newValue;
        if (valueChanged) {
          params.data.final_unit_price = newValue;
        }
        return valueChanged;
      },
      cellClass: "final",
      ...priceColDef,
    },
  ];

  // lifecycle columns
  const lifecycleColumns: ColDef[] = [
    {
      field: "sales_start",
      headerName: "Date début",
      filter: false,
      ...dateColDef,
      editable: isEditableCell,
      cellEditor: "dateCellEditor",
      cellEditorParams: { nullable: true },
    },
    {
      field: "sales_end",
      headerName: "Date fin",
      filter: false,
      ...dateColDef,
      editable: isEditableCell,
      cellEditor: "dateCellEditor",
      cellEditorParams: { nullable: true },
    },
    {
      field: "updated",
      headerName: "Mise à jour",
      filter: "agDateColumnFilter",
      ...dateColDef,
    },
  ];

  const validationDisplay = [
    ...baseColumns,
    ...activityQuantityColumns,
    ...proposalQuantityColumns,
    ...validationQuantityColumns,
    ...activityPriceColumns,
    ...validationPriceColumns,
    ...lifecycleColumns,
  ];

  const reviewDisplay = [
    ...baseColumns,
    ...reviewQuantityColumns,
    ...proposalQuantityColumns,
    ...validationQuantityColumns,
    ...reviewPriceColumns,
    ...validationPriceColumns,
  ];

  const columnDefs = step === "validation" ? validationDisplay : reviewDisplay;

  const onOverwriteDialogClose = (success: boolean) => {
    setOverwriteDialog(undefined);
    success && refetch();
  };

  return (
    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        gridOptions={gridOptions}
        columnDefs={columnDefs}
        rowData={data}
        onSortChanged={(event: SortChangedEvent) => storageStateOptions.onSortChanged(event)}
        onFirstDataRendered={(event: FirstDataRenderedEvent) => storageStateOptions.onFirstDataRendered(event)}
        onGridReady={(event: GridReadyEvent) => storageStateOptions.onGridReady(event)}
      />
      {overwriteDialog && (
        <BudgetForecastProposalOverwriteDialog
          forecast={forecast}
          step={step}
          column={overwriteDialog.node.column}
          data={overwriteDialog.node}
          onQtyUpdate={onQtyUpdate}
          close={onOverwriteDialogClose}
        />
      )}
    </div>
  );
};

export default BudgetForecastValidationGrid;
