import {
  CellValueChangedEvent,
  ColumnApi,
  FilterChangedEvent,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ServerSideStoreType,
  SetFilterValuesFunc,
  SetFilterValuesFuncParams,
  SideBarDef,
  SortChangedEvent,
} from "ag-grid-community";
import { AgGridReactProps, AgReactUiProps } from "ag-grid-react/lib/interfaces";
import { AgGridReact } from "ag-grid-react";
import AG_GRID_LOCALE_FR from "../../config/aggrid-locale.fr";
import frameworkComponents from "../../components/AgGrid";
import { useGridStorageState } from "../../hooks/aggrid/useGridStorageState";
import { useApiInstance } from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { toCamelCaseObject } from "../../utils/helpers";
import { useCallback, useMemo, useRef } from "react";

type ServerSideGridProps = {
  baseUrl: string;
  storageKey?: string;
  queryParams?: { [key: string]: any };
  menuEditAction?: (rows: any[]) => void;
  menuDeleteAction?: (rows: any[]) => void;
  gridApiRef?: React.MutableRefObject<GridApi | undefined>;
} & (AgGridReactProps | AgReactUiProps);

/**
 * A preconfigured grid with server side data
 *
 * @param props
 */
const ServerSideGrid = (props: ServerSideGridProps) => {
  const {
    baseUrl,
    storageKey,
    queryParams,
    gridOptions,
    gridApiRef,
    menuEditAction,
    menuDeleteAction,
    onGridReady,
    onFilterChanged,
    onSortChanged,
    ...gridProps
  } = props;

  const { addToast } = useToast();
  const api = useApiInstance();

  const storageStateOptions = useGridStorageState(storageKey ? storageKey : baseUrl);
  let gridApi = useRef<GridApi>();
  if (gridApiRef) {
    gridApi = gridApiRef;
  }
  const columnApi = useRef<ColumnApi>();

  /**
   * Remote datasource
   */
  const memoServerSideDatasource = useMemo<IServerSideDatasource>(() => {
    // destroy filters to be able to create them again with the right baseUrl
    columnApi.current?.getAllColumns()?.forEach((col) => {
      gridApi.current?.destroyFilter(col);
    });

    // return the IServerSideDatasource
    return {
      // called by the grid when more rows are required
      getRows: (params: IServerSideGetRowsParams) => {
        // this is a bug : avoid useless request if the sort model is not sync (gridStorageState logic in progress)
        if (params.api.getSortModel().length && !params.request.sortModel.length) {
          return;
        }

        // get data for request from server
        api
          .post(`${baseUrl}/rows`, params.request, { params: queryParams || null })
          .then((res) => {
            params.success({
              rowData: res.data.data,
              rowCount: res.data.lastRow,
            });
          })
          .catch(() => {
            params.fail();
          });
      },
    };
  }, [baseUrl, queryParams]);

  /**
   * Get all values for a given filter
   *
   * @param params
   */
  const setFilterValues: SetFilterValuesFunc = (params: SetFilterValuesFuncParams) => {
    api
      .post(
        `${baseUrl}/values`,
        {
          field: params.colDef.field,
          filterModel: gridApi.current?.getFilterModel(),
        },
        {
          params: queryParams || null,
        }
      )
      .then((req) => {
        params.success(req.data);
      })
      .catch(() => {
        addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
      });
  };

  /**
   * A a context menu entry to erase all filters
   *
   * @param params
   * @returns
   */
  const getContextMenuItems: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    const { api } = params;
    let items: any[] = [];

    if (menuEditAction) {
      items.push({
        name: "Modifier",
        action: () => menuEditAction(api?.getSelectedRows()),
        disabled: !api!.getSelectedRows().length,
        cssClasses: ["text-blue-600", "font-bold"],
      });
    }

    if (menuDeleteAction) {
      items.push({
        name: "Supprimer",
        action: () => menuDeleteAction(api?.getSelectedRows()),
        disabled: !api!.getSelectedRows().length,
        cssClasses: ["text-red-600", "font-bold"],
      });
    }

    if (items.length > 0) {
      items.push("separator");
    }

    items = items.concat(
      gridProps.getContextMenuItems ? gridProps.getContextMenuItems(params) : ServerSideGrid.defaultContextMenuItems
    );

    items = items.concat([
      "separator",
      {
        name: "Effacer tous les filtres",
        disabled: !Object.keys(params.api.getFilterModel()).length,
        action: () => params.api.setFilterModel(null),
      },
    ]);

    return items;
  };

  /**
   * @param params A generic cell value changed callback
   *
   * @returns
   */
  const memoOnCellValueChanged = useCallback<(params: CellValueChangedEvent) => void>(
    (params: CellValueChangedEvent) => {
      if (params.oldValue !== params.newValue) {
        api
          .put(`${baseUrl}/${params.data.id}`, toCamelCaseObject(params.data))
          .then((req) => addToast("Succès", "Mise à jour effectuée", "success"))
          .catch(() => {
            addToast("Erreur", `La valeur ${params.newValue} est erronée. Veuillez corriger.`, "error");
          });
      }
    },
    [baseUrl]
  );

  /**
   * Refresh all filter values
   *
   * Actually it does not work as expected...
   */
  // const refreshFiltersValues = ({ api, columnApi }: AgGridEvent) => {
  //   columnApi.getAllColumns()?.forEach(column => {
  //     if (column.getColDef().field) {
  //       const instance = api.getFilterInstance(column.getColDef().field!);
  //       if (instance instanceof SetFilter) {
  //         instance.refreshFilterValues();
  //       }
  //     }
  //   });
  // };

  /**
   * Final grid options
   */
  const options: GridOptions = {
    rowModelType: "serverSide",
    serverSideDatasource: memoServerSideDatasource,
    serverSideStoreType: ServerSideStoreType.Partial,
    localeText: AG_GRID_LOCALE_FR,
    frameworkComponents,
    rowSelection: "single",
    getContextMenuItems,
    onCellValueChanged: memoOnCellValueChanged,
    ...gridOptions,
    sortingOrder: ["asc", "desc"], // important to have consistent results over pagination
    suppressDragLeaveHidesColumns: true,
    sideBar: {
      toolPanels: ["filters"],
      defaultToolPanel: "filters",
      ...(gridOptions?.sideBar as SideBarDef),
    },
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        values: setFilterValues,
        refreshValuesOnOpen: true,
        buttons: ["apply", "clear"],
      },
      ...gridOptions?.defaultColDef,
    },
    onSortChanged: (event: SortChangedEvent) => {
      storageStateOptions.onSortChanged(event);
      (onSortChanged && onSortChanged(event)) || (gridOptions?.onSortChanged && gridOptions.onSortChanged(event));
    },
    onFilterChanged: (event: FilterChangedEvent) => {
      // refreshFiltersValues(event);
      storageStateOptions.onFilterChanged(event);
      (onFilterChanged && onFilterChanged(event)) ||
        (gridOptions?.onFilterChanged && gridOptions.onFilterChanged(event));
    },
    onGridReady: (event: GridReadyEvent) => {
      gridApi.current = event.api;
      columnApi.current = event.columnApi;
      storageStateOptions.onGridReady(event);
      (onGridReady && onGridReady(event)) || (gridOptions?.onGridReady && gridOptions.onGridReady(event));
    },
  };

  const { serverSideDatasource, onCellValueChanged, defaultColDef, ...otherGridOptions } = options;

  return (
    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        serverSideDatasource={serverSideDatasource}
        onCellValueChanged={onCellValueChanged}
        defaultColDef={defaultColDef}
        gridOptions={otherGridOptions}
        {...gridProps}
      />
    </div>
  );
};

ServerSideGrid.defaultContextMenuItems = ["copy", "copyWithHeaders", "paste", "separator", "export"];

export default ServerSideGrid;
