import { useEffect, useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Modal, Header as ModalHeader, Body, Footer, FooterItem, Close } from "@zendeskgarden/react-modals";
import { Alert, Title } from "@zendeskgarden/react-notifications";

import { Form } from "../../../components/Form";
import { useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { useForm } from "react-hook-form";

const ActivityOverwriteCreateDialog = (props: {
  close: Function;
  year: Number;
  week: number;
  productCode: string;
  display: "sales" | "price";
  value: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();
  const [undisplayedValue, setUndisplayedValue] = useState<number>();

  useEffect(() => {
    api
      .get(`activity/overwrite/${props.productCode}/${props.year}/${props.week}/${props.display}`)
      .then((req) => {
        setUndisplayedValue(+req.data[0]);
        setValue(props.display === "sales" ? "unitPrice" : "salesQuantity", +req.data[0]);
      })
      .catch(() => {
        addToast("Erreur", "Impossible de charger la liste des valeurs.", "error");
      });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchSalesQuantity = watch("salesQuantity");
  const watchUnitPrice = watch("unitPrice");

  const addActivityOverwrite = (data: any) => {
    setApiError("");
    setLoading(true);
    api
      .post(`activity/overwrite`, {
        ...data,
        year: props.year,
        week: props.week,
        productCode: props.productCode,
      })
      .then((response) => {
        addToast("Création de surcharge", `La surcharge #${response.data.id} a été créée`, "success", "top");
        props.close(true);
      })
      .catch((error) => {
        setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes"); // A revoir si configuration déjà existante juste update ?
        setLoading(false);
      });
  };

  return (
    <Modal onClose={() => props.close()}>
      <ModalHeader>Surcharger une valeur</ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(addActivityOverwrite)}>
          <Field>
            <Label>Année</Label>
            <Input disabled={true} defaultValue={props.year.toString()} />
          </Field>
          <Field>
            <Label>Semaine</Label>
            <Input disabled={true} defaultValue={props.week.toString()} />
          </Field>
          <Field>
            <Label>Volume de vente</Label>
            <Input
              type="number"
              step="any"
              {...register("salesQuantity", {
                value: props.display === "sales" ? props.value : undisplayedValue,
                validate: (value) => (watchUnitPrice !== undefined && watchUnitPrice !== "") || value !== "",
              })}
              validation={errors.salesQuantity ? "error" : undefined}
            />
            {errors.salesQuantity && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner au moins un volume de vente ou un prix
              </Message>
            )}
          </Field>
          <Field>
            <Label>Prix unitaire</Label>
            <Input
              type="number"
              step="any"
              {...register("unitPrice", {
                value: props.display === "sales" ? undisplayedValue : props.value,
                validate: (value) => value !== "" || (watchSalesQuantity !== undefined && watchSalesQuantity !== ""),
              })}
              validation={errors.unitPrice ? "error" : undefined}
            />
            {errors.unitPrice && (
              <Message className="mt-2" validation="error">
                Vous devez renseigner au moins un volume de vente ou un prix
              </Message>
            )}
          </Field>
        </Form>
        {apiError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{apiError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {loading ? "Création en cours..." : "Mettre à jour la surcharge"}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default ActivityOverwriteCreateDialog;
