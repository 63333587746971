import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Message } from "@zendeskgarden/react-forms";
import { Body, Close, Footer, FooterItem, Header as ModalHeader, Modal } from "@zendeskgarden/react-modals";

import { Alert, Title } from "@zendeskgarden/react-notifications";
import { Autocomplete, Form } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { BudgetForecast } from "../../../interfaces";

type ProductOption = {
  product_code: string;
  product_label: string;
};

const BudgetForecastProductSwitchDialog = (props: { forecast: BudgetForecast; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: productOptions }] = useApiGet(`budget/forecast/${props.forecast.id}/switch/product/options`);
  const [toProductOptions, setToProductOptions] = useState<ProductOption[]>([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const watchFromProductCode = watch("fromProductCode");

  // update toProductOptions with same recipe as selected product to replace
  useEffect(() => {
    if (watchFromProductCode) {
      const code = productOptions.find(
        (option: ProductOption) => `${option.product_code} - ${option.product_label}` === watchFromProductCode
      ).product_code;
      api
        .get(`/forecast/${props.forecast.id}/nomenclature/same/${code}`)
        .then((req) => setToProductOptions(req.data))
        .catch(() => addToast("Erreur", `Echec à la recherche de produits similaires.`, "error"));
    }
  }, [watchFromProductCode]);

  const onSubmit = (data: any) => {
    setApiError("");
    setLoading(true);
    const fromProductOption = data.fromProductCode
      ? productOptions.find(
          (option: ProductOption) => `${option.product_code} - ${option.product_label}` === data.fromProductCode
        )
      : null;
    const toProductOption = data.toProductCode
      ? toProductOptions.find(
          (option: ProductOption) => `${option.product_code} - ${option.product_label}` === data.toProductCode
        )
      : null;
    api
      .post(`budget/forecast/${props.forecast.id}/switch/product`, {
        fromProductCode: fromProductOption ? fromProductOption.product_code : null,
        toProductCode: toProductOption ? toProductOption.product_code : null,
      })
      .then((response) => {
        addToast(
          "Substitution de produit",
          `La substitution du produit ${response.data.fromProductCode} a été effectuée.`,
          "success",
          "top"
        );
        props.close(true);
      })
      .catch((error) => {
        setApiError(error.response.status === 405 ? error.message : "Erreur serveur");
        setLoading(false);
      });
  };

  let buttonContent = "Substituer les produit";
  if (loading) buttonContent = "Substitution en cours...";

  return (
    <Modal onClose={() => props.close(false)}>
      <ModalHeader>Substituer un produit dans le budget</ModalHeader>
      <Body>
        <Form id="dialog_form" onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>Produit à remplacer</Label>
            <Autocomplete
              className="mt-2"
              control={control}
              name="fromProductCode"
              options={
                productOptions
                  ?.map((option: ProductOption) => `${option.product_code} - ${option.product_label}`)
                  .sort() || []
              }
              rules={{ required: true }}
              validation={errors.fromProductCode ? "error" : undefined}
            />
            {errors.fromProductCode && (
              <Message className="mt-2" validation="error">
                Vous devez choisir un produit à remplacer.
              </Message>
            )}
          </Field>
          <Field>
            <Label>Produit de substitution</Label>
            <Autocomplete
              className="mt-2"
              control={control}
              name="toProductCode"
              options={
                toProductOptions
                  ?.map((option: ProductOption) => `${option.product_code} - ${option.product_label}`)
                  .sort() || []
              }
              // disabled={!!toProductOptions}
              rules={{ required: true }}
              validation={errors.toProductCode ? "error" : undefined}
            />
            {errors.toProductCode && (
              <Message className="mt-2" validation="error">
                Vous devez choisir un produit de remplacement.
              </Message>
            )}
          </Field>
        </Form>
        {apiError && (
          <Alert type="error" style={{ marginTop: 10 }}>
            <Title>{apiError}</Title>
            Veuillez corriger les données saisies.
          </Alert>
        )}
      </Body>
      <Footer>
        <FooterItem>
          <Button onClick={() => props.close(false)} isBasic>
            Annuler
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={loading} type="submit" form="dialog_form">
            {buttonContent}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
};

export default BudgetForecastProductSwitchDialog;
