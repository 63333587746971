import { useMemo, useRef, useState } from "react";
import { GetContextMenuItemsParams, GridApi, GridOptions, MenuItemDef } from "ag-grid-community";

import { Field, Input, Label, Message } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";
import { useForm } from "react-hook-form";
import { Form, Datepicker } from "../../../components/Form";
import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";

import moment from "moment";
import ActivityMatchingCreateDialog from "../dialogs/ActivityMatchingCreateDialog";
import { numberValueFormatter } from "../../../utils/helpers";

const ActivityMatchingSearchGrid = () => {
  const gridApi = useRef<GridApi>();
  const [searchEnd, setSearchEnd] = useState(new Date());
  const [searchStart, setSearchStart] = useState(new Date(`${searchEnd.getFullYear()}-01-01`));
  const [createDialog, setCreateDialog] = useState<any[] | undefined>(undefined);

  const baseUrl = "activity/matching/search";

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      searchStart: searchStart,
      searchEnd: searchEnd,
    },
  });

  const watchSearchStart = watch("searchStart");
  const watchSearchEnd = watch("searchEnd");

  const queryParams = useMemo(() => {
    return {
      start: moment(searchStart).format("YYYY-MM-DD"),
      end: moment(searchEnd).format("YYYY-MM-DD"),
    };
  }, [searchStart, searchEnd]);

  const onSubmit = (data: any) => {
    setSearchEnd(data.searchEnd);
    setSearchStart(data.searchStart);
  };

  const getContextMenuItems = ({ api }: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    return [
      {
        name: "Créer un alignement",
        action: () => setCreateDialog(api?.getSelectedRows()),
        disabled: !api!.getSelectedRows().length,
      },
      "separator",
      ...ServerSideGrid.defaultContextMenuItems,
    ];
  };

  const gridOptions: GridOptions = {
    columnDefs: [
      { field: "product_code", headerName: "Produit" },
      { field: "product_label", headerName: "Libellé", filter: "agTextColumnFilter" },
      { field: "product_family_label", headerName: "Filière", filter: "agTextColumnFilter" },
      { field: "product_line_label", headerName: "Conditionnement", filter: "agTextColumnFilter" },
      {
        field: "complete_percent",
        headerName: "Pourcentage de complétude",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        valueFormatter: numberValueFormatter({ suffix: "%", minimumFractionDigits: 1, maximumFractionDigits: 1 }),
        cellClassRules: {
          "bg-white": "x >= 75",
          "bg-red-100": "x >= 50 && x < 75",
          "bg-red-200": "x >= 25 && x < 50",
          "bg-red-400": "x <= 25",
        },
      },
    ],
    getContextMenuItems,
  };

  return (
    <>
      <Form className="p-2 flex-1" onSubmit={handleSubmit(onSubmit)}>
        <Field>
          <Label>Date de début </Label>
          <Datepicker
            value={watchSearchStart}
            onChange={(d) => {
              setValue("searchStart", d);
            }}
          >
            <Input
              {...register("searchStart", { required: true })}
              validation={errors.searchStart ? "error" : undefined}
            />
          </Datepicker>
          {errors.searchStart && (
            <Message className="mt-2" validation="error">
              Vous devez renseigner une date de début
            </Message>
          )}
        </Field>
        <Field>
          <Label>Date de fin </Label>
          <Datepicker
            value={watchSearchEnd}
            onChange={(d) => {
              setValue("searchEnd", d);
            }}
          >
            <Input
              {...register("searchEnd", {
                required: true,
                validate: (value) => value && value > watchSearchStart,
              })}
              validation={errors.searchEnd ? "error" : undefined}
            />
          </Datepicker>
          {errors.searchEnd?.type === "required" && (
            <Message className="mt-2" validation="error">
              Vous devez renseigner une date de fin
            </Message>
          )}
          {errors.searchEnd?.type === "validate" && (
            <Message className="mt-2" validation="error">
              La date de fin doit être ultérieure à la date de début
            </Message>
          )}
        </Field>
        <Button isPrimary type="submit">
          Choisir une période
        </Button>
      </Form>
      <ServerSideGrid
        baseUrl={baseUrl}
        queryParams={queryParams}
        gridOptions={gridOptions}
        onGridReady={({ api }) => (gridApi.current = api)}
      />
      {/* </FlexView>    */}
      {createDialog && (
        <ActivityMatchingCreateDialog
          productCode={createDialog[0].product_code}
          matchingStart={searchStart}
          matchingEnd={searchEnd}
          close={() => setCreateDialog(undefined)}
        />
      )}
    </>
  );
};

export default ActivityMatchingSearchGrid;
