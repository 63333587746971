import { GridOptions } from "ag-grid-community";

import ServerSideGrid from "../../../components/AgGrid/ServerSideGrid";
import { ApiRefetch } from "../../../hooks/useApi";
import { Forecast } from "../../../interfaces";
import { numberValueFormatter } from "../../../utils/helpers";

const ForecasInputConsumption = (props: { forecast: Forecast; refetch: ApiRefetch<any> }) => {
  const { forecast } = props;

  const baseUrl = `/input/${forecast.id}`;

  const gridOptions: GridOptions = {
    columnHoverHighlight: true,
    columnDefs: [
      { field: "component_family_code", headerName: "Code filière", maxWidth: 100, cellStyle: { fontWeight: "bold" } },
      { field: "component_family_label", headerName: "Filière", filter: "agTextColumnFilter", maxWidth: 300 },
      {
        field: "week",
        headerName: "Semaine",
        filter: "agNumberColumnFilter",
        cellStyle: { fontWeight: "bold", color: "#1f73b7", textAlign: "center" },
        maxWidth: 80,
        valueFormatter: numberValueFormatter({
          minimumIntegerDigits: 2,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      },
      {
        field: "input_quantity_kg",
        headerName: "Apports",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "carry_over_quantity_kg",
        headerName: "Reports",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "melimelo_consumption_kg",
        headerName: "Conso méli-mélos",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "promotion_consumption_kg",
        headerName: "Conso promotions",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "product_launch_consumption_kg",
        headerName: "Conso lancements",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "input_transfer_quantity_kg",
        headerName: "Transferts",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "consumption_balance_kg",
        headerName: "Solde d'apport",
        filter: "agNumberColumnFilter",
        cellStyle: (params) =>
          params.value < 0 ? { color: "red", textAlign: "right" } : { color: "black", textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "remaining_input_kg",
        headerName: "Apports restants",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "ontheshelf_consumption_kg",
        headerName: "Conso FDR (hors MM)",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "balance_kg",
        headerName: "Equilibrage",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "overwrite_consumption_kg",
        headerName: "Conso des surcharges",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
      {
        field: "final_balance_kg",
        headerName: "Equilibrage final",
        filter: "agNumberColumnFilter",
        cellStyle: { textAlign: "right" },
        valueFormatter: numberValueFormatter({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      },
    ],
  };

  return <ServerSideGrid baseUrl={baseUrl} gridOptions={gridOptions}></ServerSideGrid>;
};

export default ForecasInputConsumption;
