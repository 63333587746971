import { useEffect, useState } from "react";
import moment from "moment/moment";

import { IToolPanelParams } from "ag-grid-community";

import { Button, IconButton } from "@zendeskgarden/react-buttons";
import { FooterItem } from "@zendeskgarden/react-chrome";
import { Body, Caption, Cell, Head, HeaderCell, Table } from "@zendeskgarden/react-tables";
import { MD } from "@zendeskgarden/react-typography";

import { ReactComponent as DeleteIcon } from "@zendeskgarden/svg-icons/src/12/trash-fill.svg";

import CombinationExceptionDialog from "../../dialogs/CombinationExceptionDialog";

import { AGHeaderRow, AGRow, CompactFooter } from "../../../../components/Styled";
import { useApiGet, useApiInstance } from "../../../../hooks/useApi";
import useToast from "../../../../hooks/useToast";
import { Combination, WeeklyForecast } from "../../../../interfaces";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { Col, Row } from "@zendeskgarden/react-grid";

const CombinationExceptionPanel = (
  props: IToolPanelParams & {
    forecast: WeeklyForecast;
    familyCode: string;
    exceptionType: string;
    refreshGrid: Function;
  }
) => {
  const { forecast, familyCode, exceptionType, api } = props;

  const { addToast } = useToast();
  const preveolApi = useApiInstance();

  const [combinations, setCombinations] = useState<Combination[] | []>([]);
  const [combinationExceptionDialogVisible, setCombinationExceptionDialogVisible] = useState(false);

  const [{ data }, refetch] = useApiGet(`/weekly/forecast/${forecast.id}/${familyCode}/combination/${exceptionType}`);
  const [{ data: validation }] = useApiGet(`/validation/${forecast.id}/${familyCode}`);

  const onCombinationExceptionDialogClose = (success: boolean) => {
    setCombinationExceptionDialogVisible(false);
    if (success) {
      refetch();
      props.refreshGrid();
    }
  };

  const dateRef = moment(`${forecast.year}`).add(forecast.period, "weeks");
  const update = () => {
    // update existing combinations from grid data
    let existingCombinations: Combination[] = [];
    api.forEachNode(function (node) {
      const data = node.data;
      if (
        data &&
        data.order_type === "FDR" &&
        moment(`${forecast.year}`).add(data.shipping_week, "weeks").diff(dateRef, "week") === 0
      ) {
        const gridCombination: Combination = {
          product_code: data.product_code,
          shipping_warehouse_code: data.shipping_warehouse_code,
          delivered_customer_code: data.delivered_customer_code,
        };
        if (!existingCombinations.find((combination) => combination === gridCombination)) {
          existingCombinations.push(gridCombination);
        }
      }
    });
    setCombinations(existingCombinations);
  };

  const deleteCombination = (item: { [key: string]: string }) => {
    const combination = `${item.product_code}/${item.shipping_warehouse_code}/${item.delivered_customer_code}`;
    preveolApi
      .delete(`/weekly/forecast/${forecast.id}/${familyCode}/combination/${exceptionType}/${combination}`)
      .then(() => {
        addToast(
          `Suppression ${exceptionType === "opening" ? "d'ouverture" : "de fermeture"}`,
          `${exceptionType === "opening" ? "L'ouverture" : "La fermeture"} du produit #${
            item.product_code
          } a été supprimée`,
          "success",
          "top"
        );
        refetch();
        props.refreshGrid();
      })
      .catch(() => {
        addToast(
          "Erreur",
          `Erreur à la suppression de ${exceptionType === "opening" ? "l'ouverture" : "la fermeture"}`,
          "error",
          "top"
        );
      });
  };

  useEffect(() => {
    api.addEventListener("modelUpdated", update);
    return () => api.removeEventListener("modelUpdated", update);
  }, []);

  return (
    <>
      <Table size="small" style={{ fontSize: "small", minWidth: exceptionType === "opening" ? 450 : 350 }}>
        <Caption>
          <MD style={{ margin: 15 }} isBold>
            {exceptionType === "opening" ? "Ouverture" : "Fermeture"} de combinaisons
          </MD>
        </Caption>
        {data && (
          <Head>
            <AGHeaderRow>
              <HeaderCell width={50}>Produit</HeaderCell>
              <HeaderCell width={30}>Dépôt</HeaderCell>
              <HeaderCell width={50}>Client</HeaderCell>
              {exceptionType === "opening" && <HeaderCell width={50}>Aligné sur</HeaderCell>}
              {exceptionType === "opening" && (
                <HeaderCell width={40} style={{ textAlign: "right" }}>
                  Qté (kg)
                </HeaderCell>
              )}
              <HeaderCell width={10}></HeaderCell>
            </AGHeaderRow>
          </Head>
        )}
        <Body>
          {data &&
            data.length > 0 &&
            data.map((item: { [key: string]: string }) => (
              <AGRow key={`${item.product_code}-${item.shipping_warehouse_code}-${item.delivered_customer_code}`}>
                <Cell>{item.product_code}</Cell>
                <Cell width={30}>{item.shipping_warehouse_code}</Cell>
                <Cell width={50}>{item.delivered_customer_code}</Cell>
                {exceptionType === "opening" && <Cell width={50}>{item.product_code_match}</Cell>}
                {exceptionType === "opening" && (
                  <Cell width={40} style={{ textAlign: "right" }}>
                    {item.quantity_match}
                  </Cell>
                )}
                <Tooltip
                  placement="top"
                  type="dark"
                  size="small"
                  content={`Supprimer ${exceptionType === "opening" ? "l'ouverture" : "la fermeture"}`}
                >
                  <IconButton isDanger size="small" onClick={() => deleteCombination(item)} style={{ marginLeft: 2 }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </AGRow>
            ))}
        </Body>
      </Table>
      {data && data.length === 0 && (
        <Row alignItems="center" style={{ minHeight: "6em" }}>
          <Col>
            <MD style={{ textAlign: "center" }}>Aucune {exceptionType === "opening" ? "ouverture" : "fermeture"}</MD>
          </Col>
        </Row>
      )}
      <CompactFooter>
        <FooterItem>
          <Button
            size="small"
            isPrimary
            onClick={() => setCombinationExceptionDialogVisible(true)}
            disabled={!validation || validation.status !== "NO_VALIDATION"}
          >
            {exceptionType === "opening" ? "Ouvrir" : "Fermer"} une combinaison
          </Button>
        </FooterItem>
      </CompactFooter>
      {forecast && combinationExceptionDialogVisible && (
        <CombinationExceptionDialog
          close={onCombinationExceptionDialogClose}
          forecast={forecast}
          exceptionType={exceptionType}
          familyCode={familyCode}
          gridCombinations={combinations}
        />
      )}
    </>
  );
};

export default CombinationExceptionPanel;
