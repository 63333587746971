import { Popper as InnerPopper } from "react-popper";
import Popper from "popper.js";

/**
 * Initialize popper
 */
export const initializePopper = () => {
  // override react-popper default props to set positionFixed: true
  Object.defineProperty(InnerPopper, "defaultProps", {
    value: {
      placement: "bottom",
      eventsEnabled: true,
      referenceElement: undefined,
      positionFixed: true,
    },
    enumerable: true,
    configurable: true,
    writable: true,
  });

  // globaly deactivate prevent overflow
  Popper.Defaults.modifiers!.preventOverflow!.boundariesElement = "window";
};
