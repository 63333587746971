import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input, Message } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Body, Close, Footer, FooterItem, Header as ModalHeader, Modal } from "@zendeskgarden/react-modals";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import { Span } from "@zendeskgarden/react-typography";

import { Form, Datepicker, Multiselect } from "../../../components/Form";
import { useApiGet, useApiInstance } from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";

type TransferRule = {
  shift: number;
  day: string;
  percent: number;
};

const transferRuleDays = (day: Date, option: string) => {
  let transferRule: Array<TransferRule> = [];

  const addNewDay = (d: number) => {
    const newDay = moment(day).add(d, "d");
    if (newDay.weekday() !== 6) {
      transferRule.push({
        shift: d,
        day: newDay.format("YYYY-MM-DD"),
        percent: 0,
      });
    }
  };

  // 5 days before 'day'
  let i = 0;
  while (transferRule.length < 5) {
    i -= 1;
    addNewDay(i);
  }
  // 5 days after 'day'
  i = 0;
  while (transferRule.length < 10) {
    i += 1;
    addNewDay(i);
  }

  return transferRule
    .filter((rule) => {
      if (option === "before") return rule.shift < 0;
      if (option === "after") return rule.shift > 0;
      return true;
    })
    .sort((a, b) => {
      if (option === "before") return b.shift - a.shift;
      return a.shift - b.shift;
    });
};

const totalPercent = (rules: { percent: number }[]) => {
  return rules
    .map((rule: { percent: number }) => {
      if (isNaN(rule.percent)) return 0;
      return rule.percent;
    })
    .reduce((sum: number, next: number) => sum + next);
};

type closedDayProps = {
  id: number;
  day: string;
  shipping_warehouse_codes: string[];
  updated?: string;
};

const ClosedDayDialog = (props: { closedDay?: closedDayProps; close: Function }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const { addToast } = useToast();
  const api = useApiInstance();

  const [{ data: warehouseCodeOptions }] = useApiGet("/datasource/activity/values/shipping_warehouse_code");

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchDay = watch("day");
  const watchTransferRule = watch("transferRule");

  useEffect(() => {
    if (props.closedDay) {
      api
        .get(`/closed/day/${props.closedDay.id}`)
        .then((req) => {
          setValue("day", new Date(req.data.day));
          setValue("shippingWarehouseCodes", req.data.shippingWarehouseCodes);
          setValue("transferRule", req.data.transferRule);
        })
        .catch(() => addToast("Erreur", "Impossible de charger la liste des valeurs.", "error"));
    }
  }, []);

  const onSubmit = (data: any) => {
    setApiError("");
    setLoading(true);
    transferRuleDays(data.day, "all").forEach((rule: TransferRule, index) => {
      data.transferRule[index].day = moment(data.day).add(rule.shift, "d").format("YYYY-MM-DD");
    });
    if (props.closedDay) {
      api
        .put(`/closed/day/${props.closedDay.id}`, {
          ...data,
          id: props.closedDay.id,
          day: moment(data.day).format("YYYY-MM-DD"),
        })
        .then((response) => {
          addToast(
            "Jour de fermeture mis à jour",
            `Le jour de fermeture ${response.data.day} -- entrepôt(s) "${response.data.shippingWarehouseCodes}) -- a été mis à jour".`,
            "success",
            "top"
          );
          props.close(true);
        })
        .catch((error) => {
          setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
          setLoading(false);
        });
    } else {
      api
        .post(`closed/day`, {
          ...data,
          id: 0,
          day: moment(data.day).format("YYYY-MM-DD"),
        })
        .then((response) => {
          addToast(
            "Création d'un jour de fermeture",
            `Le ${response.data.day} a été enregistré comme jour de fermeture pour l'(es)entrepôt(s) "${response.data.shippingWarehouseCodes}".`,
            "success",
            "top"
          );
          props.close(true);
        })
        .catch((error) => {
          setApiError(error.response.status === 405 ? "Configuration déjà existante" : "Données incohérentes");
          setLoading(false);
        });
    }
  };

  const dayField = (
    <Field>
      <Label>Jour</Label>
      <Datepicker value={watchDay} onChange={(d) => setValue("day", d)}>
        <Input
          {...register("day", { required: true, disabled: props.closedDay ? true : false })}
          validation={errors.day ? "error" : undefined}
        />
      </Datepicker>
      {errors.day && (
        <Message className="mt-2" validation="error">
          Vous devez renseigner un jour
        </Message>
      )}
    </Field>
  );

  const warehousesField = (
    <Field>
      <Label>Entrepôt</Label>
      <Multiselect
        className="mt-2"
        control={control}
        name="shippingWarehouseCodes"
        options={warehouseCodeOptions || []}
        rules={{ required: true }}
        validation={errors.shippingWarehouseCodes ? "error" : undefined}
        value={props.closedDay?.shipping_warehouse_codes}
      />
      {errors.shippingWarehouseCodes && (
        <Message className="mt-2" validation="error">
          Vous devez choisir un ou plusieurs codes produit de référence
        </Message>
      )}
    </Field>
  );

  const transferRuleFields = (day: Date) => {
    const ruleField = (rule: TransferRule, i: number) => {
      let index = i > 4 ? i : 4 - i;
      return (
        <Field key={i}>
          <Label>{rule.day}</Label>
          <Input
            isCompact
            type="integer"
            {...register(`transferRule.${index}.percent` as const, { valueAsNumber: true })}
          />
        </Field>
      );
    };

    return (
      <>
        <Label>Report sur les jours voisins (en %)</Label>
        <Row>
          <Col sm={6} style={{ marginTop: "10px" }}>
            {transferRuleDays(day, "before").map((rule: TransferRule, index) => ruleField(rule, index))}
          </Col>
          <Col sm={6} style={{ marginTop: "10px" }}>
            {transferRuleDays(day, "after").map((rule: TransferRule, index) => ruleField(rule, index + 5))}
          </Col>
          <Col sm={12} style={{ marginTop: "10px" }}>
            {watchTransferRule && (
              <Span hue={totalPercent(watchTransferRule) !== 100 ? "red" : "#60ab16"}>
                Total : {totalPercent(watchTransferRule)}
              </Span>
            )}
          </Col>
        </Row>
      </>
    );
  };

  let buttonContent = props.closedDay ? "Modifier le jour de fermeture" : "Créer le jour de fermeture";
  if (loading) buttonContent = props.closedDay ? "Modification en cours..." : "Création en cours...";

  return (
    <Modal isLarge onClose={() => props.close(false)}>
      <ModalHeader>
        {props.closedDay
          ? `Modifier la règle de report du jour de fermeture du ${moment(props.closedDay?.day).format("DD/MM/YYYY")}`
          : "Créer un jour de fermeture"}
      </ModalHeader>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <Form id="dialog_form" onSubmit={handleSubmit(onSubmit)}>
        <Body>
          <Form>
            {dayField}
            {warehousesField}
            {!props.closedDay && watchDay && transferRuleFields(watchDay)}
            {props.closedDay && watchTransferRule && transferRuleFields(watchDay)}
          </Form>
          {apiError && (
            <Alert type="error" style={{ marginTop: 10 }}>
              <Title>{apiError}</Title>
              Veuillez corriger les données saisies.
            </Alert>
          )}
        </Body>
        <Footer>
          <FooterItem>
            <Button onClick={() => props.close(false)} isBasic>
              Annuler
            </Button>
          </FooterItem>
          <FooterItem>
            <Button
              isPrimary
              disabled={loading || !watchTransferRule || totalPercent(watchTransferRule) !== 100}
              type="submit"
            >
              {buttonContent}
            </Button>
          </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
      </Form>
    </Modal>
  );
};

export default ClosedDayDialog;
