import { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { Button } from "@zendeskgarden/react-buttons";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Progress } from "@zendeskgarden/react-loaders";
import { Paragraph, Title, Well } from "@zendeskgarden/react-notifications";
import { Tag } from "@zendeskgarden/react-tags";
import { Span } from "@zendeskgarden/react-typography";

import { ReactComponent as CheckOffIcon } from "@zendeskgarden/svg-icons/src/16/circle-full-stroke.svg";
import { ReactComponent as CheckOnIcon } from "@zendeskgarden/svg-icons/src/16/check-circle-fill.svg";
import { ReactComponent as ForecastIcon } from "@zendeskgarden/svg-icons/src/26/book.svg";
import { ReactComponent as ValidationIcon } from "@zendeskgarden/svg-icons/src/26/checkbox.svg";
import { ReactComponent as WeekIcon } from "@zendeskgarden/svg-icons/src/26/dashboard.svg";
import { ReactComponent as ParameterIcon } from "@zendeskgarden/svg-icons/src/26/line-chart.svg";
import { ReactComponent as SalesIcon } from "@zendeskgarden/svg-icons/src/26/play.svg";
import { ReactComponent as HistoryIcon } from "@zendeskgarden/svg-icons/src/26/workflow.svg";

import ForecastCreateDialog from "../dialogs/ForecastCreateDialog";
import ForecastForecastDialog from "../dialogs/ForecastForecastDialog";
import ForecastRefreshDialog from "../dialogs/ForecastRefreshDialog";
import ForecastReservationDialog from "../dialogs/ForecastReservationDialog";
import ForecastResetDialog from "../dialogs/ForecastResetDialog";
import ForecastValidateDialog from "../dialogs/ForecastValidateDialog";
import ForecastStatusTag from "../../../components/ForecastStatusTag";
import { PaddedMain, StyledCol } from "../../../components/Styled";
import { ApiRefetch, useApiGet } from "../../../hooks/useApi";
import { ForecastValidationStatus, WeeklyForecast } from "../../../interfaces";

const ForecastParams = (props: { forecast: WeeklyForecast; refetch: ApiRefetch<any> }) => {
  const { forecast, refetch } = props;
  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const [resetDialogVisible, setResetDialogVisible] = useState(false);
  const [refreshDialogVisible, setRefreshDialogVisible] = useState(false);
  const [reservationDialogVisible, setReservationDialogVisible] = useState(false);
  const [forecastStep1DialogVisible, setForecastStep1DialogVisible] = useState(false);
  const [forecastStep2DialogVisible, setForecastStep2DialogVisible] = useState(false);
  const [validateDialogVisible, setValidateDialogVisible] = useState(false);

  const [{ data: weeks }, refetchWeeks] = useApiGet(`/forecast/${forecast.id}/weeks`, {});
  const [{ data: monoStatus }, refetchMonoStatus] = useApiGet(`/validation/${forecast.id}/status/mono`, {});
  const [{ data: monoMelimeloStatus }, refetchMonoMelimeloStatus] = useApiGet(
    `/validation/${forecast.id}/status/mono-melimelo`,
    {}
  );
  const [{ data: melimeloStatus }, refreshMelimeloStatus] = useApiGet(`/validation/${forecast.id}/status/melimelo`, {});

  const onUpdateDialogClose = (success: boolean) => {
    setUpdateDialogVisible(false);
    if (success) {
      refetch();
      refetchWeeks();
    }
  };

  const onResetDialogClose = (success: boolean) => {
    setResetDialogVisible(false);
    if (success) {
      refetch();
      refetchMonoStatus();
      refetchMonoMelimeloStatus();
      refreshMelimeloStatus();
    }
  };

  const onRefreshDialogClose = (success: boolean) => {
    setRefreshDialogVisible(false);
    if (success) {
      refetch();
      refetchMonoStatus();
      refetchMonoMelimeloStatus();
      refreshMelimeloStatus();
    }
  };

  const onReservationDialogClose = (success: boolean) => {
    setReservationDialogVisible(false);
    if (success) {
      refetch();
    }
  };

  const onForecastStep1DialogClose = (success: boolean) => {
    setForecastStep1DialogVisible(false);
    if (success) {
      refetch();
      refetchMonoStatus();
      refetchMonoMelimeloStatus();
      refreshMelimeloStatus();
    }
  };

  const onForecastStep2DialogClose = (success: boolean) => {
    setForecastStep2DialogVisible(false);
    if (success) {
      refetch();
    }
  };

  const onValidateDialogClose = (success: boolean) => {
    setValidateDialogVisible(false);
    if (success) {
      refetch();
      refetchMonoStatus();
      refetchMonoMelimeloStatus();
      refreshMelimeloStatus();
    }
  };

  return (
    <PaddedMain>
      <Row>
        <Col sm={4}>
          <Well isRecessed>
            <Title>
              <Span>
                <Span.StartIcon>
                  <ForecastIcon />
                </Span.StartIcon>
                Prévision
              </Span>
            </Title>
            <Paragraph>
              Libellé : <Span isBold>{forecast.label}</Span>
            </Paragraph>
            <Paragraph>
              Année cible : <Span isBold>{forecast.year}</Span>
            </Paragraph>
            <Paragraph style={{ marginBottom: "10px" }}>
              Semaine cible : <Span isBold>{forecast.period}</Span>
            </Paragraph>
            <ForecastStatusTag forecast={forecast}></ForecastStatusTag>
          </Well>
          <Well isRecessed style={{ marginTop: 10 }}>
            <Title>
              <Title>
                <Span>
                  <Span.StartIcon>
                    <HistoryIcon />
                  </Span.StartIcon>
                  Paramètres de l'historique
                </Span>
              </Title>
            </Title>
            <Paragraph>{forecast.activityMatchingOn ? <CheckOnIcon /> : <CheckOffIcon />} Alignements</Paragraph>
            <Paragraph>{forecast.activityExclusionOn ? <CheckOnIcon /> : <CheckOffIcon />} Exclusions</Paragraph>
            <Paragraph>{forecast.activityOverwriteOn ? <CheckOnIcon /> : <CheckOffIcon />} Surcharges</Paragraph>
            <Paragraph>Budget de référence : {forecast.referentBudget ? "" : "NEANT"}</Paragraph>
            {forecast.referentBudget && (
              <Paragraph>
                <Link to={`/budget/${forecast.referentBudget.id}`}>
                  <Tag isPill style={{ marginRight: 5 }}>
                    <span>#{forecast.referentBudget.id}</span>
                  </Tag>
                  <span>{forecast.referentBudget.label}</span>
                </Link>
              </Paragraph>
            )}
            <Paragraph>Semaines de référence : </Paragraph>
            {weeks
              ? weeks.map((week: string) => (
                  <Tag key={week} hue="#3A3A3A" style={{ margin: "2px" }}>
                    {week}
                  </Tag>
                ))
              : ""}
          </Well>
          <Well isRecessed style={{ marginTop: 10 }}>
            <Title>
              <Span>
                <Span.StartIcon>
                  <ParameterIcon />
                </Span.StartIcon>
                Paramètres de projection
              </Span>
            </Title>
            <Paragraph>{forecast.inputStockOn ? <CheckOnIcon /> : <CheckOffIcon />} Reports d'apports</Paragraph>
            <Paragraph>
              Type d'éclatement :&nbsp;
              <Span isBold>{forecast.splitType === "ACTIVITY" ? "Historique" : "Précédente prévision"}</Span>
            </Paragraph>
          </Well>
          <Well isRecessed style={{ marginTop: 10 }}>
            <Paragraph>
              <Button
                isStretched
                isPrimary
                onClick={() => setUpdateDialogVisible(true)}
                disabled={forecast.status !== "DRAFT"}
              >
                Modifier les paramètres
              </Button>
            </Paragraph>
          </Well>
        </Col>
        <StyledCol sm={4}>
          <Well isRecessed>
            <Title>
              <Span>
                <Span.StartIcon>
                  <HistoryIcon />
                </Span.StartIcon>
                Historique de référence
              </Span>
            </Title>
            <Paragraph>
              {forecast.reset ? "Dernière réinitialisation :" : "Initialisation :"}&nbsp;
              <Span isBold>
                <Moment format="D MMM YYYY à HH:mm">{forecast.reset ?? forecast.created}</Moment>
              </Span>
            </Paragraph>
            <Paragraph>
              <Button
                isStretched
                isPrimary
                style={{ marginTop: 10, marginBottom: 10 }}
                onClick={() => setResetDialogVisible(true)}
                disabled={forecast.status !== "DRAFT"}
              >
                Réinitialiser la prévision
              </Button>
            </Paragraph>
            {forecast.refreshed && (
              <Paragraph>
                Dernière actualisation :&nbsp;
                <Span isBold>
                  <Moment format="D MMM YYYY à HH:mm">{forecast.refreshed}</Moment>
                </Span>
              </Paragraph>
            )}
            <Paragraph>
              <Button
                isStretched
                isPrimary
                style={{ marginTop: 5, marginBottom: 10 }}
                onClick={() => setRefreshDialogVisible(true)}
                disabled={!forecast.forecasted || forecast.status === "ARCHIVED"}
              >
                Actualiser la prévision
              </Button>
            </Paragraph>
          </Well>
          <Well isRecessed style={{ marginTop: 10 }}>
            <Title>
              <Span>
                <Span.StartIcon>
                  <SalesIcon />
                </Span.StartIcon>
                Réservations
              </Span>
            </Title>
            {forecast.generated && (
              <Paragraph>
                Dernière génération :&nbsp;
                <Span isBold>
                  <Moment format="D MMM YYYY à HH:mm">{forecast.generated}</Moment>
                </Span>
              </Paragraph>
            )}
            <Paragraph>
              <Button
                isStretched
                isPrimary
                style={{ marginTop: 10 }}
                onClick={() => setReservationDialogVisible(true)}
                disabled={forecast.status !== "DRAFT"}
              >
                {forecast.generated === null ? "Générer" : "Regénérer"} les réservations
              </Button>
            </Paragraph>
          </Well>
          {forecast.generated && (
            <Well isRecessed style={{ marginTop: 10 }}>
              <Title>
                <Span>
                  <Span.StartIcon>
                    <WeekIcon />
                  </Span.StartIcon>
                  Prévisions
                </Span>
              </Title>
              {forecast.forecasted && (
                <Paragraph>
                  Dernier lancement :&nbsp;
                  <Span isBold>
                    <Moment format="D MMM YYYY à HH:mm">{forecast.forecasted}</Moment>
                  </Span>
                </Paragraph>
              )}
              <Button
                isStretched
                isPrimary
                style={{ marginTop: 10 }}
                onClick={() => setForecastStep1DialogVisible(true)}
                disabled={forecast.status !== "DRAFT" || !forecast.generated}
              >
                {forecast.forecasted ? "Relancer" : "Lancer"} la prévision #{forecast.id}
              </Button>
            </Well>
          )}
        </StyledCol>
        <StyledCol sm={4}>
          {forecast.forecasted && (
            <Well isRecessed>
              <Title>
                <Span>
                  <Span.StartIcon>
                    <ValidationIcon />
                  </Span.StartIcon>
                  Avancement - familles mono-composant
                </Span>
              </Title>
              <Progress
                size="large"
                style={{ marginTop: 15 }}
                value={Math.round(monoStatus * 100)}
                title={`Avancement : ${Math.round(monoStatus * 100)} %`}
              />
            </Well>
          )}
          {forecast.forecasted && (
            <Well isRecessed style={{ marginTop: 10 }}>
              <Title>
                <Span>
                  <Span.StartIcon>
                    <ValidationIcon />
                  </Span.StartIcon>
                  Avancement - familles multi-composants
                </Span>
              </Title>
              {melimeloStatus &&
                melimeloStatus.map((component: ForecastValidationStatus) => (
                  <Paragraph key={component.family_code}>
                    {component.status !== "NO_VALIDATION" ? <CheckOnIcon /> : <CheckOffIcon />} {component.family_label}
                    <Tag isPill style={{ marginLeft: 5 }}>
                      <span>{component.family_code}</span>
                    </Tag>
                  </Paragraph>
                ))}
              <Title style={{ marginTop: 15 }}>
                <Span>
                  <Span.StartIcon>
                    <ValidationIcon />
                  </Span.StartIcon>
                  Familles mono-composants associées
                </Span>
              </Title>
              <Button
                isStretched
                isPrimary
                style={{ marginTop: 10 }}
                onClick={() => setForecastStep2DialogVisible(true)}
                disabled={
                  !["DRAFT", "PARTIAL"].includes(forecast.status) ||
                  !melimeloStatus ||
                  melimeloStatus.some(
                    (validation: ForecastValidationStatus) => validation.status === "NO_VALIDATION"
                  ) ||
                  forecast.forecastedStep2
                }
              >
                Lancer la prévision des familles associées
              </Button>
              <Progress
                size="large"
                style={{ marginTop: 15 }}
                value={Math.round(monoMelimeloStatus * 100)}
                title={`Avancement : ${Math.round(monoMelimeloStatus * 100)} %`}
              />
            </Well>
          )}
          {forecast.forecasted && (
            <Well isRecessed style={{ marginTop: 10 }}>
              <Button
                isStretched
                isPrimary
                style={{ marginTop: 10 }}
                onClick={() => setValidateDialogVisible(true)}
                disabled={
                  !["DRAFT", "PARTIAL"].includes(forecast.status) || !forecast.generated || !forecast.forecasted
                }
              >
                Valider globalement la prévision
              </Button>
            </Well>
          )}
        </StyledCol>
      </Row>

      {forecast && updateDialogVisible && (
        <ForecastCreateDialog forecast={forecast} close={onUpdateDialogClose} weeks={weeks} />
      )}
      {forecast && resetDialogVisible && <ForecastResetDialog forecast={forecast} close={onResetDialogClose} />}
      {forecast && refreshDialogVisible && <ForecastRefreshDialog forecast={forecast} close={onRefreshDialogClose} />}
      {forecast && reservationDialogVisible && (
        <ForecastReservationDialog forecast={forecast} close={onReservationDialogClose} />
      )}
      {forecast && forecastStep1DialogVisible && (
        <ForecastForecastDialog forecast={forecast} step={1} close={onForecastStep1DialogClose} />
      )}
      {forecast && forecastStep2DialogVisible && (
        <ForecastForecastDialog forecast={forecast} step={2} close={onForecastStep2DialogClose} />
      )}
      {forecast && validateDialogVisible && (
        <ForecastValidateDialog forecast={forecast} close={onValidateDialogClose} />
      )}
    </PaddedMain>
  );
};

export default ForecastParams;
